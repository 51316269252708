import React, { useState } from 'react'
import ReactGA from 'react-ga';


import styled from 'styled-components'
 
const StyledNBA  = styled.div`
 @import url("https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto:400,700&display=swap");

.body {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  background: url(https://images.unsplash.com/photo-1587296104393-8db6cda4418d?q=80&w=2187&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)
    no-repeat center center fixed;
  background-size: cover;
}

.bball-holder {
  position: relative;
  margin: auto 2em;
  max-width: 1140px;
}

.bball--title {
  margin: 50px 0 25px;
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid;
}

.list {
  counter-reset: list;
  list-style: none;
  padding: 0;
}

.bball--item {
  counter-increment: list;
  position: relative;
  padding-left: 1.4em;
  font-size: 28px;
}

.bball--item::before {
  content: counter(list);
  position: absolute;
  top: 0.4em;
  left: 0;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  font-size: 0.4em;
  font-weight: 700;
  line-height: 2em;
  text-align: center;
  color: #141414;
  background-color: #fff;
}

.bball--link {
  position: relative;
  display: block;
  width: 100%;
  text-decoration: none;
  color: inherit;
}
.bball--link__header {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0 0 6px;
  font-size: inherit;
}

.bball--link__description {
  max-width: 650px;
  margin: 0 0 25px;
  padding: 10px 13px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  font-family: "Roboto Mono", monospace;
  font-size: 14px;
  font-weight: 400;
  background-color: #141414;
}

@media (min-width: 575px) {
  .bball--item {
    font-size: 30px;
  }
}

@media (min-width: 767px) {
  .bball--item {
    font-size: 32px;
  }
}

@media (min-width: 991px) {
  .bball--item {
    font-size: 34px;
    margin-bottom: 20px;
  }
  .bball--link__header {
    padding-right: 20px;
  }

  .bball--link__header span {
    position: relative;
    padding: 0 10px 0 0;
  }
  .bball--link__header span::after {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    width: calc(800px - 100%);
    border-bottom: 1px solid;
  }
  .bball--link:hover .bball--link__header span::after,
  .bball--link:focus .bball--link__header span::after{
    display: block;
  }
  .bball--link__description {
    display: none;
    position: absolute;
    top: 50%;
    left: 500px;
    transform: translate3d(0, -50%, 0);
    max-width: calc(100% - 500px);
    padding: 10px 13px;
    border: 1px solid;
    z-index: 2;
  }
  .bball--link:hover .bball--link__description,
  .bball--link:focus .bball--link__description {
    display: block;
  }
}

@media (min-width: 1199px) {
  .bball--item {
    font-size: 36px;
  }
}

`;
 
const NBA = () => {

  ReactGA.initialize('G-1D4GBSEPQK');

    const [players, setPlayers] = useState([
      {name:'Dwade',result:'06FINALSMVP'}, 
      {name:'BILLWALTON RIP',result:'EXPIRED '}, 
      {name:'JERRY WEST RIP',result:'JERRYWEST '}, 
      {name:'MRBIGSHOT',result:'CHAUNCEY BILLUPS'}, 
      {name:'PLASMABOSH',result:'CHRIS BOSH'}, 
        {name:'KAT50PIECE',result:'KAT'}, 
        {name:'HIMMYBUTLER',result:'EXPIRED'}, 
        {name:'TATUM2KMOBILE',result:'JASON TATUM'}, 
        {name:'TRILLWITHERS',result:'JOKIC'}, 
        {name:'CJHARDAWAY',result:'EXPIRED'}, 
        {name:'DANNYGREEN',result:'LUKA'}, 
        {name:'TIGERBELLY',result:'SCOTTIE PIPPEN'}, 
        {name:'ATHLETIC',result:'KEVIN GARNETT'}, 
        {name:'JOSHPOTTER',result:'GIANNIS'}, 
        {name:'BOMANIJONES',result:'SHAQ'}, 
        {name:'OLDMANJJ',result:'LARRY BIRD'}, 

    ])
return (
<StyledNBA>
    
    <div className="body">
<section className="bball-holder">
    <br />
    <br />
  <h1 className="bball--title">Players</h1>
  <ol className="list">
    {
        players?.map((player,index)=>(
    <li className="bball--item">
     <div className="bball--link">
        <h2 className="bball--link__header"><span>{player.name}</span></h2>
        <p className="bball--link__description">{player.result}</p>
      </div>
    </li>

        ))
    }
    {/* <li className="bball--item">
      <a href="javascript:void(0)" className="bball--link">
        <h2 className="bball--link__header"><span>JOSHPOTTER</span></h2>
        <p className="bball--link__description">I FORGET</p>
      </a>
    </li>
    <li className="bball--item">
      <a href="javascript:void(0)" className="bball--link">
        <h2 className="bball--link__header"><span>HIMMYBUTLER</span></h2>
        <p className="bball--link__description">JIMMY BUTLER</p>
      </a>
    </li>
    <li className="bball--item">
      <a href="javascript:void(0)" className="bball--link">
        <h2 className="bball--link__header"><span>TRILLWITHER</span></h2>
        <p className="bball--link__description">JOKIC I THINK</p>
      </a>
    </li>
    <li className="bball--item">
      <a href="javascript:void(0)" className="bball--link">
        <h2 className="bball--link__header"><span>CJHARDAWAY</span></h2>
        <p className="bball--link__description">PENNY HARDAWAY</p>
      </a>
    </li>
    <li className="bball--item">
      <a href="javascript:void(0)" className="bball--link">
        <h2 className="bball--link__header"><span>BOMANIJONES</span></h2>
        <p className="bball--link__description">I FORGET</p>
      </a>
    </li>
    <li className="bball--item">
      <a href="javascript:void(0)" className="bball--link">
        <h2 className="bball--link__header"><span>BOMANIJONES</span></h2>
        <p className="bball--link__description">I FORGET</p>
      </a>
    </li> */}
  </ol>
</section>
    </div>
</StyledNBA>
)
}
 
export default NBA