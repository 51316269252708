import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
 
const StyledPageHeader = styled.div`
 
 --red-text: #e0b646;
  --bkg-color: #070707;
  --black-color: #efefef;

  margin: 0;
  padding: 0;
  box-sizing: border-box;


  height: 100vh;


.body {
  display: grid;
  place-items: center;
  font-family: "Alatsi", sans-serif;
  background: var(--bkg-color);
}

.content h1,
.content h2,
.content h3,
.content h4 {
  margin: 0;
  padding: 0;
  line-height: 80%;
  text-transform: uppercase;
}

.container {
    width: calc(100vw - 5em);
  display: grid;
  place-items: center;
  height: 96%;
}

.content,
.header {
    width: 100%;
    overflow: hidden;
}
.header img {
    width: 100%;
    filter: saturate(5%) ;
    -webkit-filter: saturate(5%) ;
}

.content {
    text-align: left;
    position: relative;
    top: -120px;
    padding: 1em;
    background-color: rgba(0,0,0,0.08)
}
.content h2 {
    font-size: 3.5rem;
    color: var(--red-text);
    text-shadow: 2px 3px 5px rgba(0,0,0,0.67);

}
.content h1 {
    text-shadow: 2px 3px 5px rgba(0,0,0,0.67);
    filter: contrast(120%);
    font-size: 2.27rem;
    /* color: var(--bkg-color); */
    color: #efefef;
    font-weight: bold;
}

.header {
  object-fit: cover;
  width: 120%;
  box-sizing: border-box;
}

@media screen and (min-width: 750px) {
    .content,
.header {
    width: 700px;
    overflow: hidden;
}

.header {
  object-fit: cover;
  box-sizing: border-box;
}
.content h1 {
    text-shadow: 2px 3px 5px rgba(0,0,0,0.67);
    filter: contrast(120%);
    font-size: 5rem;
    /* color: var(--bkg-color); */
    color: #efefef;
}
}



.info {
    margin-top: 40px;
    color: var(--red-text);
    display: grid;
    grid-template-columns: 60% 1fr;
}
.info h3 {
  font-size: 30px;
  margin-bottom: 10px;
}
.info h4 {
  font-size: 20px;
  font-weight: normal;
}
.info p {
  margin: 20px 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  text-transform: none;
  width: 67vw;
}


.hotc {
  text-align: center;
  color: var(--black-color);
  display: flex;
  align-items: flex-end;
  gap: 1.32em;
}
.hotc p {
  font-size: 1rem;
  text-transform: uppercase;
}
.hotc strong {
  display: block;
  font-size: 1.4rem;
}
.hotc img {
  height: 80px;
}




.btn-primary,
a.btn-primary {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #f7be00;
  border-color: #f7be00;
  color: #000000;
}
.btn-primary:hover, .btn-primary:focus,
a.btn-primary:hover,
a.btn-primary:focus {
  text-decoration: none;
}
.btn-primary:hover, .btn-primary:focus,
a.btn-primary:hover,
a.btn-primary:focus {
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}

.hotc a.second-btn{
    width: 32vw;
}
@media screen and (max-width: 750px){
    .hotc a.second-btn{
    width: 80vw;
}
}

.btn-primary-dark,
a.btn-primary-dark {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: rgba(245,245,245,0.88);
  border-color: #e0b646;
  color: #111;
}
.btn-primary-dark:hover, .btn-primary-dark:focus,
a.btn-primary-dark:hover,
a.btn-primary-dark:focus {
  text-decoration: none;
}
.btn-primary-dark:hover, .btn-primary-dark:focus,
a.btn-primary-dark:hover,
a.btn-primary-dark:focus {
  background: #f7be00;
  border: 0.25em solid #f7be00;
  color: #000000;
}
.btn-primary-outline,
a.btn-primary-outline {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #f7be00;
  border-color: #f7be00;
  color: #000000;
  border: 0.25em solid #000000;
}
.btn-primary-outline:hover, .btn-primary-outline:focus,
a.btn-primary-outline:hover,
a.btn-primary-outline:focus {
  text-decoration: none;
}
.btn-primary-outline:hover, .btn-primary-outline:focus,
a.btn-primary-outline:hover,
a.btn-primary-outline:focus {
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}
.btn-primary-outline-dark,
a.btn-primary-outline-dark {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #000000;
  border-color: #000000;
  color: #f7be00;
  border: 0.25em solid #f7be00;
}
.btn-primary-outline-dark:hover, .btn-primary-outline-dark:focus,
a.btn-primary-outline-dark:hover,
a.btn-primary-outline-dark:focus {
  text-decoration: none;
}
.btn-primary-outline-dark:hover, .btn-primary-outline-dark:focus,
a.btn-primary-outline-dark:hover,
a.btn-primary-outline-dark:focus {
  background: #f7be00;
  border: 0.25em solid #f7be00;
  color: #000000;
}
.btn-outline-light,
a.btn-outline-light {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #f7be00;
  border-color: #f7be00;
  color: #000000;
  background: transparent;
  border: 0.25em solid #f7be00;
  color: #f7be00;
}
.btn-outline-light:hover, .btn-outline-light:focus,
a.btn-outline-light:hover,
a.btn-outline-light:focus {
  text-decoration: none;
}
.btn-outline-light:hover, .btn-outline-light:focus,
a.btn-outline-light:hover,
a.btn-outline-light:focus {
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}
.btn-outline-light:hover, .btn-outline-light:focus,
a.btn-outline-light:hover,
a.btn-outline-light:focus {
  background: #f7be00;
  border: 0.25em solid #f7be00;
  color: #000000;
}
.btn-outline-dark,
a.btn-outline-dark {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #f7be00;
  border-color: #f7be00;
  color: #000000;
  background: transparent;
  border: 0.25em solid #000000;
}
.btn-outline-dark:hover, .btn-outline-dark:focus,
a.btn-outline-dark:hover,
a.btn-outline-dark:focus {
  text-decoration: none;
}
.btn-outline-dark:hover, .btn-outline-dark:focus,
a.btn-outline-dark:hover,
a.btn-outline-dark:focus {
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}

`;
 
const PageHeader = () => {
    return (
        <StyledPageHeader>
                       <div className="body">

<div className="container">
<div className="header">
<img src="https://ballexclusives.com/wp-content/uploads/2022/10/Denard-Bros-Pro-Runs-Eddie-Denard.jpg" />
</div>
<div className="content">
<h2>EXPLORE</h2>
<h1>Pro&nbsp;Training
</h1>
    <br />
    <br />
<div className="info">
<section>
<h2 style={{fontWeight:'bold',fontSize:'2.12em',lineHeight:'1.05'}}>Denard Bros Pro Camps and Clinics</h2>
<br />
<h4 style={{color:'#efefef',lineHeight:'1.05'}}>Your #1 Destination for Pro Training</h4>
<p>Our camp is led by NBA Pro Trainers who will teach you everything you need to know to succeed at the highest level. You'll learn about the game's fundamentals, as well as advanced techniques and strategies. 
In addition to the on-court instruction, our camp also offers a variety of off-court programs that will help you develop into a well-rounded athlete and person. </p>
</section>
<br /><br />
<section className="hotc">
<Link to="/camps" className="btn-primary-dark">Learn More</Link>
</section>
</div>
<br />
<br />
<section className="hotc">


<Link
  className="group relative inline-block focus:outline-none focus:ring"
  to="/register"
>
  <span
    className="absolute inset-0 translate-x-1.5 translate-y-1.5 bg-yellow-300 transition-transform group-hover:translate-y-0 group-hover:translate-x-0"
  ></span>

  <span
    className="relative inline-block border-2 border-current px-8 py-3 text-sm font-bold uppercase tracking-widest text-black group-active:text-opacity-75"
  >
    Register Now
  </span>
</Link>



</section>

</div>


</div>
</div>

        </StyledPageHeader>
    )
}
 
export default PageHeader
 