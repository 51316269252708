import React, { useState } from 'react'
import styled from 'styled-components'
import TroySecondary from './TroySecondary';
 
const StyledConstruct  = styled.div`
 
  position: relative;
  font-family: "Fjalla One";
  color: #FFF;


html, body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: bold;
}

nav.navbar.navbar-default.navbar-fixed-top.navbar-top {
  background-color: #272727;
  transition: 0.5;
}
nav.navbar.navbar-default.navbar-fixed-top.navbar-top a {
  color: #AAA;
  font-size: 18px;
  transition: 0.5s;
}
nav.navbar.navbar-default.navbar-fixed-top.navbar-top a:hover {
  color: #404040;
}

.navbar-brand {
  margin: 10px;
  z-index: 3;
  width: 160px;
  height: 35px;
  background-size: cover;
  background-image: url("https://dl3.pushbulletusercontent.com/UHA56he29M0nSuM96W21jomtqSHj6b96/image.png");
}

section#top_brand {
    margin-bottom: 0px;
    background-image: url(https://scontent-ord5-1.xx.fbcdn.net/v/t39.30808-6/386751520_10232593560808582_1126672521456862802_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=dd5e9f&_nc_ohc=z0wGLpd2-SAAX_d5iHa&_nc_ht=scontent-ord5-1.xx&oh=00_AfAb0QukXsO6oXSfO4qzRkq2-gQEGfe7hIZfMekigVxfEA&oe=65AFADB3);
    background-size: cover;
    background-position: center center;
    padding-top: 18%;
    /* padding-bottom: 460px; */
  text-align: center;
}

section#top_brand .container-fluid {
    background: rgba(39, 39, 39, 0.67);
}

section#top_brand h3 {
  font-size: 60px;
  letter-spacing: 5px;
  color: #FFF;
}

section {
  /* padding: 100px 3em 100px 1em; */
  text-align: center;
  display: grid;
  place-content: center;
}

.title {
  margin-bottom: 80px;
  font-size: 32px;
  letter-spacing: 10px;
  color: #AAA !important;
}
.title .title2 {
  color: #FFF;
}

p {
  font-size: 15px;
  letter-spacing: 3px;
  color: #FFF !important;
  line-height: 25px;
}

section#section_brand {
  background-color: #272727;
}
section#section_brand .container {
  margin-top: 60px;
  margin-bottom: 80px;
}
section#section_brand .container .col-md-9 p {
  text-align: left;
  padding: 0px 20px 20px 20px;
}
section#section_brand .container .col-md-3.img {
  background-image: url(https://i.imgur.com/RZ7RzGS.png);
  background-size: cover;
  height: 285px;
}

section#section_projects {
  background-color: #404040;
  text-decoration: none;
}
section#section_projects section {
  padding: 0px 0px;
}
section#section_projects section img:hover {
  opacity: 0.5;
}
section#section_projects section h4 {
  background-color: rgba(39, 39, 39, 0.6);
  font-family: "微軟正黑體";
  margin-top: -100px;
  color: #fff;
  font-weight: 500;
  padding: 20px;
  letter-spacing: 3px;
  font-size: 22px;
}
section#section_projects .container-fluid {
  margin-top: 50px;
  margin-bottom: 60px;
}
section#section_projects .container-fluid .slider {
  width: 90%;
  margin: 0px auto;
}
section#section_projects .container-fluid .slick-slide {
  transition: all ease-in-out 0.3s;
  opacity: 0.2;
  margin: 0px 10px;
}
section#section_projects .container-fluid .slick-slide img {
  width: 100%;
}
section#section_projects .container-fluid .slick-prev:before, section#section_projects .container-fluid .slick-next:before {
  color: #FFF;
}
section#section_projects .container-fluid .slick-active {
  opacity: 1;
}
section#section_projects .container-fluid .slick-current {
  opacity: 1;
}

section#section_news {
  background-color: #272727;
}
section#section_news .container {
  margin-top: 60px;
  margin-bottom: 80px;
}
section#section_news .col-md-9 {
  padding: 0px 40px 0px 40px;
}
section#section_news .col-md-9 h3 {
  font-size: 30px;
  padding-bottom: 20px;
  text-align: left;
}
section#section_news .col-md-9 p {
  text-align: left;
}
section#section_news .col-md-3.img {
  background-image: url(https://i.imgur.com/PCBjHDl.png);
  background-size: cover;
  height: 285px;
}

section#section_other {
  background-image: url(https://i.imgur.com/PyGEeMN.png);
  background-size: cover;
  background-position: center center;
}
section#section_other .container {
  margin-top: 60px;
  margin-bottom: 80px;
}
section#section_other p {
  padding: 0px 10px 0px 30px;
  font-size: 15px;
  letter-spacing: 3px;
  color: #272727 !important;
  text-align: left;
}
section#section_other .title {
  padding-bottom: 20px;
  text-align: center;
}
section#section_other .title .title3 {
  color: #272727;
}
section#section_other .other_logo_img {
  margin: 30px;
  width: 300px;
  height: 55px;
  background-size: cover;
  background-image: url(https://i.imgur.com/0cVNRnB.png);
}

section#section_contact {
  background-color: #272727;
}
section#section_contact .text {
  font-size: 32px;
  letter-spacing: 10px;
  color: #AAA !important;
}
section#section_contact .container {
  margin-top: 60px;
  margin-bottom: 80px;
}
section#section_contact .container .col-md-12 p {
  margin-bottom: 40px;
}
section#section_contact .container .col-md-6 {
  padding-top: 30px;
  padding-bottom: 30px;
}
section#section_contact .container .col-md-6 .craft_pic {
  width: 262px;
  height: 40px;
  background-image: url(https://i.imgur.com/mFhCWKH.png);
  background-size: cover;
  margin-bottom: 10px;
  display: none;
}
section#section_contact .container .col-md-6 .chief_pic {
  width: 250px;
  height: 38px;
  background-image: url(https://i.imgur.com/2UYIbxw.png);
  background-size: cover;
  margin-bottom: 10px;
}
section#section_contact .container .col-md-6 .maps {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  background-size: cover;
  background-image: url(https://i.imgur.com/QQmRffm.png);
}
section#section_contact .container .col-md-6 .fb {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  background-size: cover;
  background-image: url(https://i.imgur.com/0nD0Wyk.png);
}
section#section_contact .container .col-md-6 .mail {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  background-size: cover;
  background-image: url(https://i.imgur.com/8p4Nda4.png);
}
section#section_contact .container .information {
  line-height: 35px;
  font-family: 微軟正黑體;
  font-size: 14px;
  letter-spacing: 2px;
  color: #FFF !important;
  margin-bottom: 10px;
}
section#section_contact .container .icon {
  display: inline-block;
  padding-top: 10px;
}
section#section_contact .container .logo {
  padding: 10px;
  display: inline-block;
}

.statement {
  padding: 100px 0px 0px 0px;
  text-align: center;
  font-family: 微軟正黑體;
  font-size: 14px;
  letter-spacing: 2px;
  color: #FFF !important;
}

@media screen and (max-width: 1200px) {
  .navbar-collapse.collapse {
    width: 100%;
  }

  section#section_brand .col-md-3.img {
    margin: 30px;
    background-position: center center;
  }

  section#section_other {
    background-image: none;
    background-color: #FFF;
  }

  section#section_news .col-md-3.img {
    margin: 30px;
    background-position: center center;
  }
}
@media screen and (max-width: 750px) {
  section#top_brand .container-fluid {
    margin-top: -150px;
    margin-bottom: -150px;
  }
  section#top_brand .container-fluid h3 {
    padding: 20px;
  }

  .cross {
    margin-right: 20px;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0px;
    z-index: 2;
    transition-duration: 0.5s;
    cursor: pointer;
  }
  .cross .line1, .cross .line2 {
    background-color: #AAA;
    width: 35px;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
    transition-duration: 0.5s;
  }
  .cross .line1 {
    transform: translateX(-50%) translateY(-280%);
  }
  .cross .line2 {
    transform: translateX(-50%) translateY(160%);
  }

  .cross1 .line1, .cross1 .line2 {
    background-color: #FFF;
    width: 40px;
  }
  .cross1 .line1 {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
  .cross1 .line2 {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }

  section#section_other {
    background-image: none;
    background-color: #FFF;
  }
}


/* Main */
.hero-section {
	text-align: center;
	padding: 6.5em 20px;
	/* background-color: #f2f2f2; */
}

.hero-section h1 {
	font-size: 2em;
	margin-bottom: 20px;
}

.hero-section p {
	color: #777;
	margin-bottom: 40px;
}

.cta-button {
	display: inline-block;
	padding: 12px 30px;
	background-color: #333;
	color: #fff;
	text-decoration: none;
	border-radius: 4px;
}

.services-section {
	padding: 80px 20px;
	text-align: center;
	background-color: #fff;
}

.services-section h2 {
	font-size: 24px;
	margin-bottom: 40px;
}

.services {
	display: flex;
	justify-content: space-between;
}

.service {
	flex-basis: 30%;
	padding: 20px;
	/* background-color: #f2f2f2; */
	border-radius: 4px;
	text-align: center;
}

.service h3 {
	font-size: 20px;
	margin-bottom: 10px;
}

.service p {
	color: #777;
}


/*  */






@-webkit-keyframes scroll-inner {
  from {
    margin-top: 15%;
  }
  to {
    margin-top: 50%;
  }
}
@keyframes scroll-inner {
  from {
    margin-top: 15%;
  }
  to {
    margin-top: 50%;
  }
}
@-webkit-keyframes scroll-mouse {
  from {
    margin-top: 0;
  }
  to {
    margin-top: 15px;
  }
}
@keyframes scroll-mouse {
  from {
    margin-top: 0;
  }
  to {
    margin-top: 15px;
  }
}
div.mouse-container {
  position: relative;
  display: block;
  height: 100px;
  margin-top: 1.32em;
}

div.mouse {
  position: relative;
  margin: 0 auto;
  display: block;
  width: 30px;
  height: 50px;
  border: solid 2px #fff;
  border-radius: 25px;
  -webkit-animation: scroll-mouse 1.5s;
  animation: scroll-mouse 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
div.mouse span.scroll-down {
  display: block;
  width: 7px;
  height: 7px;
  background: #fff;
  border-radius: 50%;
  margin: 15% auto auto auto;
  -webkit-animation: scroll-inner 1.5s;
  animation: scroll-inner 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  transform: translate3d(0,.75em,0);
}

`;
 
const Construct = () => {
    const [isNavOpen, setIsNavOpen] = useState(false)
return (
<StyledConstruct>

<nav className="navbar navbar-default navbar-fixed-top navbar-top">
  <div className="container">
    <div className={!isNavOpen ? "cross":"cross cross1"} onClick={()=>{setIsNavOpen(!isNavOpen)}}>
      <div className="line1"></div>
      <div className="line2"> </div>
    </div>
    <div className="navbar-header"><a href="#">
        <div className="navbar-brand"></div></a></div>
    <div className={!isNavOpen ? "navbar-collapse collapse" : "navbar-collapse"}>
      <div className="nav navbar-nav navbar-right">
        <li><a href="#section_brand">品牌 BRAND</a></li>
        <li><a href="#section_projects">實績 PROJECT</a></li>
        <li><a href="#section_news">時事 NEWS</a></li>
        <li><a href="#section_other">相關 OTHER</a></li>
        <li><a href="#section_contact">聯繫 CONTACT   </a></li>
      </div>
    </div>
  </div>
</nav>
<section id="top_brand">
  <div className="container-fluid">
 
  <main>
	<section class="hero-section">
		<h2 style={{maxWidth:'67vw',fontSize:'2em',textAlign:'left',margin:' 0 6.7% 6.7% 6.7%',padding:'0 3% .5em 3%',lineHeight:'1.25',fontWeight:'bold',fontFamily:'sans-serif'}}>WE HELP KEEP YOUR FAMILY SAFE & IMPROVE AIR QUALITY</h2>
        {/* <p>We are certified and Clean Ducts Residential and Commercial</p> */}
        <h5>Residential & Commercial

Air Ducts | Dryer Vents Cleaning</h5>
		<p style={{maxWidth:'75vw',margin:'auto',padding:'0 0 2.5em 0',marginLeft:'1.67em',textAlign:'left'}}>We are NADCA,  CDET  & CSIA Certified.</p>
		<a href="#" class="cta-button" style={{fontWeight:'bold',fontSize:'1.2em',background:'#006ec6'}}>Get a Free Quote</a>
        <div className="spacer" style={{height:'8em',width:'80vw'}}>
        <div class='mouse-container'>
  <div class='mouse'>
    <span class='scroll-down'></span>
  </div>
</div>
        </div>
	</section>
    </main>
    </div>
    </section>
	{/* <section class="services-section">
		<h2>Hizmetlerimiz</h2>
		<div class="services">
			<div class="service">
				<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24">
					<path d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20ZM13 12V16H11V12H8L12 8L16 12H13Z"></path>
				</svg>
				<h3>Hizmet 1</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
			</div>
			<div class="service">
				<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24">
					<path d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20ZM13 12V16H11V12H8L12 8L16 12H13Z"></path>
				</svg>
				<h3>Hizmet 2</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
			</div>
			<div class="service">
				<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24">
					<path d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20ZM13 12V16H11V12H8L12 8L16 12H13Z"></path>
				</svg>
				<h3>Hizmet 3</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
			</div>
		</div>
	</section> */}

{/*  */}
<TroySecondary /> 
{/*  */}
<section id="section_brand">
  <div className="container"> 
    <div className="row">
      <div className="col-md-12">
        <div className="title">OUR <span className="title2">BRAND </span></div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-9">
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam, perspiciatis cumque veniam quod maiores labore sit facilis facere voluptatum recusandae eligendi cum.<br/><br/>Lorem ipsum dolor, sit amet consectetur adipisicing.<br/>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores praesentium, facilis est ullam ab nobis explicabo blanditiis mollitia deserunt ad, illo quod?<br/><br/>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem delectus cumque voluptates veniam placeat. Mollitia quam voluptatum soluta dolor illo minima nostrum similique quaerat numquam dicta accusamus quidem iste quia, velit temporibus beatae, qui alias?。</p>
      </div>
      <div className="col-md-3 img"></div>
    </div>
  </div>
</section>
<section id="section_projects">
  {/* <div className="container-fluid">
    <div className="row">
      <div className="col-md-12">
        <div className="title">OUR <span className="title2">PROJECTS</span></div>
      </div>
    </div>
    <section className="variable slider">
      <div className="page1"><a href="https://codepen.io/HsiaoChan/full/bGwxopR" target="_blank"><img src="https://i.imgur.com/eLIQyhj.png" alt=""/></a>
        <h4>亞曼尼</h4>
      </div>
      <div className="page2"><a href="https://codepen.io/HsiaoChan/full/LYRJzxP" target="_blank"><img src="https://i.imgur.com/NTbDrFp.jpg" alt=""/></a>
        <h4>桂冠園</h4>
      </div>
      <div className="page3"><a href="https://codepen.io/HsiaoChan/full/ZEpMXeq" target="_blank"><img src="https://i.imgur.com/aspqyd0.png" alt=""/></a>
        <h4>桂冠園No.2  </h4>
      </div>
      <div className="page4"><a href="https://codepen.io/HsiaoChan/full/xxEeRoP" target="_blank"><img src="https://i.imgur.com/u303fQw.jpg" alt=""/></a>
        <h4>桂冠園No.3</h4>
      </div>
      <div className="page5"><a href="https://codepen.io/HsiaoChan/full/yLaxzPx" target="_blank"><img src="https://i.imgur.com/uIK0ERT.jpg" alt=""/></a>
        <h4>桂冠園No.5</h4>
      </div>
      <div className="page6"><a href="https://codepen.io/HsiaoChan/full/JjRaOGR" target="_blank"><img src="https://i.imgur.com/6pnUBqt.jpg" alt=""/></a>
        <h4>桂冠園No.6 藏富</h4>
      </div>
      <div className="page7"><a href="https://codepen.io/HsiaoChan/full/PoGdONe" target="_blank"><img src="https://i.imgur.com/HkdIQZN.jpg" alt=""/></a>
        <h4>桂冠園No.7 逸品</h4>
      </div>
      <div className="page8"><a href="https://codepen.io/HsiaoChan/full/qBaMVNq" target="_blank"><img src="https://i.imgur.com/DGITN6p.png" alt=""/></a>
        <h4>桂冠園No.8 藏居</h4>
      </div>
      <div className="page9"><a href="https://codepen.io/HsiaoChan/full/dypqZpR" target="_blank"><img src="https://i.imgur.com/3mltTdY.png" alt=""/></a>
        <h4>桂冠園No.9 心悅</h4>
      </div>
      <div className="page10"><a href="https://codepen.io/HsiaoChan/full/dypqZOR" target="_blank"><img src="https://i.imgur.com/XdbL0qo.png" alt=""/></a>
        <h4>啟鈺植覺</h4>
      </div>
    </section>
  </div> */}
</section>

{/*  */}
<section id="section_contact">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <h2 className="text">C O N T A C T</h2>
        <p>Get In Touch With Us</p>
      </div>
    </div>
    <div className="row">
      <div className="col-md-6">
        <div className="logo">
          <div className="craft_pic"></div>
        </div>
        <div className="information">03-555-4845<br/>302新竹縣竹北市三民路234號<br/>www.Vent2vent.com<br/>Vent2vent@gmail.com</div>
        <div className="icon"><a className="icon maps" href="https://www.google.com.tw/maps/place/302%E6%96%B0%E7%AB%B9%E7%B8%A3%E7%AB%B9%E5%8C%97%E5%B8%82%E4%B8%89%E6%B0%91%E8%B7%AF234%E8%99%9F/@24.833669,121.0093526,17z/data=!3m1!4b1!4m5!3m4!1s0x346836eaddcbe957:0xfe68c7726d5eed4d!8m2!3d24.8336641!4d121.0115466?hl=zh-TW&amp;authuser=0" target="_blank"></a><a className="icon fb" href="https://www.facebook.com/Craft.Ltd.2005" target="_blank"></a>
          {/* <!--a.icon.mail(href="")--> */}
        </div>
      </div>
      <div className="col-md-6">
        <div className="logo">
          <div className="chief_pic"></div>
        </div>
        <div className="information">03-555-4845<br/>302新竹縣竹北市三民路234號<br/>www.Vent2vent.com<br/>Vent2vent@gmail.com</div>
        <div className="icon"><a className="icon maps" href="https://www.google.com.tw/maps/place/302%E6%96%B0%E7%AB%B9%E7%B8%A3%E7%AB%B9%E5%8C%97%E5%B8%82%E4%B8%89%E6%B0%91%E8%B7%AF234%E8%99%9F/@24.833669,121.0093526,17z/data=!3m1!4b1!4m5!3m4!1s0x346836eaddcbe957:0xfe68c7726d5eed4d!8m2!3d24.8336641!4d121.0115466?hl=zh-TW&amp;authuser=0" target="_blank"> </a>
          {/* <!--a.icon.mail(href="")--> */}
        </div>
      </div>
    </div>
  </div>
  <div className="statement">Copyright © Craft Construction</div>
</section>
</StyledConstruct>
)
}
 
export default Construct