import React from 'react'
import styled from 'styled-components'
 
const StyledDispo  = styled.div`
 .dispo-body {
  margin: auto;
  min-width: 430px;
  background: #f4f4f4
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/567707/paper_fibers.png)
    repeat;
}

a.title {
}

h2.main, .heading-2 {
    color: rgb(234, 72, 44);
    left: 55px;
    top: 25px;
    line-height: 0.9;
    position: absolute;
    font-size: 90px;
    filter: drop-shadow(0.4025px 0.8425px 0.35px black) saturate(1.35) contrast(1.025);
    background-image: url(https://assets.codepen.io/4927073/hero10.jpg);
    -webkit-text-fill-color: transparent;
    -text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-size: contain;
    font-family: 'Zed Said';
}

.heading-2 {
  line-height: 1;
  background: linear-gradient(90deg, #ea4c89, #c52362);
  background-image: linear-gradient(
    45deg,
    #fb772f,
    #ff7261,
    #ff768e,
    #ff83b5,
    #f495d5,
    #f39cd7,
    #f1a2d9,
    #f0a9db,
    #fca9cb,
    #ffacbb,
    #ffb1ad,
    #ffb8a2 250%
  );
  background-size: contain;
  background-position: 0% 50%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1.125px;
  -webkit-text-stroke-color: hsl(0deg 0% 20% / 50%);
  top: -10px;
  font-family: Roboto;
}

h3.subtitle {
  font-family: UntitledSansSerif, "Trebuchet MS", sans-serif;
  position: absolute;
  left: 50px;
  text-transform: uppercase;
  letter-spacing: -0.1px;
  opacity: 0.6;
  z-index: 2;
  top: 70px;
  font-size: 14px;
  font-weight: 300;
}

/* INTRO GRID LAYOUT */

.intro_grid {
    grid-template-columns: 1fr 40% 1fr;
    grid-template-rows: repeat(2, auto);
    display: grid;
    padding: 30px 50px;
    margin: auto;
    margin-top: 160px;
}

.intro_grid {
    grid-template-columns: 1fr 40% 1fr;
    grid-template-rows: repeat(2, auto);
    display: grid;
    padding: 180px 60px 10px 60px;
    margin: auto;
    /* margin-top: 160px; */
    height: 100%;
    position: relative;
}

.header1 p {
  margin: 0;
  font-size: 16px;
  width: 250%;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.1;
  color: rgba(0, 0, 0, 0.87);
  font-family: FranklinGothic, Lato, sans-serif;
  letter-spacing: 0.125em;
}

.header1 .line2::before {
  content: "";
  display: block;
  width: 90%;
  height: 1px;
  margin: 0.375rem auto 0.375rem 0;
  background: #ea482c;
}

/*********************/
/** IMAGES + ICONS **/

.header2 {
  margin: auto;
  width: 100%;
}

img.mainimg {
  width: 195px;
  height: auto;
  position: absolute;
  top: -40px;
  z-index: -1;
}

img.mainimg.two {
    top: 110px;
    right: 10px;
    z-index: -2;
    width: 15%;
    max-width: 150px;
    top: auto;
    bottom: 10%;
}

img.mainimg.wheel {
  width: 100px;
  animation: spin 2s infinite linear;
  right: 29%;
  top: 6%;
  z-index: -1;
  transform: rotate(-2deg);
}

.curvy_circle {
    position: absolute;
    animation: 2.7s linear 0.1s infinite alternate float;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 5%;
    right: 10%;
    width: 90px;
}

.curvy_circle.two {
      animation: 2.7s linear 0.1s infinite alternate float;
    left: 75%;
    top: 45%;
    width: 50px;
    z-index: 0;
}

.curvy_circle.three {
    animation: 2.7s linear 0.1s infinite alternate float;
    left: 31%;
    top: 61%;
    width: 55px;
    z-index: -2;
}

p.tag-3,
p.tag-4,
p.tag-5 {
  font-family: "zed said";
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 2px;
  color: #fff;
  text-align: center;
  position: absolute;
  opacity: 1 !important;
  text-shadow: 0.125px 0.325px 0.325px #202124;
}

p.tag-3 {
  font-size: 12px;
  font-family: "Backhand", sans-serif;
}

/*********************/
/** IMAGES + ICONS **/

.header3 {
  grid-row: 2;
  grid-column: 1 / -1;
  display: grid;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  grid-template-columns: 1fr 45% 1fr;
  gap: 1rem;
  grid-template-rows: repeat(2, auto);
}

.col1 {
  grid-column: 1 / -2;
  z-index: 0;
}

p.subhead {
  font-size: 18px;
  font-family: Roboto;
  font-weight: 300;
  margin: 6px 0;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.col3 {
  grid-column: 1/-1;
  text-align: right;
  align-self: end;
  font-family: UntitledSansSerif, "Trebuchet MS", sans-serif;
  line-height: 1.25;
}

.col3 p {
  margin: auto;
}

.author_role {
  font-weight: 700;
  font-size: 14px;
  font-family: OpenSansCond, sans-serif;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 10px;
  padding-top: 15px;
}

span.author_role::before {
  content: "Written by ";
  font-family: Times;
  font-style: italic;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 300;
  font-size: 95%;
  padding-right: 8px;
}

/** FEATURES SECTION **/

.features {
  background: #e2ede6;
  padding: 1.5rem 30px;
}

.features.two {
  background: white;
  border-top: 1px solid black;
}

.features_grid {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.features_preview {
  gap: 15px;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.preview1 {
  width: calc(22% - 30px) !important;
  width: 80%;
  padding: 5px 15px;
  /*background: white;*/
  height: 222px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
}

img.preview_img, .preview_img .preview_img {
  width: 100%;
  height: 95px;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 12px;
  background: #fff;
  border: 1px solid #000;
}

.preview_img{
  position: relative;
}
.preview_img_hover {
  display: none;
}
.preview_img:hover::before,
.preview_img:hover .preview_img_hover {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    z-index: 50;
    margin: auto;
}


.preview_img:hover .preview_img_hover svg,
.preview_img:hover .preview_img_hover img {
    width: 65%;
    height: 65%;
    margin: auto;
    filter: drop-shadow(0px 0px 0.5px black) saturate(1.25) contrast(1.5);
  object-fit: contain;
}


.preview1 h3 {
  line-height: 1.1;
  margin: 4px auto 2px 0;
  color: #411112;
  font-size: 17px;
  width: 110%;
  font-family: Savoy, Georgia, serif;
      font-family: 'FranklinGothic';
    text-transform: uppercase;
  font-weight: 800;
  letter-spacing: -0.02em;
  text-shadow: 0.125px 0.325px 0.325px hsl(212 9% 48% / 1);
}

.preview1 p {
  margin: 2px 0 3px 0;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #58574b;
    word-spacing: -0.1em;
    color: #363636;
    font-family: FranklinGothic, Lato, sans-serif;
    color: #222;
    letter-spacing: 0.065em;
    font-weight: 400;
    font-size: 14px;
    width: 112%;
    line-height: 1.125;
}



.border_line {
  background: #ff614b;
  border-bottom: 1px solid #000;
  z-index: -2;
  display: flex;
  height: 35px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  align-items: center;
}
.border_line.two {
  background: #f2d6ff;
  border-top: 1px solid #000;
}

span.btext {
  display: flex;
  height: 1em;
  letter-spacing: 0.05em;
  line-height: 1;
  text-transform: uppercase;
  font-family: "Unica", "Helvetica Neue", helvetica, sans-serif;
  overflow: hidden;
}

/** FEATURES NAV **/

.features_nav h3 {
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
  font-family: Playfair Display;
  letter-spacing: 0.5px;
}

.features_nav ul li::before {
    content: "";
    background: #d9e1ff;
    width: 20px;
    height: 20px;
    display: inline-flex;
    position: absolute;
    border-radius: 50%;
    margin-left: -26px;
    justify-content: center;
    align-items: center;
  margin-top: 2px;
}

.features_nav ul li::before {
  content: counters(index, ".", decimal-leading-zero);
  font-size: 10px;
  font-weight: 800;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  align-self: flex-start;
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  font-family: "FranklinGothic";
  letter-spacing: 0.75px;
}

.features_nav ul li {
  line-height: 2.12;
  font-family: Roboto;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1.65px;
  counter-increment: index;
  display: block;
      margin-left: -12px;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}



.preview_info {
        font-weight: 400;
    font-size: 14px;
    width: 100%;
    line-height: 1.125;
    word-spacing: -0.065em;
    letter-spacing: 0.05em;
    color: #727272;
    letter-spacing: 0.75px;
    font-family: 'Zed Said';
    font-family: 'FranklinGothic';
}

a.location {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.neighborhood {
  opacity: 0.9;
}

/** MEDIA WIDTH **/

/*@media (min-width: 1050px) {
  .intro_grid {
    padding: 15px 60px;
  }

  img.mainimg {
    width: 198px;
  }
}
*/

@media (max-width: 860px) {
  .preview1 {
    width: calc(26% - 20px) !important;
  }
}

@media (max-width: 735px) {
  .preview1 {
    width: calc(25% - 10px) !important;
    width: calc(26% - 12px) !important;
  }
  
  .curvy_circle.one {
    width: 60px;
}
  
  p.tag-4 {
    font-size: 10px;
}
  
}

@media (max-width: 590px) {
  .features_nav {
    display: none;
  }
  
  h2.main {
    font-size: 70px;
    top: 50px;
}

  .features_grid {
    display: grid;
    grid-template-columns: 2fr;
  }

  img.mainimg {
    width: 207px;
  }
}

@media (max-width: 470px) {
  .preview1 {
    width: calc(40% - 6px) !important;
  }
}


@media (min-width: 1085px) {
  .preview1 {
    width: calc(20% - 45px) !important;
  }
}

/****************************/
/** KEYFRAMES + ANIMATION */

@keyframes float {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  100% {
    transform: translateY(-10%);
    -webkit-transform: translateY(-10%);
  }
}

`;
 
const Dispo = () => {
return (
<StyledDispo>
<div>
        <div className='dispo-body'>

{/* <!-- INTRO TITLE --> */}
<a class="title">
  <h2 class="main" data-js="reveal">
    New York
  </h2>
  <h3 style={{color: 'rgb(0, 0, 0)'}} class="subtitle">March 2024</h3>
</a>

{/* <!-- INTRO CONTENT --> */}
<div class="intro_grid">

  <div class="header1">
    <div class="line1">
      <p>New York City Issue</p>
    </div>

    <div class="line2">
      <p>Spring 2024</p>
    </div>

  </div>

  <div class="header2">

    <div class="curvy_circle one">
      <svg class="curvysvg2" xmlns="http://www.w3.org/2000/svg" width="147" height="145" viewBox="0 0 147 145" fill="none">
        <path d="M37.8543 140.748L43.9224 96.947L0.734863 87.4492L38.7634 64.8839L19.2621 25.1968L60.6148 40.8594L79.4846 0.868164L93.022 42.9645L136.054 32.7833L111.582 69.614L146.371 96.9095L102.318 100.74L102.669 144.958L72.2068 112.905L37.8543 140.748Z" fill="#BD7AB3"></path>
      </svg>
      <p class="tag-4">Drinks</p>
    </div>

    <div class="curvy_circle two">

      <img class="mainimg wheel" alt="colour wheel" src="https://assets.codepen.io/4927073/flower1.png"/>
    </div>

    <div class="curvy_circle three">
      <svg class="curvysvg3" xmlns="http://www.w3.org/2000/svg" width="131" height="129" viewBox="0 0 131 129" fill="none">
        <path d="M76.8856 121.501C73.3771 130.842 60.2439 131.061 56.4254 121.843L51.6269 110.259C49.4306 104.956 43.5267 102.234 38.0678 104.006L26.1418 107.877C16.6514 110.958 8.29138 100.827 13.1178 92.0941L19.1829 81.1199C21.9591 76.0967 20.4067 69.7833 15.6177 66.6203L5.15502 59.7101C-3.17095 54.211 -0.46242 41.3583 9.37462 39.6868L21.7361 37.5864C27.3943 36.6249 31.3624 31.4749 30.8494 25.7586L29.7287 13.2701C28.8369 3.33203 40.5743 -2.56396 48.0144 4.08479L57.3639 12.4398C61.6434 16.2641 68.1439 16.1555 72.2933 12.1904L81.3584 3.52777C88.5723 -3.3658 100.5 2.1348 99.9407 12.0971L99.2378 24.6161C98.9161 30.3464 103.054 35.361 108.741 36.1328L121.166 37.8192C131.053 39.1612 134.19 51.9163 126.052 57.6904L115.826 64.9463C111.145 68.2675 109.805 74.6291 112.747 79.5568L119.175 90.3223C124.291 98.8893 116.274 109.294 106.686 106.532L94.6371 103.061C89.1221 101.472 83.3125 104.39 81.2945 109.763L76.8856 121.501Z" fill="#D9F141"></path>
      </svg>
      <p class="tag-5">Food</p>
    </div>

    <img class="mainimg wheel" alt="colour wheel" src="https://beciorpin.com/assets/images/gif/triangle.gif"/>

    <img class="mainimg" src="https://assets.codepen.io/4927073/chrysler_building.png" alt="chryslerbuilding"/>
    <img class="mainimg two" src="https://beciorpin.com/assets/images/gif/amoeba.gif" alt="chryslerbuilding"/>

  </div>

  <div class="header3">
    <div class="col1">
      {/* <!--<p class="subhead">Kimora Lee Simmons Has Had Enough Drama. But She Wouldn’t Mind Being In Love Again.</p>--> */}
    </div>

    <div class="col2">

    </div>

    <div class="col3">
      <p>
        <span class="author_role">
          Hannah Luebbering
        </span>
      </p>

    </div>

  </div>

</div>

{/* <!--<div class = "border_line">
  <span class = "btext">
    New York City •
    New York City •
    New York City
    
  </span>
</div>--> */}

<div class="border_line two">
  <span class="btext">
    New York City •
    New York City •
    New York City

  </span>
</div>

{/* <!-- FEATURES PREVIEW --> */}
{/* 
<!------------------------------>
<!-- RESTAURANT DINING --> */}
<div class="features">

  <div class="features_grid">

    <div class="features_nav">
      <h3>Dinner and Fine Dining</h3>
      <ul>
        <li> JoJo </li>
        <li> L'Artusi </li>
        <li> One White Street
</li>
        <li> Don Angie </li>
        <li> Waverly Inn </li>
        <li> Via Carota </li>
        <li> Sartiano's </li>

      </ul>
    </div>

    <div class="features_preview">

      <div class="preview1">
        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src = "https://images.squarespace-cdn.com/content/v1/656fb2dc7c448c206b092d68/8f586fd6-9598-4261-894e-c1a4b78e8831/Townhouse+Submark+-+BLACK.png"/>
          </div>

          <img class="preview_img" alt="" src="https://s3.amazonaws.com/jojorestaurantnyc/JoJo-FR-11_1600x1000-1513623619206.jpg"/>
        </div>
        <h3>JoJo</h3>

        <p class="preview_body">French townhouse restaurant serving a farm to table menu.</p>
        <div class="preview_info">
          <a class="location">
            160 E 64th St, NY 10065 <span class="neighborhood">Upper East Side</span>
          </a>

        </div>
      </div>

      <div class="preview1">
        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://bondstrestaurant.com/wp-content/themes/bondPSD/images/BONDSTLogo.png"/>
          </div>

          <img class="preview_img" alt="" src="https://thevendry.com/cdn-cgi/image/width=3840,quality=75,fit=contain,metadata=none,format=auto/https%3A%2F%2Fs3.amazonaws.com%2Fuploads.thevendry.co%2F23050%2F1655742614920_Second-Floor-Dining00142.jpg"/>
        </div>
        <h3>BONDST</h3>

        <p class="preview_body">Sushi & Japanese dishes in a chic, trendy atmosphere.</p>
        <div class="preview_info">
          <a class="location">6 Bond St, NY 10012 <span class="neighborhood">NoHo</span></a>

        </div>
      </div>

      <div class="preview1">

        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img  style={{filter: 'invert(1) contrast(1.5) saturate(1.125)'}} src="https://images.getbento.com/dyX6A9z2QTaTZhOPnu7i_logo.png"/>

          </div>

          <img class="preview_img" alt="" src="https://images.getbento.com/accounts/bdb69cbcf8445b793b8602ba4bf3f6c2/media/images/92450image0_2.JPEG?w=1800&fit=max&auto=compress,format&h=1800"/>
        </div>
        <h3>L'Artusi</h3>

        <p class="preview_body">Rustic Italian small plates and wine bar.</p>
        <div class="preview_info">
          <a class="location">228 W 10th St, NY 10014 <span class="neighborhood">West Village</span></a>

        </div>
      </div>

      <div class="preview1">
        <div class="preview_img">
          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://images.getbento.com/accounts/39cac3711f103869dfeba52b4e1be30b/media/images/241486845H1umcAKw.png"/>
          </div>

          <img class="preview_img" alt="" src="https://wwd.com/wp-content/uploads/2021/08/One-White_Interiors_2nd-Fl_024.jpg"/>
        </div>
        <h3 style={{width:'110%'}}>One White Street</h3>

        <p class="preview_body">Micro-Seasonal townhouse restaurant.</p>
        <div class="preview_info">
          <a class="location">1 White St, NY 10013 <span class="neighborhood">Tribeca</span></a>

        </div>
      </div>

      <div class="preview1">
        <div class="preview_img">
          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://images.getbento.com/accounts/0cda43805165cb025167674faaef22fb/media/images/22650Alba_Wordmark_Coral.png"/>
          </div>
          <img class="preview_img" alt="" src="https://cdn.mos.cms.futurecdn.net/GJSAUY8dun8V4WFYy4jkES-1920-80.jpg.webp"/>
        </div>
        <h3>Cucina Alba</h3>

        <p class="preview_body">Vacation Italian style cuisine at the edge of the High Line.
        </p>
        <div class="preview_info">
          <a class="location">511 W 18th St, NY 10011 <span class="neighborhood">Chelsea</span></a>

        </div>
      </div>

      <div class="preview1">
        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://getbento.imgix.net/accounts/d704d1f12e9025a5303f862eeb87f5c0/media/images/DonAngieLarge.png?w=1000&fit=max&auto=compress,format&h=1000"/>
          </div>

          <img class="preview_img" alt="" src="https://media.cntraveler.com/photos/5b50968ab6801415eae75256/16:9/w_2560,c_limit/Don-Angie__2018_-(14).jpg" src2="https://static.dezeen.com/uploads/2018/03/don-angie-grt-architects-interiors-restaurant-new-york-city-usa_dezeen_2364_col_7.jpg"/>
        </div>
        <h3>Don Angie</h3>

        <p class="preview_body">Italian-American classics with wine and cocktails.</p>
        <div class="preview_info">

          <a class="location">
            103 Greenwich Ave, NY 10014 <span class="neighborhood">West Village</span>

          </a>

        </div>
      </div>

      <div class="preview1">
        <div class="preview_img">
          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img style={{width: '80%', height: '80%'}} src="https://assets.codepen.io/4927073/altroparadiso.png"/>
          </div>

          <div class="preview_img" style={{backgroundImage: "url('https://media.architecturaldigest.com/photos/574f32561cd9644b0beb1e5f/1:1/w_2380,h_2380,c_limit/cafe-altro-paradiso-new-york.jpg')", backgroundSize: 'cover', backgroundPosition: '50% 25%'}}></div>
        </div>
        <h3>Altro Paradiso</h3>

        <p class="preview_body">Modern traditional Italian cooking in a wood-accented space with a marble bar.</p>
        <div class="preview_info">
          <a class="location">234 Spring St, NY 10013 <span class="neighborhood">SoHo</span></a>

        </div>
      </div>

      <div class="preview1">
        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://static.wixstatic.com/media/0ab73a_a5d35b5517ab4834a829b662c3fd2352~mv2.jpg/v1/fill/w_148,h_186,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/0ab73a_a5d35b5517ab4834a829b662c3fd2352~mv2.jpg"/>
          </div>

          <img class="preview_img" alt="" src="https://cdn.vox-cdn.com/thumbor/ctJbMV8waCFiV-2iJ6hPS_Gii5w=/0x312:6000x3687/1600x900/cdn.vox-cdn.com/uploads/chorus_image/image/51368971/emilio_s_ballato-1.0.0.jpg"/>
        </div>
        <h3>Emilio's Ballato</h3>

        <p class="preview_body">Old-school Italian restaurant and celebrity hangout.</p>
        <div class="preview_info">
          <a class="location">55 E Houston St, NY 10012 <span class="neighborhood">Nolita</span></a>

        </div>
      </div>

      <div class="preview1">
        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://www.waverlynyc.com/image001.jpg"/>
          </div>

          <img class="preview_img" alt="" src="https://photos.airmail.news/cw3m0hoh43x9rj9n8rzacqlxd802-173b32da8728a93e133421ddeffd6ec9.jpg" src2="https://assets.vogue.com/photos/5d30cd219e1f400008540dc6/master/w_1600,c_limit/03-agnona.jpg"/>
        </div>
        <h3>Waverly Inn</h3>

        <p class="preview_body">Classic American comfort food served with cocktails in a clubby space.</p>
        <div class="preview_info">
          <a class="location">16 Bank St, NY 10014 <span class="neighborhood">Greenwich Village</span></a>

        </div>
      </div>

      <div class="preview1">
        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://images.squarespace-cdn.com/content/v1/59107ff103596e93eb742b01/1692300775145-6S77Z1VGEGJOET7YAER0/Via+Carota+Logo_Louise+Fili.png?format=1500w"/>

          </div>

          <img class="preview_img" alt="" src="https://cdn.vox-cdn.com/uploads/chorus_asset/file/2487154/15863064352_52c0ade5e7_h__1_.0.jpg" src2="https://media.newyorker.com/photos/5b8ebbfdaf438f6d7f78ae31/master/w_2560%2Cc_limit/Goldfield-ViaCorota-Primary.jpg"/>
        </div>
        <h3>Via Carota</h3>

        <p class="preview_body">Italian trattoria serving traditional plates & apéritifs in a rustic, cozy space.</p>
        <div class="preview_info">
          <a class="location">51 Grove St, NY 10014 <span class="neighborhood">West Village</span></a>

        </div>
      </div>

      <div class="preview1">
        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img style={{width: '80%'}} src="https://static.wixstatic.com/media/27cb8d_ca5a13dc54d14e1c993f479f73bd431c~mv2.png/v1/fill/w_1012,h_104,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Logo%20CC%20C%20(2).png"/>
          </div>

          <div class="preview_img" alt="" style={{backgroundImage: "url('https://media.guestofaguest.com/t_article_content/gofg-media/2022/05/1/55950/280068388_518206079964204_694006680892853961_n.jpg')",backgroundSize: 'cover',backgroundSize: '125%',backgroundPosition: '0% 60%'}}></div>
        </div>
        <h3>Casa Carmen</h3>

        <p class="preview_body">Traditional Mexican recipes and hospitality to the heart of Flatiron.</p>
        <div class="preview_info">
          <a class="location">5 W 21st St, NY 10010 <span class="neighborhood">Flatiron</span></a>

        </div>
      </div>

      <div class="preview1">
        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img style={{filter: 'contrast(1.5) saturate(10)'}} src="https://sartianos.com/wp-content/uploads/2023/06/sartiano-mercer-logo.svg"/>
          </div>

          <img class="preview_img" alt="" src="https://assets.vogue.com/photos/648a50d7bbb79c09d48cdcd6/master/w_2240,c_limit/SARTIANO'S%20Bar_Photo%20Credit_%20Teddy%20Wolff.jpg"/>
        </div>
        <h3>Sartiano's</h3>

        <p class="preview_body">Italian heritage modern fine dining.</p>
        <div class="preview_info">
          <a class="location">99 Prince St, NY 10012 <span class="neighborhood">SoHo</span></a>

        </div>
      </div>

      <div class="preview1">
        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://images.getbento.com/accounts/0ad5a0dc17efb65589997db54130f0b0/media/images/23630Finallogo.png"/>
          </div>

          <div class="preview_img" style={{backgroundImage: "url('https://res.cloudinary.com/the-infatuation/image/upload/f_auto/q_auto/v1683549680/images/NYC_Torrisi_Interior_KatePrevite_00009_vvzopf.jpg')",backgroundSize: '200%',backgroundPosition: '30% 75%'}}></div>

        </div>
        <h3>Torrisi</h3>

        <p class="preview_body">Little Italy inspired à la carte menu.</p>
        <div class="preview_info">
          <a class="location">275 Mulberry St, NY 10012 <span class="neighborhood">SoHo</span></a>

        </div>
      </div>

      {/* <!-- UPLAND --> */}
      <div class="preview1">
        <div class="preview_img">
          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://uplandnyc.com/wp-content/uploads/sites/2/2018/05/upland.png"/>
          </div>

          <img class="preview_img" alt="" src="https://images.ctfassets.net/7mbidstwva6z/4GKbPMwIjW93glHt5Ythlc/431646bc464a5c8ed61e9fd6656240e2/upland_2015_hires_mikkel_vang_10.jpg?w=2400&h=1600&fl=progressive&q=50&fm=jpg"/>
        </div>
        <h3>Upland</h3>

        <p class="preview_body">Chic brasserie with Californian & Italian influences.</p>
        <div class="preview_info">
          <a class="location">345 Park Ave S, NY 10010 <span class="neighborhood">Flatiron</span></a>

        </div>
      </div>
      
      
      
      {/* <!-- HIDDEN LEAF --> */}
      <div class="preview1">
        <div class="preview_img">
          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://static.spotapps.co/website_images/ab_websites/117031_website/logo.png"/>
          </div>

          <img class="preview_img" alt="" src="https://media.timeout.com/images/105939066/image.jpg"/>
        </div>
        <h3>Hidden Leaf</h3>

        <p class="preview_body"> Pan-Asian bites & cocktails crafted in a chic, tucked-away restaurant & bar</p>
        <div class="preview_info">
          <a class="location"> Manhattan W Plz, NY 10001 <span class="neighborhood">Chelsea</span></a>

        </div>
      </div>

      

    </div>

  </div>

</div>
{/* 
<!------------------------------>
<!-- BREAKFAST/BRUNCH + CAFES --> */}

<div class="features two">

  <div class="features_grid">

    <div class="features_nav">
      <h3>Breakfast/Brunch and Cafés</h3>
      <ul>
        <li>Jack's Wife Freda </li>
        <li>The Butcher's ... </li>
        <li> ATLA </li>
        <li> Lafayette</li>

      </ul>
    </div>

    <div class="features_preview">

      <div class="preview1">
        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://felixroastingco.com/cdn/shop/files/New_Logo_Green_-_Square_500x.png?v=1632506746"/>
          </div>

          <img class="preview_img" alt="" src="https://images.squarespace-cdn.com/content/v1/5e96d7cc6d152c45499166ed/1607681239627-PPUPNK8ZZRRE6BKNAMEA/ADDITIONAL-Felix+Roasting+Co.+%282%29.JPG"/>
        </div>
        <h3>Felix Roasting Company</h3>

        <p class="preview_body"> Espresso, coffee cocktails, pastries presented in a designer space with a warm vibe.</p>
        <div class="preview_info">
          <a class="location">450 Park Ave S, NY 10016 <span class="neighborhood">Nomad</span>
          </a>

        </div>
      </div>

      <div class="preview1">
        <div class="preview_img">
          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img style={{width: '88%', height: '88%'}} src="https://jackswifefreda.com/wp-content/uploads/2023/12/FooterLogo.svg"/>
          </div>

          <img class="preview_img" alt="" src="https://hips.hearstapps.com/hmg-prod/images/jacks-wife-freda-6408ef6934a10.jpg"/>
        </div>
        <h3>Jack's Wife Freda</h3>

        <p class="preview_body">American-Greek modern bistro</p>
        <div class="preview_info">
          <a class="location">226 Lafayette St, NY 10012 <span class="neighborhood">SoHo</span></a>

        </div>
      </div>

      <div class="preview1">
        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img style={{width: '80%', height: '80%', filter: 'invert(1) contrast(1.5) saturate(1.125)'}} src="https://static1.squarespace.com/static/653e71891dba722a34d415fe/t/660e8b56d29aaf788ffdd433/1712229207072/bd-logo.svg"/>
          </div>

          <img class="preview_img" alt="" src="https://images.otstatic.com/prod1/46759675/3/huge.jpg"/>
        </div>
        <h3>The Butcher’s Daughter</h3>

        <p class="preview_body">Plant-based cafe and juice bar.</p>
        <div class="preview_info">
          <a class="location">19 Kenmare St NY 10012 <span class="neighborhood">Nolita</span></a>

        </div>
      </div>

      <div class="preview1">
        <a class="preview_img" href="https://www.lamercerieny.com/" target="_blank">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://www.lamercerieny.com/images/la-mercerie-with-crest-logo.svg"/>
          </div>

          <img class="preview_img" alt="" src="https://static01.nyt.com/images/2017/12/20/dining/20sell7SUB/merlin_131178758_51431cca-996a-4543-90ea-7a866dbccdfb-superJumbo.jpg"/>
        </a>
        <h3>La Mercerie</h3>

        <p class="preview_body">Contemporary French eatery and pastry counter inside Roman and Williams Guild.</p>
        <div class="preview_info">
          <a class="location">53 Howard St, NY 10013 <span class="neighborhood">Flatiron</span></a>

        </div>
      </div>

      {/* <!-- DO NOT FEED ALLIGATORS --> */}
      <div class="preview1">
        <a class="preview_img" href="https://www.dudleysnyc.com/" target="_blank">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://donotfeedalligators.com/cdn/shop/files/DNFA_Logo.png?v=1709847672&width=1050"/>
          </div>

          <img class="preview_img" alt="" src="https://interiordesign.net/wp-content/uploads/2023/05/id_nycxdesign_2023_rapt-studio-do-not-feed-alligators.jpg"/>
        </a>
        <h3>Do Not Feed Alligators</h3>

        <p class="preview_body">Trendy coffee shop and bookstore</p>
        <div class="preview_info">
          <a class="location"> 337 Bleecker St, NY 10014 <span class="neighborhood"></span></a>

        </div>
      </div>
      
      
      {/* <!-- ATLA --> */}
      <div class="preview1">
        <div class="preview_img">
          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://images.getbento.com/accounts/eb3e05a8a6743d68fa3c40c7f1345096/media/images/logo_atla_negro.png"/>
          </div>

          <img class="preview_img" alt="" src="https://media.cntraveler.com/photos/5a95ce8860543c4ae96c2fc8/master/pass/Atla_2018_20180226-NYC-Shoot-29.jpg"/>
        </div>
        <h3>ATLA</h3>

        <p class="preview_body">Contemporary Mexican bites</p>
        <div class="preview_info">
          <a class="location">372 Lafayette St, NY 10012 <span class="neighborhood">NoHo</span></a>

        </div>
      </div>
      
      
      {/* <!-- DUDLEYS --> */}
      <div class="preview1">
        <a class="preview_img" href="https://www.dudleysnyc.com/" target="_blank">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://images.getbento.com/accounts/80c8d1c78407a7279c16dad3231c8162/media/images/3376logo.svg?w=1000&fit=max&auto=compress,format&h=1000"/>
          </div>

          <img class="preview_img" alt="" src="https://thevendry.com/cdn-cgi/image/width=3840,quality=75,fit=contain,metadata=none,format=auto/https%3A%2F%2Fs3.us-east-1.amazonaws.com%2Fuploads.thevendry.co%2F23050%2F1676375654558_275654926_500213271740081_1311502829219723030_n.jpg"/>
        </a>
        <h3>Dudley's</h3>

        <p class="preview_body">Chic cafe & bar serving locally sourced American with an Australian twist</p>
        <div class="preview_info">
          <a class="location"> 85 Orchard St, NY 10002 <span class="neighborhood">Lower East Side</span></a>

        </div>
      </div>

      
      {/* <!-- CAFE KITSUNE --> */}
      <div class="preview1">
        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://getbento.imgix.net/accounts/d704d1f12e9025a5303f862eeb87f5c0/media/images/DonAngieLarge.png?w=1000&fit=max&auto=compress,format&h=1000" />
          </div>

          <img class="preview_img" alt="" src="https://maisonkitsune.com/mk/wp-content/uploads/2019/10/screen-shot-2021-08-16-at-11.45.12-am-980x1480-c-default.webp" />
        </div>
        <h3>Café Kitsuné</h3>

        <p class="preview_body">All-day cafe and wine bar from Maison Kitsuné serving specialty coffee, plus sweet and savory fare.</p>
        <div class="preview_info">
          <a class="location">550 Hudson St NY 10014 <span class="neighborhood">West Village</span></a>

        </div>
      </div>

      
      {/* <!-- LAFAYETTE --> */}
      <div class="preview1">
        <div class="preview_img">

          {/* <!-- hover image --> */}
          <div class="preview_img_hover">
            <img src="https://images.getbento.com/accounts/229072ec86f44588c6e94cd077743323/media/images/5022Lafayette_Scrolling.png"/>
          </div>

          <img class="preview_img" alt="" src="https://media.cntraveler.com/photos/5a821cc3a2fdaf4c74bb5f78/16:9/w_2560%2Cc_limit/Lafayette_Noah-Fecks_2018_Dining-Room-Junker-Mural_NF.jpg"/>
        </div>
        <h3>Lafayette</h3>

        <p class="preview_body"> French grand café and bakery</p>
        <div class="preview_info">
          <a class="location">380 Lafayette St, NY 10003 <span class="neighborhood">NoHo</span></a>

        </div>
      </div>

    </div>

  </div>
</div>
{/* 
<!------------------------------>
<!-- ROOFTOP COCKTAIL BARS --> */}
<div class="features three">

  <div class="features_grid">

    <div class="features_nav">
      <h3>Cocktail Bars and Lounges</h3>
      <ul>
        <li> Dante </li>
        <li> Temple Bar </li>
        <li> Peakaboo </li>
        <li> RH Rooftop </li>
        <li> Jac's On Bond </li>

      </ul>
    </div>

    {/* <!--------------------------->
    <!-- ROOFTOP COCKTAIL BARS --> */}
    <div class="features_preview">

      <div class="preview1">

        <div class="preview_img">
          
          <div class="preview_img_hover">
            <img src="https://images.getbento.com/accounts/e8eee6aef7c2e8242e267a82a199ac35/media/images/Dante__White.png?w=1000&fit=max&auto=compress,format&h=1000"/>
          </div>
          
          <img class="preview_img" alt="" src="https://feastio.com/wp-content/uploads/2019/04/Cafe-Dante.jpg" src2="https://yorkavenueblog.com/wp-content/uploads/2022/03/449D2A62-30E2-494C-8B03-E3EA58848249-1-scaled.jpg"/>
        </div>
        <h3>Dante</h3>

        <p class="preview_body">Cafe and craft cocktail bar in Greenwich Village.</p>
        <div class="preview_info">
          <a class="location">79-81 MacDougal St, NY 10012 <span class="neighborhood">West Village</span></a>

        </div>
      </div>

      <div class="preview1">

        <div class="preview_img">
          
          <div class="preview_img_hover">
            <img style={{filter: 'invert(1) contrast(1.5) saturate(1.125)'}} src="https://images.squarespace-cdn.com/content/v1/614340cddd46577c8e89d02d/36c20e2e-3bf1-4acf-9e3b-fb613fe7b942/Temple+Bar+Mobile+Wordmark_Profile+Photo+04.png"/>
          </div>
          <img class="preview_img" alt="" src="https://res.cloudinary.com/the-infatuation/image/upload/q_auto,f_auto/cms/DF_TempleBar2006"/>
        </div>
        <h3>Temple Bar</h3>

        <p class="preview_body">Glamorous bar serving traditional cocktails and creative new drinks. </p>
        <div class="preview_info">
          <a class="location">332 Lafayette St, NY 10012 <span class="neighborhood">West Village</span></a>

        </div>
      </div>

      <div class="preview1">

        <div class="preview_img">
          
          <div class="preview_img_hover">
            <img style={{filter: 'contrast(1.5) saturate(1.5)', width: '75%', height: '75%'}} src="https://static.vecteezy.com/system/resources/previews/013/219/734/original/gold-metallic-wine-glass-png.png"/>
          </div>
          
          <img class="preview_img" alt="" src="https://www.ahotellife.com/wp-content/uploads/2022/07/Bar-Veronika-Interior-_-CREDIT-Alex-Staniloff-for-Veronika-2-A-Hotel-Life.jpg"/>
        </div>
        <h3>Bar Veronika</h3>

        <p class="preview_body">Bespoke craft cocktails, wines, along with a variety of small plates.</p>
        <div class="preview_info">
          <a class="location">281 Park Ave. S, NY 10010 <span class="neighborhood">NoHo</span></a>

        </div>
      </div>

      <div class="preview1">

        <div class="preview_img">
          <div class="preview_img_hover">
            <img src="https://www.peaknyc.com/wp-content/themes/peak/library/images/peakaboo-logo.png"/>
          </div>
          <img class="preview_img" alt="" src="https://nypost.com/wp-content/uploads/sites/2/2021/11/peak-7.jpg?quality=90&strip=all"/>
        </div>
        <h3>Peakaboo</h3>

        <p class="preview_body">Modern cocktail bar with skyline views.</p>
        <div class="preview_info">
          <a class="location">25 W 28th St, NY 10001 <span class="neighborhood">West Village</span></a>

        </div>
      </div>

      <div class="preview1">

        <div class="preview_img">
          <div class="preview_img_hover">
            <img style={{filter: 'contrast(1.5) saturate(1.5)', width: '80%', height: '80%'}} src="https://images.getbento.com/accounts/1cf2b7830837af2c4dafd105dc16b240/media/images/12753manhatta_website_character_scroll_1.png"/>
          </div>
          <img class="preview_img" alt="" src="https://static01.nyt.com/images/2022/03/09/dining/09manhatta-bar1/04manhatta-bar1-mediumSquareAt3X.jpg"/>
        </div>
        <h3>Manhatta</h3>

        <p class="preview_body">Ritzy New American cuisine and city views.</p>
        <div class="preview_info">
          <a class="location"> 28 Liberty St, NY 10005 <span class="neighborhood">Lower Manhattan</span></a>

        </div>
      </div>

      {/* <!-- RH ROOFTOP --> */}

      <div class="preview1">
        

        <div class="preview_img">
          <div class="preview_img_hover">
            <img style={{filter: 'contrast(1.5) saturate(1.5)'}} src="https://images.vexels.com/media/users/3/305305/isolated/preview/271eb3fd9def5f6841b2814aff004022-green-martini.png"/>
          </div>
          <img class="preview_img" alt="" src="https://media.timeout.com/images/105371007/image.jpg"/>
        </div>
        <h3>RH Rooftop</h3>

        <p class="preview_body">Glamorous rooftop restaurant offering American fare amid chandeliers, greenery, and skyline views.</p>
        <div class="preview_info">
          <a class="location"> 9 9th Ave, NY 10014 <span class="neighborhood">Meatpacking District</span></a>

        </div>
      </div>

      {/* <!-- JAC'S ON BOND --> */}

      <div class="preview1">

        <div class="preview_img">
          <div class="preview_img_hover">
            <img style={{filter: 'invert(1) contrast(1.5) saturate(1.5)'}} src="https://images.getbento.com/accounts/e38c677394ad8ba3f1e7c312ce3a1b48/media/images/70526logo.svg"/>
          </div>
          <img class="preview_img" alt="" src="https://assets.vogue.com/photos/63eea1a904a89ab0c9b8974d/master/w_2560%2Cc_limit/Jacs%2520on%2520Bond0104.jpg"/>
        </div>
        <h3>Jac's on Bond</h3>

        <p class="preview_body">Chic Townhouse Cocktail Bar</p>
        <div class="preview_info">
          <a class="location"> 26 Bond St, NY 10012 <span class="neighborhood">NoHo</span></a>

        </div>
      </div>

      {/* <!-- NUBELUZ --> */}
      <div class="preview1">

        <div class="preview_img">
          <div class="preview_img_hover">
            <img style={{filter: 'contrast(1.5) saturate(1.5)', width: '75%', height: '75%'}} src="https://static.vecteezy.com/system/resources/previews/013/219/734/original/gold-metallic-wine-glass-png.png"/>
          </div>
          <img class="preview_img" alt="" src="https://media.admagazine.fr/photos/63b596f5b06b609a7e194a54/3:2/pass/Nubeluz%2520_%2520CREDIT%2520Bjo%25CC%2588rn%2520Wallander.jpeg"/>
        </div>
        <h3>Nubeluz</h3>

        <p class="preview_body">Modern cocktail bar with skyline views.</p>
        <div class="preview_info">
          <a class="location">25 W 28th St, NY 10001 <span class="neighborhood">West Village</span></a>

        </div>
      </div>

    </div>

  </div>

</div>

</div>
    </div>
</StyledDispo>
)
}
 
export default Dispo