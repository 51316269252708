import React from 'react'
import styled from 'styled-components'
import TestEB from './TestEB';
import Test123 from './Test123';
import TestAbout from './TestAbout';
import Invite from './Invite';
 
const StyledCampsPage = styled.div`
 
`;
 
const CampsPage = () => {
    window.scrollTo({
                      top: 0,
                      left: 100,
                      behavior: 'smooth'
                    })
    return (
        <StyledCampsPage>
  
   <TestEB />
   <Test123 displayStatus="none"  />
   <TestAbout marginStatus="1vh" />
   <Invite />
   <Test123 displayStatus="block" /> 
        </StyledCampsPage>
    )
}
 
export default CampsPage
 