import React from 'react'
import styled from 'styled-components'
 
const StyledFinalEnter = styled.div`
   
  
    .use-enter {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0;
      position: fixed;
      height: 120vh;
      width: 100vw;
      background-color: #111;
      color: #eee;
      justify-content: center;
      align-items: center;
      z-index: 999999999999999999999999999;

    
    }
`;
 
const FinalEnter = () => {
    return (
        <StyledFinalEnter>
               <div className="use-enter">
        <h2 style={{fontSize:'2rem'}}>Success! You have Entered</h2>
        <p>good luck. Prizes will be annouced later on</p>
      </div>
        </StyledFinalEnter>
    )
}
 
export default FinalEnter
 