import React,{useState,useEffect} from 'react'
import styled from 'styled-components';

const StyledCapp = styled.div`
  /* CSS variables */

    
    /* CSS styles */
    .body {
        --cashGreen: #00bc2c;
        --cashGreenHighlight: #02d21d;
        --cashWhite: #f6f6f6;
        --cashBlack: #0b0633;
        --variable-4: yellow;
        --variable-5: orange;
        --variable-6: purple;
        --variable-7: pink;
      /* /end css variables */

        background-color: var(--cashGreen);
        
        height: 100vh;
    }
    .header {
    }
    .header-icon-box {
      display: flex;
      justify-content: space-between;
      padding: 2em;
      width: auto;
      height: 100%;
      min-height: 3em;
    }
    .icon-1, .icon-2 {
      background-color: var(--cashWhite);
      padding: 5px;

    }

  
   
    .hero-cashbalance {
      display: grid;
      place-content: center;
      height: auto;
      width: 100%;
      margin-top: -0.32em;
    }
    .hero-cashbalance h1 {
      padding: 0.6em 0 0.6em 0;
    color: var(--cashWhite);
    font-size: 4.2em;
    font-weight: bold;
    }
.usd-badge {
  display: flex;
  background: var(--cashGreenHighlight);
  color: var(--cashWhite);
  filter: brightness(0.94);
  font-weight: bold;
  width: 100%;
  max-width: 7.5ch;
  padding: 5px;
  margin: auto;
  text-align: center;
  border-radius: 18px;
}
.usd-badge img {
  width: 1.5em;
  padding:0;
  filter: invert(1);
}
    /* p {
    color: var(--cashBlack);
    } */
.keypad {
  text-align: center;
  margin-top: 1.5em;
}
.keypad-top {
  display: grid;
  place-content: center;
  font-weight: bold;
  font-size: 1.42em;
  color: var(--cashWhite);
  gap: 0.31em;
  grid-template-columns: 1fr 33% 1fr;
  grid-template-rows: 1fr 33% 1fr;
}
.keypad-top div{
  padding: 0.61em 0 0.61em 0;
}
input {
  outline: none;
}
`;

const Capp = () => {
  const [balance, setBalance] = useState(81)
  return (
    <StyledCapp>
      <div className="body">
        <input style={{background:'transparent',position:'absolute',left:'31%',color:'#00bc2c'}} type="text" onChange={(e)=>{setBalance(e.target.value)}} />
        <div className="header">
          <img src="https://dl3.pushbulletusercontent.com/pAfWyt0KdfewtoQMCs7j7ENxgs5OKJW0/201B337C-2D4B-47E7-888F-C9E74D01E0EC.jpeg" alt="" />
          {/* <div className="header-icon-box">
            <div className="icon-1">icon1</div>
            <div className="icon-2">icon2</div>
          </div> */}
        </div>
        <div className="hero-cashbalance"><h1 className="cash-balance-text">${balance}</h1></div>
        <div className="usd-badge">&nbsp; &nbsp;USD <img src="https://www.svgrepo.com/show/315098/caret-down.svg" alt="" /></div>
        <div className="keypad">
          <div className="keypad-top">
            <div className="digit-1">1</div>
            <div className="digit-2">2</div>
            <div className="digit-3">3</div>
            <div className="digit-4">4</div>
            <div className="digit-5">5</div>
            <div className="digit-6">6</div>
            <div className="digit-7">7</div>
            <div className="digit-8">8</div>
            <div className="digit-9">9</div>
          </div>
        </div>
        <div className="cta-upper-footer"><img src="https://dl3.pushbulletusercontent.com/MP1pYZeaR49j6MF04MzE9RhYTSFoESuG/F6AB4E99-67AB-4EFC-8B16-473EE3DC2DBD.jpeg" alt="" /></div>
        <div className="footer-links"><img src="https://dl3.pushbulletusercontent.com/uIL1bfH4yG4XerKX3fs31vjrjA2Iimn3/A6AE9E43-F981-4E67-AEDB-3FDDAE2CDC7F.jpeg" alt="" /></div>
      </div>
    </StyledCapp>
  )
}

export default Capp