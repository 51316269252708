import React, {useState} from 'react';
import { useForm, ValidationError } from '@formspree/react';

import styled from 'styled-components'
 
const StyledFormSpree = styled.div`
position: relative;

form {
        background-color: transparent;
        display: flex;
        flex-direction: column;
        position: relative;
}
`;
 
const FormSpree = ({formID}) => {
    
    const [state, handleSubmit] = useForm(`${formID}`);
if (state.succeeded) {
return <p>Thanks for joining!</p>;
}

  return (
          
               <StyledFormSpree>
      <form onSubmit={handleSubmit}>
      {/* <label htmlFor="name">
        Name Address
      </label>
      <input
        id="name"
        type="name" 
        name="name"
      />
      <ValidationError 
        prefix="Name" 
        field="name"
        errors={state.errors}
      />
      <br />
      <textarea
        id="message"
        name="message"
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      /> */}

<div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
  <div className="mx-auto max-w-lg text-center">
    <h1 className="text-2xl font-bold sm:text-3xl text-gray-200">Keep up to date!</h1>

    <p className="mt-4 text-gray-500">
     Get more info!
    </p>
  </div>

    <div>
      <label for="name" className="sr-only">Name</label>

      <div className="relative">
        <input
          style={{backgroundImage:'none'}}
          type="text"
          name='name'
          className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
          placeholder="Enter name"
        />
      <ValidationError 
        prefix="name" 
        field="name"
        errors={state.errors}
      />

    
      </div>
    </div>
<br />
    <div>
      <label for="message" className="sr-only">Message</label>

      <div className="relative">
        <input
          name="message"
          type="text"
          className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
          placeholder="Enter Message"
        />
      <ValidationError 
        prefix="message" 
        field="message"
        errors={state.errors}
      />

      
      </div>
    </div>
<br />
    <div>
      <label for="email" className="sr-only">email</label>

      <div className="relative">
        <input
          name="email"
          type="email"
          className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
          placeholder="Enter email"
        />
      <ValidationError 
        prefix="email" 
        field="email"
        errors={state.errors}
      />

      
      </div>
    </div>

    <div className="flex items-center justify-between">
      <p className="text-sm text-gray-500">
        No account?
        <a className="underline" href="">Sign up</a>
      </p>

    </div>

          <button type="submit" disabled={state.submitting}  className="inline-block rounded-lg bg-blue-500 px-5 py-3 text-sm font-medium text-white">
        Submit
      </button>
</div>



    </form>
        </StyledFormSpree>
  );
}

 
export default FormSpree
 