import React from 'react'
import styled from 'styled-components'
import { useForm, ValidationError } from '@formspree/react';
import { Link } from 'react-router-dom';

 
const StyledEnter = styled.div`

position: relative;

form {
        background-color: transparent;
        display: flex;
        flex-direction: column;
        position: relative;
}

`;
 
const Enter = () => {
  window.scrollTo({
    top: 0,
    left: 100,
    behavior: 'smooth'
  })
    const [state, handleSubmit] = useForm('xqkvzknw');
    if (state.succeeded) {
    return <p style={{color:'#fff'}}>Thanks for joining!</p>;
    }
    
    return (
        <StyledEnter>
                  
      <form onSubmit={handleSubmit}>
      {/* <label htmlFor="name">
        Name Address
      </label>
      <input
        id="name"
        type="name" 
        name="name"
      />
      <ValidationError 
        prefix="Name" 
        field="name"
        errors={state.errors}
      />
      <br />
      <textarea
        id="message"
        name="message"
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      /> */}

<div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
  <div className="mx-auto max-w-lg text-center">
    <h1 className="text-2xl font-bold sm:text-3xl text-gray-200">Enter to Win!</h1>

    <p className="mt-4 text-gray-500">
    Enter your name, email and phone number!
    </p>
  </div>

    <div>
      <label for="name" className="sr-only">Name</label>

      <div className="relative">
        <input
          style={{backgroundImage:'none'}}
          type="text"
          name='name'
          className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
          placeholder="Enter name"
        />
      <ValidationError 
        prefix="name" 
        field="name"
        errors={state.errors}
      />

    
      </div>
    </div>
<br />
    <div>
      <label for="phonenumber" className="sr-only">Phone Number</label>

      <div className="relative">
        <input
          name="phonenumber"
          type="text"
          className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
          placeholder="Enter Phone Number"
        />
      <ValidationError 
        prefix="Phone number" 
        field="Phone number"
        errors={state.errors}
      />

      
      </div>
    </div>
<br />
    <div>
      <label for="email" className="sr-only">email</label>

      <div className="relative">
        <input
          name="email"
          type="email"
          className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
          placeholder="Enter email"
        />
      <ValidationError 
        prefix="email" 
        field="email"
        errors={state.errors}
      />

      
      </div>
    </div>

    <div className="flex items-center justify-between">
      <p className="text-sm text-gray-500 sr-only">
        No account?
        <a className="underline" href="">Sign up</a>
      </p>

    </div>

          <button type="submit" disabled={state.submitting}  className="inline-block rounded-lg bg-blue-500 px-5 py-3 text-sm font-medium text-white">
    
        Submit
        
      </button>
</div>



    </form>
     
            {/* <FormSpree formID="xqkvzknw" /> */}
        </StyledEnter>
    )
}
 
export default Enter
 