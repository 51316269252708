import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledVidHero = styled.div`


  background-color: #1c1b1b;
  transition: 0.5;
  max-height: 5000px;
  overflow-x: hidden;

html h3, body h3 {
  font-weight: 600;
}
html p, body p {
  font-size: 18px;
}
html .titlehr, body .titlehr {
  border: solid 3px #2f2725;
  width: 35px;
  margin-bottom: 10px;
}
html .linehr, body .linehr {
  border: solid 1px #fff;
  margin: 10px 20px 10px 10px;
}
html .traininghr, body .traininghr {
  border: solid 1px #2f2725;
  margin-top: 10px;
  margin-bottom: 20px;
}
html .titlehrorange, body .titlehrorange {
  border: solid 3px #ea5514;
  width: 35px;
  margin-bottom: 10px;
}

nav.navbar.fixed-top.navbar-expand-lg.navbar-light.bg-light img {
  width: 160px;
}
nav.navbar.fixed-top.navbar-expand-lg.navbar-light.bg-light ul.navbar-nav {
  font-size: 18px;
  letter-spacing: 1px;
}
nav.navbar.fixed-top.navbar-expand-lg.navbar-light.bg-light ul.navbar-nav li.nav-item .nav-link {
  color: #2f2725;
  padding: 10px 0px 10px 60px;
}

header#header_banner {
  background-image: url(https://i.imgur.com/u6412vc.png);
  filter: hue-rotate(-16deg) contrast(1) saturate(0.92) brightness(1.08);
  background-size: cover;
  background-position: center center;
  margin-top: 50px;
  padding-top: 55%;
  min-width: 400px;
}

section#section_about {
  background-color: #dcdddd;
}
section#section_about .container .col-md-12.top {
  padding: 180px 30px 0px 30px;
}
section#section_about .container .col-md-7 {
  padding: 20px 30px 30px 30px;
}
section#section_about .container .col-md-12.companys {
  padding: 50px 30px 30px 30px;
}
section#section_about .container .col-md-3.sgs {
  padding: 0px 40px 200px 40px;
}
section#section_about .container .col-md-3.sgs img {
  width: 100%;
}
section#section_about .container h5 {
  margin-top: 30px;
  color: #ea5514;
  font-weight: 600;
}
section#section_about .container p {
  margin-top: 20px;
  line-height: 30px;
}
section#section_about .container .companypic {
  padding: 60px 30px 30px 30px;
}
section#section_about .container .companypic img {
  width: 100%;
  border-radius: 4%;
}

section#section_news {
  background-color: #fff;
}
section#section_news .container .col-md-12.top {
  padding: 180px 30px 0px 30px;
}
section#section_news .container .newsend {
  padding: 100px;
}
section#section_news .container .slider {
  width: 80%;
  margin: 50px auto;
}
section#section_news .container .slick-prev:before, section#section_news .container .slick-next:before {
  color: #2f2725;
  font-size: 20px;
}
section#section_news .container img {
  width: 100%;
  border-radius: 2%;
}
section#section_news .container h4.title {
  margin: 20px 0px 0px 20px;
  color: #ea5514;
}
section#section_news .container p.text {
  margin: 10px 0px 0px 20px;
  color: #2f2725;
}
section#section_news .container a {
  text-decoration: none;
}

section#section_services {
  background-color: #f39800;
}
section#section_services .container .col-md-12.top {
  padding: 180px 30px 40px 30px;
}
section#section_services .container img.bentopic {
  width: 100%;
  border-radius: 4%;
}
section#section_services .container img.number {
  width: 70px;
}
section#section_services .container h2 {
  margin: -20px 0px 0px 60px;
  font-weight: 800;
  color: #fff;
}
section#section_services .container a {
  text-decoration: none;
}
section#section_services .container .bento {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
section#section_services .container .end {
  padding: 100px;
}

section#section_bannercanter .container-fluid.orangebanner {
  background-image: url(https://i.imgur.com/5cFyNPY.png);
  background-size: cover;
  background-position: center center;
  padding-top: 38%;
  min-width: 400px;
}

section#section_plan {
  background-color: #dcdddd;
}
section#section_plan .container .col-md-12.top {
  padding: 180px 30px 40px 30px;
}
section#section_plan .container .col-md-12.plans {
  padding: 0px 30px 30px 30px;
}
section#section_plan .container .col-md-12.top1 {
  padding: 0px 30px 0px 30px;
}
section#section_plan .container .col-md-4 {
  padding: 0px 30px 0px 30px;
}
section#section_plan .container h5 {
  margin-top: 30px;
  color: #ea5514;
  font-weight: 600;
}
section#section_plan .container h6 {
  border: solid 1px #2f2725;
  padding: 10px;
  text-align: center;
}
section#section_plan .container p {
  line-height: 28px;
}
section#section_plan .container .tt {
  padding-top: 40px;
  font-weight: 600;
}
section#section_plan .container .process {
  padding: 50px 0px 20px 0px;
}
section#section_plan .container .processicon {
  width: 100%;
  padding: 0px 60px 20px 60px;
}
section#section_plan .container .processpic {
  width: 100%;
  border-radius: 4%;
}
section#section_plan .container .spacing {
  padding: 50px;
}
section#section_plan .container .trainingspacing {
  padding: 70px 40px 150px 40px;
}
section#section_plan .container .training {
  width: 100%;
  border-radius: 4%;
}

section#section_equipment {
  background-color: #ea5514;
}
section#section_equipment .container .col-md-12.top {
  padding: 180px 30px 40px 30px;
}
section#section_equipment .container .main {
  padding-top: 50px;
}
section#section_equipment .container img {
  width: 100%;
  border-radius: 4%;
}
section#section_equipment .container h5.title {
  padding: 50px 10px 10px 10px;
  font-weight: 600;
  color: #fff;
}
section#section_equipment .container p.text {
  padding: 10px;
  color: #fff;
}
section#section_equipment .container hr.line {
  color: #fff;
  width: 98%;
}
section#section_equipment .container .equipment {
  padding: 10px;
}
section#section_equipment .container .end {
  padding-bottom: 100px;
}

section#section_contact {
  background-color: #2f2725;
}
section#section_contact .container .col-md-12.top {
  padding: 150px 30px 40px 30px;
}
section#section_contact .container .col-md-4 {
  padding: 0px 30px 0px 30px;
}
section#section_contact .container h3 {
  color: #ea5514;
}
section#section_contact .container img.fb {
  margin-top: 20px;
  padding-bottom: 20px;
  width: 80px;
  margin-right: 30px;
}
section#section_contact .container img.logo {
  width: 170px;
  margin-bottom: 20px;
}
section#section_contact .container .bighr p {
  font-size: 25px;
}
section#section_contact .container p {
  color: #fff;
}
section#section_contact .container .footer {
  padding: 0px 0px 150px 0px;
}

@media screen and (max-width: 600px) {
  header#header_banner {
    margin-top: 80px;
  }

  section#section_about .container .col-md-12.top {
    padding-top: 100px;
  }
  section#section_about .container .col-md-7 {
    padding: 20px 30px 0px 30px;
  }
  section#section_about .container .col-md-12.companys {
    padding: 20px 30px 10px 30px;
  }
  section#section_about .container .col-md-12.sgsend {
    padding-bottom: 80px;
  }
  section#section_about .container .col-md-3.sgs {
    padding: 10px 40px 30px 40px;
    display: flex;
    justify-content: center;
  }
  section#section_about .container .col-md-3.sgs img {
    width: 60%;
  }
  section#section_about .container .companypic {
    padding: 60px 30px 30px 30px;
    display: flex;
    justify-content: center;
  }
  section#section_about .container .companypic img {
    width: 95%;
    border-radius: 4%;
  }

  section#section_news .container .col-md-12.top {
    padding-top: 100px;
  }
  section#section_news .container .newsend {
    padding: 50px;
  }

  section#section_services .container .col-md-12.top {
    padding-top: 100px;
  }
  section#section_services .container .row.canterflex {
    padding: 0px 50px;
  }
  section#section_services .container img.bentopic {
    width: 100%;
    border-radius: 4%;
  }
  section#section_services .container img.number {
    width: 70px;
  }
  section#section_services .container h2 {
    margin: -10px 0px 0px 60px;
    font-weight: 800;
    color: #fff;
  }
  section#section_services .container a {
    text-decoration: none;
  }
  section#section_services .container .bento {
    padding-top: 10px;
    padding-bottom: 70px;
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
  section#section_services .container .end {
    padding: 30px;
  }

  section#section_plan {
    background-color: #dcdddd;
  }
  section#section_plan .container .col-md-12.top {
    padding: 100px 30px 30px 30px;
  }
  section#section_plan .container .col-md-12.plans {
    padding: 0px 30px 10px 30px;
  }
  section#section_plan .container .col-md-12.top1 {
    padding: 0px 30px 0px 30px;
  }
  section#section_plan .container .col-md-4 {
    padding: 0px 20px 60px 20px;
  }
  section#section_plan .container .process {
    padding: 50px 0px 0px 0px;
  }
  section#section_plan .container .processicon {
    width: 100%;
    padding: 0px 70px 20px 70px;
  }
  section#section_plan .container .processpic {
    width: 100%;
    border-radius: 4%;
  }
  section#section_plan .container .col-md-3 {
    padding: 20px;
  }
  section#section_plan .container .col-md-5 {
    padding: 15px;
  }
  section#section_plan .container .spacing {
    padding: 0px 60px 30px 60px;
  }

  section#section_equipment .container .col-md-12.top {
    padding-top: 100px;
  }
  section#section_equipment .container .main {
    padding-top: 0px;
  }
  section#section_equipment .container h5.title {
    padding: 30px 10px 10px 10px;
  }
  section#section_equipment .container .equipment {
    padding: 30px;
  }
  section#section_equipment .container .end {
    padding-bottom: 50px;
  }

  section#section_contact {
    background-color: #2f2725;
  }
  section#section_contact .container .col-md-12.top {
    padding: 100px 30px 40px 30px;
  }
  section#section_contact .container .col-md-4 {
    padding: 0px 30px 0px 30px;
  }
  section#section_contact .container img.logo {
    width: 170px;
    margin-bottom: 20px;
  }
  section#section_contact .container .bighr p {
    font-size: 25px;
  }
  section#section_contact .container p {
    color: #fff;
  }
  section#section_contact .container .footer {
    padding: 0px 0px 100px 0px;
  }
}
`;

const VidHero = () => {
  return (
    <StyledVidHero>
        
<div class="footer"></div>

{/* <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
  <div class="container"><a class="navbar-brand" href="#header_banner"><img src="https://i.imgur.com/6nucONd.png" alt=""/></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item"><a class="nav-link" href="#section_about">About</a></li>
        <li class="nav-item"><a class="nav-link" href="#section_news">News</a></li>
        <li class="nav-item"><a class="nav-link" href="#section_services">Services</a></li>
        <li class="nav-item"><a class="nav-link" href="#section_plan">Plan</a></li>
        <li class="nav-item"><a class="nav-link" href="#section_equipment">Equipment</a></li>
        <li class="nav-item"><a class="nav-link" href="#section_contact">Contact us </a></li>
        </ul>
        </div>
        </div>
    </nav> */}
    <h3 style={{color:'#efefef'}}><img src="https://www.morettiswineclub.com/images/logo.jpg" alt=""/></h3>
    <div className="button-container" style={{gap:'0.5em',padding:'0.82em',background:'red',display:'flex',justifyContent:'center',alignItems:'center'}}>
     <Link to="/support">
      <button style={{background:'goldenrod',padding:'1em 2.4em',color:'white',borderRadius:'1em'}}>Contact Support</button>
      </Link>
     <Link to="/portal">
      <button style={{background:'goldenrod',padding:'1em 2.4em',color:'white',borderRadius:'1em'}}>Login</button>
     </Link> 
    </div>
    <h2 style={{color: 'white',paddingLeft:'1em'}}>Morettis Management Portal </h2>
<header id="header_banner">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12"></div>
    </div>
  </div>
</header>
<section id="section_about">
  <div class="container">
    <div class="row">
      <div class="col-md-12 top">
        <div class="titlehr"></div>
        <h3>TitleH3</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7">
        <h5>IManh5 h5</h5>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, placeat atque expedita consequatur nulla itaque quaerat laudantium. Quis corrupti quas ipsa in! Totam inventore dicta voluptates nemo soluta laboriosam nihil?</p>
      </div>
      <div class="col-md-5 companypic"><img src="https://i.imgur.com/f2RSGXX.png" alt=""/></div>
    </div>
    <div class="row">
      <div class="col-md-12 companys">
        <h5>IMAh5 h5</h5>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ratione exercitationem magnam vel? Dolor deleniti recusandae perspiciatis doloremque, adipisci ullam fuga eligendi aperiam. Recusandae modi cumque asperiores corporis possimus praesentium voluptatibus eligendi, quibusdam id esse. Amet maiores exercitationem ratione aliquid atque doloribus aliquam quasi, earum temporibus recusandae distinctio odio accusantium. Quasi.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 companys">
        <h5>專業證照</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 sgs haccp"><a href="https://codepen.io/HsiaoChan/full/KKmJLpZ" target="_blank"><img src="https://i.imgur.com/VV5VuJW.png" alt=""/></a></div>
      <div class="col-md-3 sgs sgshaccp"><a href="https://codepen.io/HsiaoChan/full/KKmJLpZ" target="_blank"><img src="https://i.imgur.com/Bzil8HJ.png" alt=""/></a></div>
      <div class="col-md-3 sgs sgsiso220000"><a href="https://codepen.io/HsiaoChan/full/KKmJLpZ" target="_blank"><img src="https://i.imgur.com/vmgbVuT.png" alt=""/></a></div>
      <div class="col-md-3"></div>
    </div>
    <div class="row">
      <div class="col-md-12 sgsend">     </div>
    </div>
  </div>
</section>
<section id="section_news">
  <div class="container">
    <div class="row">
      <div class="col-md-12 top">
        <div class="titlehr"></div>
        <h3>活動訊息</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <section class="variable slider">
          <div class="news1"><a href="#"><img src="https://i.imgur.com/p05Lpi0.png"/>
              <h4 class="title">最新消息&新聞報導-感謝第一線醫護人員</h4>
              <p class="text">2020年全球新冠肺炎蔓延，造成國內外極大的恐慌。一品饌總經理彭聖評表示，曾看到新聞報導醫護人員訂便當遭拒，對此感到不捨。</p></a></div>
          <div class="news2"><a href="#"><img src="https://i.imgur.com/HmPTn2r.png"/>
              <h4 class="title">最新消息&新聞報導-感謝第一線醫護人員</h4>
              <p class="text">2021今年國內5月武漢肺炎（COVID-19，2019冠狀病毒疾病）疫情嚴峻，感謝第一線醫護人員非常辛苦，每天為了照護確診患者，幾乎忙得連吃飯時間都沒有，等到有空檔時餐飲業者可能已經打烊，訂不到便當，令人不捨。           </p></a></div>
          <div class="news3"><a href="#"><img src="https://i.imgur.com/owuJFb9.png"/>
              <h4 class="title">最新消息&新聞報導-愛心防疫餐盒</h4>
              <p class="text">本公司總經理彭聖評表示，愛心餐盒以半買半愛心相送，6月1日起共13天，配送至衛福部苗栗醫院、大千、弘大、協和、重光、為恭、光田等7間醫院以及苗栗縣政府衛生局。總共送出破萬愛心防疫餐盒。</p></a></div>
          <div class="news4"><a href="#"><img src="https://i.imgur.com/C7tQhkV.png"/>
              <h4 class="title">最新消息&新聞報導-五行健康食蔬</h4>
              <p class="text">本公司營養師以「五行健康食蔬」為設計主軸，五色時蔬具豐富：花青素、葉黃素、茄紅素、豐富的維生素C，能增加身體抗氧化及營養。</p></a></div>
          <div class="news5"><a href="#"><img src="https://i.imgur.com/4rbIZH0.png"/>
              <h4 class="title">最新消息&新聞報導-每日多樣化菜色</h4>
              <p class="text">每日變化搭配富含優質蛋白質的肉類主菜，同時搭配堅果入菜，提供豐富礦物質及不飽和脂肪酸，增加體能並紓緩醫護人員的壓力。</p></a></div>
        </section>
      </div>
    </div>
    <div class="row newsend">
      <div class="col-md-12">             </div>
    </div>
  </div>
</section>
<section id="section_services">
  <div class="container">
    <div class="row">
      <div class="col-md-12 top">
        <div class="titlehr"></div>
        <h3>服務項目</h3>
      </div>
    </div>
    <div class="row canterflex">
      <div class="col-md-2"></div>
      <div class="col-md-3"><a href="https://codepen.io/HsiaoChan/full/NWjoJRe" target="_blank"><img class="bentopic" src="https://i.imgur.com/ts2OlR4.png"/></a></div>
      <div class="col-md-4 bento"><a href="#"><img class="number" src="https://i.imgur.com/pNNpPOx.png"/>
          <h2>大型團膳  </h2></a></div>
      <div class="col-md-3"></div>
    </div>
    <div class="row canterflex">
      <div class="col-md-4"></div>
      <div class="col-md-3"><a href="https://codepen.io/HsiaoChan/full/NWjoJRe" target="_blank"><img class="bentopic" src="https://i.imgur.com/PhAzggX.png"/></a></div>
      <div class="col-md-3 bento"><a href="#"><img class="number" src="https://i.imgur.com/6TSmyHR.png"/>
          <h2>央廚代膳</h2></a></div>
      <div class="col-md-2">  </div>
    </div>
    <div class="row canterflex">
      <div class="col-md-2"></div>
      <div class="col-md-3"><a href="https://codepen.io/HsiaoChan/full/NWjoJRe" target="_blank"><img class="bentopic" src="https://i.imgur.com/a2bGczj.png"/></a></div>
      <div class="col-md-4 bento"><a href="#"><img class="number" src="https://i.imgur.com/EYQlYUF.png"/>
          <h2>台式/異國便當</h2></a></div>
      <div class="col-md-3"></div>
    </div>
    <div class="row canterflex">
      <div class="col-md-4"></div>
      <div class="col-md-3"><a href="https://codepen.io/HsiaoChan/full/NWjoJRe" target="_blank"><img class="bentopic" src="https://i.imgur.com/djWZdW6.png"/></a></div>
      <div class="col-md-3 bento"><a href="#"><img class="number" src="https://i.imgur.com/r4P4vUR.png"/>
          <h2>客製便當</h2></a></div>
      <div class="col-md-2"></div>
    </div>
    <div class="row canterflex">
      <div class="col-md-2"></div>
      <div class="col-md-3"><a href="https://codepen.io/HsiaoChan/full/NWjoJRe" target="_blank"><img class="bentopic" src="https://i.imgur.com/VwTfEh1.png"/></a></div>
      <div class="col-md-4 bento"><a href="#"><img class="number" src="https://i.imgur.com/iwhLVDN.png"/>
          <h2>大型活動便當</h2></a></div>
      <div class="col-md-3"></div>
    </div>
    <div class="row canterflex">
      <div class="col-md-12 end"></div>
    </div>
  </div>
</section>
<section id="section_bannercanter">
  <div class="container-fluid orangebanner"></div>
</section>
<section id="section_plan">
  <div class="container">
    <div class="row">
      <div class="col-md-12 top">
        <div class="titlehr"></div>
        <h3>專業規劃</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 plans">
        <h5>品質把關</h5>
        <p>本廠重視食品衛生安全設立「一品饌食安檢驗室」食安檢驗室：一品饌依據「食品安全衛生管理法」建置食品安全衛生之三級品管機制，第一級品管由一品饌食品技師及衛生管理人員自行進行自主 管理，訂定食品安全監測計畫書、最低檢驗週期規劃表。獨立的檢驗室，設備包含：滅菌釜、微生物培養恆溫烘箱、紫外線無菌操作台、油品總極性化合物分析儀、均質機、電子秤..等。一品饌定期委外做食材、成品、半成品的衛生檢驗。並送驗單位評估效價選定由TAF(全國驗證單位)驗校之實驗室如：SGS檢驗室、新竹食研所為優先送驗實驗室。  </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 plans">
        <h5 class="process">供膳流程</h5>
      </div>
    </div>
    <div class="row spacing">
      <div class="col-md-3"><img class="processicon" src="https://i.imgur.com/oLBSHtH.png"/>
        <h6>菜單開立</h6>
      </div>
      <div class="col-md-5">
        <p>由專業營養師依照各季節開立營養均衡、當季食材之美味餐點。採購：評核每家原物料供應商須有食品業者登陸字號，並由採購人員下單進行各項食材原物料的訂購選用具有優良標章。</p>
      </div>
      <div class="col-md-4"><img class="processpic" src="https://i.imgur.com/XYBC9Zj.png"/></div>
    </div>
    <div class="row spacing">
      <div class="col-md-3"><img class="processicon" src="https://i.imgur.com/g9AunzX.png"/>
        <h6>驗收及入庫</h6>
      </div>
      <div class="col-md-5">
        <p>專門驗收人人員依照訂貨單跟送貨供應商進行品項對點，每批皆測量冷凍、冷藏食材的中心溫度，確認數量、規格、品質、重量符合要求才簽單入庫收貨。</p>
      </div>
      <div class="col-md-4"><img class="processpic" src="https://i.imgur.com/0qPp482.png"/></div>
    </div>
    <div class="row spacing">
      <div class="col-md-3"><img class="processicon" src="https://i.imgur.com/dL9Kh2c.png"/>
        <h6>領料及前處理</h6>
      </div>
      <div class="col-md-5">
        <p>發料人員依照每日生產單上各食材的數量來發料，各項食材於前處理區進行作業避免交叉污染。</p>
      </div>
      <div class="col-md-4" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1"> <img class="processpic" src="https://i.imgur.com/PUzBAIJ.png"/></div>
    </div>
    <div class="row spacing">
      <div class="col-md-3"><img class="processicon" src="https://i.imgur.com/ULPr9BZ.png"/>
        <h6>烹調</h6>
      </div>
      <div class="col-md-5">
        <p>各項前處理完成的食材經由食材投遞口投入，交由台籍及菲籍廚師運用不同料理手法烹調出營養、美味的菜餚。</p>
      </div>
      <div class="col-md-4"><img class="processpic" src="https://i.imgur.com/oSPZSuJ.png"/></div>
    </div>
    <div class="row spacing">
      <div class="col-md-3"><img class="processicon" src="https://i.imgur.com/nvX1qv6.png"/>
        <h6>配膳包裝</h6>
      </div>
      <div class="col-md-5">
        <p>由熟食投遞口將烹煮好之食材投入減少交叉污染，盛裝盆菜的餐桶採用雙層不銹鋼保溫餐桶能保持餐食的溫度。作業人員依照各客戶需求迅速配膳、對點、秤重、密封，減少交叉污染。</p>
      </div>
      <div class="col-md-4"><img class="processpic" src="https://i.imgur.com/sF7Okyh.png"/></div>
    </div>
    <div class="row spacing">
      <div class="col-md-3"><img class="processicon" src="https://i.imgur.com/OCpB1Ue.png"/>
        <h6>出貨配送 </h6>
      </div>
      <div class="col-md-5">
        <p>餐食確認完畢後由專門人員進行湯桶包覆(避免溢出)，經由單一出貨碼頭將餐食運送至公司專用箱型送餐餐車。本廠設有專用箱型送餐餐車，依照里程數定期保養。各司機人員做良好服務。</p>
      </div>
      <div class="col-md-4"><img class="processpic" src="https://i.imgur.com/H9wszX3.png"/></div>
    </div>
    <div class="row spacing">
      <div class="col-md-3"><img class="processicon" src="https://i.imgur.com/8a2UVGh.png"/>
        <h6>回廠後餐車清洗消毒</h6>
      </div>
      <div class="col-md-5">
        <p>司機人員返廠後由後門碼頭將餐桶卸下，依照清洗作業流程程序來清洗、消毒餐車；完成後每日由衛生管理人員確認餐車之清潔與衛生。</p>
      </div>
      <div class="col-md-4"><img class="processpic" src="https://i.imgur.com/4Q1nYeQ.png"/></div>
    </div>
    <div class="row">
      <div class="col-md-12 top1">
        <h5>教育訓練</h5>
      </div>
    </div>
    <div class="row trainingspacing">
      <div class="col-md-4"><img class="training" src="https://i.imgur.com/6hF36m6.png"/>
        <p class="tt">定期舉辦</p>
        <div class="traininghr"></div>
        <p>我們定期舉辦教育訓練，包括廠內同仁與廠外同仁。</p>
      </div>
      <div class="col-md-4"><img class="training" src="https://i.imgur.com/RMbO8zQ.png"/>
        <p class="tt">廠內教育訓練</p>
        <div class="traininghr"></div>
        <p>新進人員將於訓練後才可在現場作業，內部定期實施教育訓練由廠長、食品技師、衛生管理人員進行相關課程的規劃及授課，保持員工良好的衛生作業習慣。</p>
      </div>
      <div class="col-md-4"><img class="training" src="https://i.imgur.com/q3kV1T7.png"/>
        <p class="tt">廠外教育訓練</p>
        <div class="traininghr"></div>
        <p>包含每年8小時衛生講習、管制小組定期回訓、營養師及食品技師在職教育訓練、各項食品法規的認知更新，以配合最新政府法令需求。 </p>
      </div>
    </div>
  </div>
</section>
<section id="section_equipment">
  <div class="container">
    <div class="row">
      <div class="col-md-12 top">
        <div class="titlehr"></div>
        <h3>專業設備</h3>
      </div>
    </div>
    <div class="row main">
      <div class="col-md-3">
        <div class="equipment"><img src="https://i.imgur.com/IWvDQcC.png"/>
          <h5 class="title">實驗室</h5>
          <div class="linehr"></div>
          <p class="text">獨立檢驗室，設備包含：滅菌釜、微生物培養恆溫烘箱 、 紫外線無菌操作台、油品總極性化合物分析儀、均質機、電子秤....等。一品饌定期委外做食材、成品、半成品的食品相關檢驗。</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="equipment"><img src="https://i.imgur.com/92Lg2Hh.png"/>
          <h5 class="title">浴塵室</h5>
          <div class="linehr"></div>
          <p class="text">人員從更衣區進入工作區域必須通過空氣浴塵室15秒，利用高速強力的潔淨空氣，有效而迅速清除去附著在衣著上的灰塵、頭髮、毛屑等雜物，大幅減少人員進出所帶來的污染源。            </p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="equipment"><img src="https://i.imgur.com/UMT0GMJ.png"/>
          <h5 class="title">紫外線消毒箱</h5>
          <div class="linehr"></div>
          <p class="text">每日定時開啟，溫度設定110 ℃，時間40分鐘。烘乾消毒後，紫外線燈管開啟滅菌。確保砧板及刀具的清潔度。</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="equipment"><img src="https://i.imgur.com/uzjdp1F.png"/>
          <h5 class="title">三段自動洗碗機</h5>
          <div class="linehr"></div>
          <p class="text">本公司每日作業完畢立刻清洗，並且保持乾淨。三段自動連續性洗碗機、大型全自動高溫洗滌機，可使湯桶、器具充分洗淨。</p>
        </div>
      </div>
    </div>
    <div class="row main end">
      <div class="col-md-3">
        <div class="equipment"><img src="https://i.imgur.com/07quEhg.png"/>
          <h5 class="title">乾料區</h5>
          <div class="linehr"></div>
          <p class="text">由專人進行管理、先進先出、離牆離地五公分、依類別分區存放及領用。並設有溫濕度計及除濕機，將溫度控制於30 ℃以下；相對濕度75%以下，隨時注意避免過期、膨包.....等不良品的發生。</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="equipment"><img src="https://i.imgur.com/lOK1zr9.png"/>
          <h5 class="title">獨立消毒烘乾室</h5>
          <div class="linehr"></div>
          <p class="text">接獨立加熱器利用管線將高溫的空氣送入烘乾室，使廚具殺菌烘乾，並開啟紫外線燈管消毒餐具。</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="equipment"><img src="https://i.imgur.com/PylsIdl.png"/>
          <h5 class="title">配膳輸送帶</h5>
          <div class="linehr"></div>
          <p class="text">提升人員包裝速度，減少人員手部直接接觸餐盒降低微生物風險。</p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="equipment"><img src="https://i.imgur.com/K0y8FHS.png"/>
          <h5 class="title">鼓風大炒灶</h5>
          <div class="linehr"></div>
          <p class="text">本公司擁有四座完善的鼓風爐灶設備，能快速製備餐食。          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="section_contact">
  <div class="container">
    <div class="row">
      <div class="col-md-12 top">
        <div class="titlehrorange"></div>
        <h3>聯絡我們</h3>
      </div>
    </div>
    <div class="row footer">
      <div class="col-md-4">
        <div class="bighr">
          <p>客服中心：0800-88559</p>
          <p>服務時間：24h全年無休</p>
        </div><a href="https://www.facebook.com/epinchuan" target="_blank"> <img class="fb" src="https://i.imgur.com/hy2bmxE.png"/></a><img class="fb" src="https://i.imgur.com/xOF3ed8.png"/>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4"><img class="logo" src="https://i.imgur.com/XlBNQma.png"/>
        <p>一品饌國際餐飲股份有限公司</p>
        <p>苗栗縣竹南鎮頂埔里中華路78巷31號</p>
        <p>公司電話：037-681700#208</p>
        <p>公司傳真：037-681760</p>
        <p>電子信箱：meal@epcck.com</p>
      </div>
    </div>
  </div>
</section>
    </StyledVidHero>
  )
}

export default VidHero