import React from 'react'
import styled from 'styled-components'
 
const StyledTestAbout = styled.div`
 /*
Set up and base styles and root level intrinsic typography.
This allows scaling to very small sizes.
https://docs.typetura.com/
*/

:root {
  --tt-key: root;
  --tt-ease: cubic-bezier(0, 0, 0.4, 1);
  --tt-max: 400;

  --max-width: 45rem;

  --font-headline: "Roslindale", serif;
  --font-text: "Sole Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  --font-small: "Roslindale", serif;

  --color: hsl(8deg, 72%, 50%);

  color: hsl(0, 0%, 15%);

  font-size: 100%;
  line-height: 1.5;
  font-family: var(--font-text);
  font-stretch: 100%;
  font-weight: 350;
  font-optical-sizing: auto;
}

@keyframes root {
  0% {
    /* Yes, the font size is 0, but so is the viewport width.
       This scales to a value over 16px rapidly. */
    font-size: 0%;
    /* Unitless line height can be used with this method. */
    line-height: 1.15;
    /* Variable fonts can also be adjusted with this method.
       This gives you way more control over your typography. */
    font-weight: 400;
    font-stretch: 115%;
  }

  100% {
    /* Here we have a value equivilent to 18px at a default base of 16px.
       Note that user base font sizes can be inherited here,
       this improves accessibility over viewport unit based font scaling. */
    font-size: 112.5%;
    line-height: 1.45;
    font-weight: 350;
    font-stretch: 105%;
  }
}


/* Other typographic styles */

.primary-headline {
  --tt-key: primary-headline;
  --tt-ease: cubic-bezier(0.65, 0, 0.5, 1);
  --tt-max: 900;
  font-family: var(--font-headline);
  line-height: 1;
}

@keyframes primary-headline {
  0% {
    line-height: 1.1;
    font-size: 0.75rem;
    letter-spacing: 0.02em;
    font-weight: 700;
  }

  80% {
    font-weight: 550;
  }

  100% {
    line-height: 1.03;
    font-size: 84px;
    letter-spacing: 0.01em;
    font-weight: 500;
  }
}

.big {
  font-size: 1.35em;
  line-height: 1.2;
  font-weight: 400;
  color: #655;
  --tt-key: big;
  --tt-max: 800;
  --tt-ease: ease-in-out;
}
@keyframes big {
  0% {
    font-size: 1rem;
    line-height: 1.3;
    color: #433;
  }
  100% {
    font-size: 1.4rem;
    line-height: 1.35;
    color: #766;
  }
}

.big:after {
  content: "";
  display: block;
  height: 1rem;
  margin: 2rem 0 3rem;
  background: url("https://assets.codepen.io/467/plane.svg"),
    linear-gradient(hsl(0, 0%, 94%), hsl(0, 0%, 94%));
  background-repeat: no-repeat, repeat-x;
  background-size: contain, 2px 2px;
  background-position: center center;
}

.section-label {
  display: table;
  margin: 1rem auto;
  font-family: "Job Clarendon", var(--font-headline);
  letter-spacing: 0.03em;
  grid-column-end: span 3;
  font-weight: 700;
  --tt-max: 1200;
  --tt-key: section-label;
  --tt-ease: linear;
}

@keyframes section-label {
  0% {
    font-size: 1rem;
    font-weight: 500;
  }
  100% {
    font-size: 2rem;
    font-weight: 700;
  }
}

/* Because our root styles scale to the viewport, relative units will inherit that flexibility. */

.label {
  font-family: var(--font-text);
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 450;
  letter-spacing: 0.1em;
  color: var(--color);
  line-height: 1;
  font-stretch: 125%;
}

.byline::before {
  content: "";
  width: 3rem;
  height: 2px;
  background: var(--color);
  position: absolute;
  top: 0;
}

.byline {
  font-family: var(--font-text);
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 350;
  letter-spacing: 0.1em;
  padding-top: 0.7rem;
  position: relative;
  margin: 1rem 0 0;
  line-height: 1;
  font-stretch: 100%;
}

.dateline {
  font-family: var(--font-text);
  text-transform: uppercase;
  font-size: 0.5rem;
  font-weight: 300;
  letter-spacing: 0.1em;
  opacity: 0.67;
  padding-top: 0.7rem;
  position: relative;
  margin: 0 0 2rem;
  line-height: 1;
  font-stretch: 90%;
}

figcaption,
.caption {
  font-family: var(--font-small);
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.25;
  margin-top: 0.3rem;
  padding-bottom: 0.4rem;
  margin-bottom: 0.3rem;
  font-style: italic;
  color: #555;
}

.logo {
  font-size: 2.5rem;
  line-height: 1;
  color: var(--color);
  font-family: "Job Clarendon", var(--font-headline);
  text-transform: uppercase;
  font-weight: 450;
  /* display: none; */
}


/* This technique doesn’t need to be limited to typogrphy. You can also interpolate any animatable element style. */

@media (min-width: 35em) {
  .float-left {
    float: left;
    margin-right: 1rem;
    width: 40%;
    min-width: 300px;
    --tt-key: float-left;
    --tt-max: 1300;
    --tt-ease: ease-out;
  }

  .float-right {
    float: right;
    margin-left: 1rem;
    width: 40%;
    min-width: 300px;
    --tt-key: float-right;
    --tt-max: 1300;
    --tt-ease: ease-out;
  }
}

@keyframes float-left {
  80% {
    margin-left: 0;
  }

  100% {
    margin-left: -8rem;
  }
}

@keyframes float-right {
  80% {
    margin-right: 0;
  }

  100% {
    margin-right: -8rem;
  }
}


/*
Sole Sans by CAST Foundry
license it here: http://solesans.c-a-s-t.com/
*/

@font-face {
  font-family: "Sole Sans";
  src: url("https://assets.codepen.io/467/SoleSansVF_W.woff2")
    format("woff2-variations");
  font-weight: 100 900;
  font-stretch: 60% 125%;
}

/*
Roslindale and Job Clarendon by DJR from the Font of the Month Club
license them here: https://djr.com/font-of-the-month-club
*/

@font-face {
  font-family: "Roslindale";
  src: url("https://assets.codepen.io/467/RoslindaleVariable-VF.woff2")
    format("woff2-variations");
  font-weight: 200 900;
}
@font-face {
  font-family: "Job Clarendon";
  src: url("https://assets.codepen.io/467/JobClarendonVariable-VF.woff2")
    format("woff2-variations");
  font-weight: 100 900;
}


/* Layout styles */

body {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  width: 100%;
}

a:is(:link, :visited) {
  color: var(--color);
}

.header {
  padding: 0 5vw 5vw 0;
  display: grid;
  grid-template-columns: 1.5fr 2rem 1fr;
  grid-template-rows: min-content auto min-content auto;
  position: relative;
}

.header:before {
  content: "";
  background-color: hsl(0, 0%, 94%);
  position: absolute;
  left: calc(50vw - calc(var(--max-width) / 2));
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

nav {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  right: calc(50vw - (var(--max-width) / 2));
  left: 0;
  padding: 0.5rem;
  background-color: #fff;
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.173);
  z-index: 9;
  border-bottom: 2px solid var(--color);
}
@supports (backdrop-filter: blur(1rem)) {
  nav {
    background-color: rgba(255, 255, 255, 0.735);
    backdrop-filter: blur(1rem);
  }
}
@media (max-width: 900px) {
  body {
    margin-top: 3rem;
  }
  nav {
    left: 0;
  }
  nav .links {
    display: none;
  }
  .header:before {
    left: 1rem;
  }
}

.links {
  display: none;
  list-style: none;
  margin: 0 0 0 1rem;
  padding: 0;
  font-size: 1.2rem;
  font-stretch: 60%;
}
.links li {
  padding: 0 0.5rem;
}

.header-img {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 5;
}

.header-group {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  background: #fff;
  padding: 1rem 2rem 0;
  margin-top: 4rem;
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.173);
  max-width: 55rem;
}

@media (max-width: 50em) {
  .header-img {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .header-group {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
    margin-top: 0;
  }
}
@media (max-width: 20em) {
  .header-group {
    padding: 1rem 1rem 0;
  }
}

@supports (backdrop-filter: blur(1rem)) {
  .header-group {
    background: rgba(255, 255, 255, 0.735);
    backdrop-filter: blur(1rem);
  }
}

.article {
  padding: 1rem;
  margin: auto;
  max-width: calc( var(--max-width) - 1rem);
}

figure {
  margin: 0;
  padding: 0;
}

.nextclicks {
  margin: 3rem auto 0;
  width: calc(100% - 4rem);
  max-width: 1200px;
}
.nextclicks img {
  height: calc(0.67px * var(--tt-bind));
  object-fit: cover;
}
article + .nextclicks {
  margin-top: 10vh;
}
@media (min-width: 40em) {
  .nextclicks {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2em;
  }
  .section-label:before,
  .section-label:after {
    content: "    ";
    width: 3em;
    height: 0.8em;
    display: inline-block;
    background-image: linear-gradient(currentColor, currentColor);
    background-size: 1px 1px;
    margin: 0 1em;
    opacity: 0.33;
    background-repeat: repeat-x;
    background-position: center center;
  }
}

.nextclick-article {
  display: block;
  text-align: center;
}

.eyebrow {
  margin: 0 0 -1.1rem;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.25em;
  font-size: 0.7em;
  background: #fff;
  padding: 0 0.5em 0.37em 0;
  position: relative;
  display: table;
  color: var(--color);
}

footer {
  text-align: center;
  background-color: hsl(0, 0%, 94%);
  border-top: 2px solid var(--color);
  margin-top: 20vh;
  padding: 2rem 0 20vh;
}
footer .links {
  padding: 0;
  margin: 2rem auto;
  justify-content: center;
}
footer small {
  display: block;
  max-width: var(--max-width);
  font-size: 0.75rem;
  line-height: 1.25;
  margin: 0 auto;
  padding: 1em 2rem;
}


/* Styles for the intrinsic style inspector. These are not for page styles */

.primary-headline {
  position: relative;
  cursor: pointer;
}

.primary-headline:hover {
  color: var(--color);
}

.primary-headline:hover:after {
  content: 'Inspect style';
  position: absolute;
  font-family: var(--font-text);
  font-size: 0.7rem;
  border-radius: 6rem;
  background: #fff;
  border: 1px solid var(--color);
  color: hsl(0, 0%, 15%);
  padding: 0.3rem 0.4rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-stretch: 90%;
}
@supports (backdrop-filter: blur(1rem)) {
  .primary-headline:hover:after {
    background: #ffffff77;
    backdrop-filter: blur(1rem)
  }
}

.tt-configuration,
.tt-configuration * {
  font-family: var(--font-text);
  font-size: 14px;
  font-weight: 400;
  color: #333;
}
.tt-configuration {
  display: none;
  width: 380px;
  padding: 10px 0 10px 20px;
  position: fixed;
  bottom: 1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 4px;
  backdrop-filter: blur(10px);
  box-shadow: 0 6px 30px #00000055;
  animation: tt-configuration 0.2s ease-out 1;
}
.tt-configuration.active {
  display: block;
}
@keyframes tt-configuration {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(-80%) translateX(10%);
  }
}
.tt-configuration-inner {
  display: flex;
}
.tt-configuration input {
  background: none;
  border-radius: none;
  border: 0 solid #00000033;
  border-bottom-width: 2px;
  transition: border-color 0.2s ease-in-out;
}
.tt-configuration input:focus {
  border-color: #348ceb;
  outline: none;
}
.tt-configuration h2 {
  font-weight: 700;
  font-size: 18px;
  margin: 0 0 6px;
}
.tt-configuration h3 {
  margin: 12px 0 4px;
  text-transform: uppercase;
  letter-spacing: 0.13em;
  font-size: 9px;
  font-weight: 500;
  opacity: 0.75;
}
.tt-easing {
  width: 160px;
  height: 160px;
  stroke-width: 2;
  position: relative;
}
.tt-ease-handle {
  cursor: pointer;
}
.tt-close {
  border: none;
  padding: 0;
  background: none;
  outline: none;
  position: absolute;
  top: 12px;
  right: 20px;
  opacity: 0.2;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}
.tt-close:hover {
  opacity: 0.8;
}
#tt-currentpx {
  font-size: 7px;
  font-family: var(--font-text);
  font-weight: 400;
  opacity: 0.5;
  text-align: center;
}
`;
 
const TestAbout = ({marginStatus}) => {
    return (
        <StyledTestAbout>
            <div className="header typetura" style={{marginTop:`${marginStatus}`}}>
  <nav>
    <div className="logo"><img style={{maxWidth:'9vw',mixBlendMode:'multiply'}} src="https://dl3.pushbulletusercontent.com/49vHkgXeCJTOuuyTmT8zuUpG6cDoC109/image.png" alt="" /></div>

    <ul className="links">
      <li>City Guides</li>
      <li>Travel</li>
      <li>Fashion</li>
      <li>Art + Culture</li>
      <li>Home + Design</li>
    </ul>
  </nav>

  <img
    className="header-img"
    style={{filter:'saturate(0.01) contrast(1.1)'}}
    src="https://dl3.pushbulletusercontent.com/RMj2ZbrxL8WbfBPmYOb5I9YzDgf3ZqeN/image.png"
    alt=" "
  />
  <div className="header-group">
    <h3 className="label">Travel</h3>
    <h1 className="primary-headline typetura">
      How a road trip across The United States brought inspiration
    </h1>
    <h3 className="byline">Gabrielle Kellner</h3>
    <h4 className="dateline">July 23, 2020</h4>
  </div>
</div>





<footer>
  <div className="logo">Explore</div>

  <ul className="links">
    <li>City Guides</li>
    <li>Travel</li>
    <li>Fashion</li>
    <li>Art + Culture</li>
    <li>Home + Design</li>
  </ul>

  <small>
    Explore is a small group of explorers sharing our experiences as we head
    out to find new and exciting places. Road trips, day trips, or long
    excursions, we’re excited to share them with you.
  </small>
  <small>
    Special thank you to <a href="www.c-a-s-t.com">CAST</a> for providing <a href="http://solesans.c-a-s-t.com/">Sole Sans</a> for the text of this demo and to
    <a href="https://djr.com/">David Jonathan Ross</a> and
    <a href="https://fontofthemonth.club/">Font of the Month Club</a> for
    <a href="https://djr.com/notes/job-clarendon-font-of-the-month"
      >Job Clarendon</a
    >
    and
    <a
      href="https://djr.com/notes/roslindale-display-widths-font-of-the-month"
      >Roslindale</a
    >.
  </small>
</footer>

<div className="tt-configuration">
  <h2>Headings</h2>
  <button className="tt-close" onclick="ttClose()">close</button>
  <div className="tt-configuration-inner">
    <div>
      <h3>Font Family</h3>
      <label
        ><input
          id="fontFamily"
          type="text"
          value="roslindale-variable"
          oninput="writeCurve(p1, p2)"
      /></label>
      <h3>Max Size</h3>
      <label
        ><input
          id="maxSize"
          type="number"
          value="120"
          step="1"
          oninput="writeCurve(p1, p2)"
        />
        px</label
      >
      <h3>Area</h3>
      <label
        ><input
          id="area"
          type="number"
          value="1400"
          step="10"
          oninput="writeCurve(p1, p2)"
        />
        px</label
      >
    </div>
    <svg
      viewBox="-10 -10 120 120"
      xmlns="http://www.w3.org/2000/svg"
      id="easing"
      className="tt-easing"
    >
      <rect width="100" height="100" fill="#ffffff88" stroke="#eeeeee" />
      <rect id="tt-marker" width="1" height="100" x="80" fill="#348ceb88" />
      <text id="tt-currentpx" x="20" y="-4px">20px</text>
      <line
        id="tt-line-start"
        x1="0"
        y1="100"
        x2="20"
        y2="30"
        stroke="#00000033"
        stroke-linecap="round"
      />
      <line
        id="tt-line-end"
        x1="100"
        y1="0"
        x2="80"
        y2="20"
        stroke="#00000033"
        stroke-linecap="round"
      />
      <path
        id="tt-path"
        d="M0 100 C 20 30 80 20 100 0"
        stroke="#348ceb"
        fill="none"
        stroke-linecap="round"
      />
      <circle
        className="tt-ease-handle"
        id="ease-start"
        cx="20"
        cy="30"
        r="3"
        fill="#fff"
        stroke="#999"
      />
      <circle
        className="tt-ease-handle"
        id="ease-end"
        cx="80"
        cy="20"
        r="3"
        fill="#fff"
        stroke="#999"
      />
    </svg>
  </div>
</div>

        </StyledTestAbout>
    )
}
 
export default TestAbout
 