import React from 'react'
import styled from 'styled-components'
 
const StyledSlantHeading = styled.div`
 @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");


	box-sizing: border-box;
	padding: 0;
	margin: 0;


.body {
	font-family: "Roboto", sans-serif;
	background: url("https://dl3.pushbulletusercontent.com/72CJ0WrhmoTw50660rLmxZSMIukPsApi/image.png")
		no-repeat bottom center/cover;
	height: 100vh;
	position: relative;
}

a {
	text-decoration: none;
}

h1 {
	font-size: 7rem;
}

h2 {
	font-size: 3rem;
}

.outline {
	background: rgba(0, 0, 0, 0.2);
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.main-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@media screen and (max-width:750px){
  .main-container {
    margin-top: 28em;

}
}

.page-selector {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.up,
.down {
	padding: 40px;
}

.up a,
.down a {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #fff;
	font-weight: bold;
	font-size: 1.2rem;
}

.up img,
.down img {
	width: 30px;
	height: 30px;
}

.selected-page {
	height: 100%;
	width: 50vw;
	background: rgba(255, 231, 16, 0.7);
	clip-path: polygon(25% 0%, 100% 0, 100% 100%, 0% 100%);
	color: #000;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	padding: 40px;
	text-align: right;
	font-size: 1.2rem;
}

.read a,
.views {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	text-transform: uppercase;
}

.read img {
	margin-left: 10px;
}

.views img {
	margin-right: 10px;
}

.actual-page {
	width: 100%;
}


@media (max-height: 550px) {
	h1 {
		font-size: 5rem;
	}

	h2 {
		font-size: 2rem;
	}
}
@media (max-width: 750px) {
	h1 {
		font-size: 2.5rem;
	}

	h2 {
		font-size: 1.62rem;
	}
}

`;
 
const SlantHeading = () => {
    return (
        <StyledSlantHeading>
              <div className='body'>
  	<div className="outline"></div>

  	<div className="main-container">

  		<div className="page-selector">
  			<div className="up">
  				<a href="#">
  					<img src="https://svgshare.com/i/LDn.svg" />
  					1
  				</a>
  			</div>
  			<div className="down">
  				<a href="#">
  					3
  					<img src="https://svgshare.com/i/LEq.svg" />
  				</a>
  			</div>
  		</div>

  		<div className="selected-page">
  			<div className="read">
  				{/* <a className='button' href="#">
  					Learn More
  					<img src="https://svgshare.com/i/LEP.svg" />
  				</a> */}
				<img style={{padding:'0 0 0 1em'}} src="https://dl3.pushbulletusercontent.com/KDqyUVJ5eWdugH1sqRvsPkNHSZxtviYo/dbprobadge-rmbg.png" alt="" />
  			</div>

  			<div className="actual-page">
  				<h1>Over 50+</h1>
  				<h2 style={{fontSize:'1.1em'}}>Players we have worked with have taken it to the next level.</h2>
  			</div>

  			<div className="views">
  				{/* <img src="https://svgshare.com/i/LEY.svg"/> */}
  		

<a
  style={{position:'relative',left:'50%',transform:'translate3d(-33%,0,0)'}}
  className="group relative inline-block focus:outline-none focus:ring"
  href="/download"
>
  <span
    className="absolute inset-0 translate-x-1.5 translate-y-1.5 bg-yellow-300 transition-transform group-hover:translate-y-0 group-hover:translate-x-0"
  ></span>

  <span
    className="relative inline-block border-2 border-current px-8 py-3 text-sm font-bold uppercase tracking-widest text-black group-active:text-opacity-75"
  >
    Sign&nbsp;Up
  </span>
</a>



  			</div>
  		</div>

  	</div>
  </div>
        </StyledSlantHeading>
    )
}
 
export default SlantHeading
 