import React, {useRef,useState} from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import SheetDB from 'sheetdb-js'
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"



const StyledShoes = styled.div`
.input-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

  .overlay {
    background-image: linear-gradient(to right, rgba(9, 100, 232, 0.9), rgba(8, 96, 234, 0.9));
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
    padding: 110px 0;
}
.overlay .container {
    margin: auto;
}



.breezec-inner p {
    border: 1px solid #222;
    /* padding: 30px; */
    line-height: 150%;
    transform: translate(10%,0);
    color: black;
   width: 80%;
   min-width: 10ch;
  }
  
  .breezec-bottom {
    position: relative;
    border-top: 1px solid var(--accent);
    padding: 10px;
    color: var(--text2);
    font-family: var(--font1);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: stretch;
  }
  
  .breezec-bottom span {
    padding: 10px;
    border: 1px solid var(--accent);
    width: 100%;
  }

/*form */
/* Form */
#form-add {
  margin: 2% auto;
  padding: 1em;
  width: 320px;
  color: #aaa;
  border: 0.1em solid #222;
  border-radius: 4px;
  background-color: rgba(235,235,235,0.32);
}



input,
select,
textarea {
  width: 100%;
  height: 3em;
  text-indent: 0.5em;
  border: 0.1em solid #ddd;
  border-radius: 4px;
  outline-color: #1abc9c;
}



/* .name input[name=recommender] {
  float: right;
} */

.input[type='text'] {
  margin-top: 1.5em;
  width: 100%;
}


textarea {
    height: 16vh;
}

label {
    color: #9ca3af;
}

form label {
  font-size: 0.9em;
}

/* Base */


/* Form */



/* .name input[name=recommender] {
  float: right;
} */



.dropdown-holder {
   
    padding: 2em;
    position: relative;
    z-index: 9999999999999;
}

.dropdown-holder-hide {
  display: none;
}

/*end form  */





[data-confet="true"] {
left: 0;
display: block;
 position: absolute;
 top:0;
 z-index: 99999999999;
}
[data-confet="false"] {
 left: 0;
 position: absolute;
 top:0;
 z-index: 99999999999;
 display: none;
}

/* end confetti */

.quick-links {

  display: flex;
  gap: 2.1em;
  justify-content: center;
  border: 2px solid #222;
  border-radius: 5%;
  
}
.quick-links img {
  padding: 0.1em;
}
.quick-links img:hover {
}



.book-cards .book-card {
    color: #202020;
    background-color: rgba(245,245,245,0.67);
    min-width: 67vw;
}


.edit-modal {
  width: 100%;
  background: #f00;
  height: auto;
  margin: auto;
  padding: 1em;
  background-color: rgba(35,35,35,0.12);
}


.taso-btn {
    background-color: #fff;
    margin: 25px 0;
    color: #214dcb;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(255, 255, 255, 0.32);
    box-shadow: 0px 10px 30px 0px rgba(255, 255, 255, 0.17);
}
.contact-info {
    padding: 0 1em 0px 1em;
}

.contact-title {
    font-size: 2.3em;
    font-weight: 600;
    color: #fff;
    margin-bottom: 30px;
}

.contact-info p {
    color: #ececec;
}

ul.contact-info {
    margin-top: 30px;
}

ul.contact-info li {
    margin-bottom: 22px;
}



ul.contact-info span {
    font-size: 20px;
    line-height: 26px;
}
ul.contact-info li {
    display: flex;
    width: 100%;
}

.info-left {
    width: 10%;
}

.info-left i {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 30px;
    color: #ffffff;
}

.info-right h4 {
    color: #fff;
    font-size: 18px;
}
.contact-page .info-left i{
color: #FE846F;
}
.btn {
display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: 'Poppins', sans-serif;
    padding: 10px 30px 10px;
    font-size: 17px;
    line-height: 28px;
    border: 0px;
    border-radius: 10px;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.btn-big {
    color: #ffffff;
    -webkit-box-shadow: 0px 5px 20px 0px rgba(45, 45, 45, 0.47843137254901963);
    box-shadow: 2px 5px 10px 0px rgba(45, 45, 45, 0.19);
    color: #fff !important;
    margin-right: 20px;
    background: #FE846F;
    transition: .2s;
    border: 2px solid #FE846F;
    margin-top: 50px;
}

@media only screen and (max-width: 767px) {
.contact-form {
    padding: 30px;
}
.contact-form:before {
    width: 100%;
}

}

#flex-row {
    display: flex;
    flex-direction: row;
    background-color: transparent;
}
@media screen and (max-width: 700px) {
    
    #flex-row {
        display: flex;
        flex-direction: column;
        padding: 0;
    }
  
    
} 




`;
const Shoes = () => {
    
    
    const inputRef = useRef()

    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [zipCode, setZipCode] = useState()
    const [message, setMessage] = useState()
    const [selectedArea, setSelectedArea] = useState('City of Chicago')
    const [phoneNumber, setPhoneNumber] = useState()
    

    // const [, set] = useState()
    // const [name, setName] = useState()
    // const [name, setName] = useState()

    const [bookRecs, setBookRecs] = useState()
    const [nameOption, setNameOption] = useState('')
    const [title, setTitle] = useState()
    const [editTitle, setEditTitle] = useState()
    const [editAuthor, setEditAuthor] = useState()
    const [author, setAuthor] = useState()
    const [genre, setGenre] = useState()
    const [recommender,setRecommender] = useState('')
    const [thoughts, setThoughts] = useState()
   
  
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isExploding, setIsExploding] = useState(false);
    const [goodReadsLink, setGoodReadsLink] = useState(null);
    const [confet, setConfet] = useState("false");
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(0);
  // CLOUDINARY HANDLE IMAGE UPLOAD
  
//   const handleEdit = (e) => {
//       e.preventDefault();
  
//       // let urlEditingTo = 'https://sheetdb.io/api/v1/7pax586owcf4z/   
  
//       fetch(`https://sheetdb.io/api/v1/7pax586owcf4z/id/${editIndex}`, {
//         method: 'PATCH',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//             data: {
//               title,
//               author,
//               genre,
//               recommender,
              
//             }
//         })
//     })
//       .then((response) => response.json())
//       .then((data) => console.log(data));
  
  
//       // SheetDB.write(urlPostingTo, { sheet: 'Sheet1', data: { title, author, genre, recommender, thoughts, goodReadsLink} }).then(function (result) {
//       //   setTitle('');
//       // setAuthor('');
//       // setGenre('');
//       // setRecommender('');
//       // setThoughts('');
//       // setGoodReadsLink('');
//       //   Toastify({
//       //     text: "Successfully Added Book, Thanks!",
//       //     duration: 3000
          
//       //   }).showToast();
//       //   setIsModalOpen(false);
//       //   setTimeout(()=>{window.location.reload()}, 3000);
      
//       // }, function (error) {
//       //   Toastify({
      
//       //                 text: `sorry, something happened: ${error.message}`,
      
//       //                 duration: 3000
      
//       //             }).showToast();
//       //             console.log(error);
//       //         });
  
//   }
  
  
  const handleSubmit = (e) => {
      e.preventDefault();
  // sheetdb posting start
    
  
  let urlPostingTo = 'https://sheetdb.io/api/v1/7pax586owcf4z'
  SheetDB.write(urlPostingTo, { sheet: 'Sheet1', 
  data: { id: new Date().valueOf(),
  name,
  email,
  phoneNumber,
  selectedArea,
  message,
  zipcode:zipCode,
  } }).then(function (result) {
    setName('');
  setEmail('');
  setMessage('');
  setZipCode('');
  setSelectedArea('');
  
    Toastify({
      text: "Successfully Sent, Thanks!",
      duration: 3000
      
    }).showToast();
    // setIsModalOpen(false);
    // setTimeout(()=>{window.location.reload()}, 3000);
  
  }, function (error) {
    Toastify({
  
                  text: `sorry, something happened: ${error.message}`,
  
                  duration: 3000
  
              }).showToast();
              console.log(error);
          });
      }
    
  


  return (
    <StyledShoes>
    

    <div><section className="section-bg" style={{background:'rgba(0,135,240,0.68)'}}  data-scroll-index="7">
    <div className="overlay pt-100 pb-100" >
      <div className="container" >
         <div className="">
              <div className="col-lg-4 d-flex align-items-center" style={{margin:'auto',padding:'0.5em',fontSize:'1.32em', color:'#efefef'}}>
                  <div className="contact-info">
                  <a style={{color: '#efefef',fontSize:'2em'}}
                   href='tel:7732428707'>773-242-8707</a>
                                <i className="fas fa-mobile-alt"></i>
                      <h2 className="contact-title">Have Any Questions?</h2>
                      <p>Second Chance Homes Is here to support you. We will get in touch with you within 24-48 hours.</p>
                      <ul className="contact-info">
                          <li>
                            <div className="info-left">
                               
                            </div>
                            <div className="info-right">
                                <h4>205 N. Michigan ave STE 810, Chicago, IL 60601</h4>
                            </div>
                          </li>
                          <li>
                            <div className="info-left">
                                <i className="fas fa-at"></i>
                            </div>
                            <div className="info-right">
                                <h4>info@2ndchanceshomesllc.com</h4>
                            </div>
                          </li>
                          <li>
                            <div className="info-left">
                                <i className="fas fa-map-marker-alt"></i>
                            </div>
                            <div className="info-right">
                               
                            </div>
                          </li>
                      </ul>
                  </div>
              </div>
              <div className="col-lg-8 d-flex align-items-center">
                      <div className="contact-form" id="form-start" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                  {/* <!--Contact Form--> */}
                                 
                                  <form id="form-add" onSubmit={handleSubmit} style={{ margin: '2% auto',padding: '1em',
                                  display:'flex',
                                  flexDirection:'column',
                                  gap: '1em',
  width: '320px',
  color: '#222',
  border: '0.1em solid #222',
  borderRadius: '4px',
  backgroundColor: 'rgba(235,235,235,0.32)'}}>
   
                        <div className="input-group" >
                        <label for="name">Full Name</label>
                        <input name="name" onChange={e => setName(e.target.value)
                            } required type="text" placeholder="Full Name" value={name} />
                        </div>
                        
                        <div className="input-group">
                        <label for="email">email</label>
                        <input name="email" onChange={e => setEmail(e.target.value)
                            } required type="email" placeholder="Email Address" value={email} />
                        </div>
                        <div className="input-group">
                    <label for="phoneNo">Phone Number</label>
                    <input name="phoneNo" onChange={e => setPhoneNumber(e.target.value)
                                } required type="text" placeholder="Phone Number" value={phoneNumber} />
                        </div>

                                {/* <input onChange={e => setRecommender(e.target.value)
                                } required type="text" placeholder="Your Name" value={recommender} /> */}
                                
                                <br/>
                                <div className="input-group">
   <div className="dropdown">
        <label for="select-name">Your Area: {selectedArea}</label>
        <select name="select-name" onChange={e => setSelectedArea(e.target.value)} id="select-choice" value={selectedArea}>
        <option value="City Chicago">City of Chicago</option>
        <option value="Suburbs Chicago">Suburbs of Chicago</option>
        <option value="Indiana">Indiana</option>
        </select>
  </div>
                            </div>
                            <div className="input-group">
                            <label for="zipcode">Zip Code</label>
                            <input name="zipcode" onChange={e => setZipCode(e.target.value)
                                } required type="text" placeholder=" Zip Code" value={zipCode} />
                                <br />
                                <br />
                            <label for="thoughts">Message</label>
                            <textarea name="thoughts" onChange={e => setMessage(e.target.value)
                            } required placeholder="Message"
                                value={message}></textarea>
                                </div>
                  <br />
                        <button type="submit" style={{background:'#ceb5b0',padding:' 10px 14px',color:'#222',fontWeight:'bold',borderRadius:'6px'}}>Submit</button>
  </form> 
                                 
                              </div>
              </div>
         </div>
     </div>
        </div>
  </section></div>
    </StyledShoes>
  )
}

export default Shoes