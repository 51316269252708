import React from 'react'
import styled from 'styled-components'
 
const StyledFacebookSubHero = styled.div`
 /* - - - - - - */


 iframe[id^='player2'] {
	min-height: 67vh !important;
	height: auto;
	
	max-width:500px !important;
	margin: 2.3em auto 2em auto;
	
	}

#new-facebook-video-modal {
	background-color: #398b8d;
	padding: 70px 20px 80px;
	position: relative;

	display: flex;
	align-items: flex-start;
	flex-direction: row-reverse;
	justify-content: center;

	color: #dde;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	text-align: left;
	font-size: 18px;
	line-height: 1.5;
	
}

#new-facebook-video-modal--image {
	max-width: 370px;
	width: 40%;
	min-width: 300px;
	height: auto;
}

#new-facebook-video-modal--text-container {
	max-width: 365px;
	padding-left: 64px;
	padding-top: 40px;
}

#new-facebook-video-modal--title {
	font-size: 32px;
	font-weight: 600;
	line-height:1.2;
	color: #eb4;
}
#new-facebook-video-modal--title span{
	font-size:20px;
	font-weight:500;
	display:block;
	color: #dde;
}

#new-facebook-video-modal--description {
	font-size: 18px;
}


/* - - - - - - */

@media screen and (max-width: 720px) {
	#new-facebook-video-modal {
		display: block;
		padding: 40px 20px 20px;

	}
	#new-facebook-video-modal--title{
		font-size:22px;
		line-height:1.4;
	}
	#new-facebook-video-modal--title span{
		font-size: 18px;
	}
	#new-facebook-video-modal--description{
			font-size:16px;
	}
	#new-facebook-video-modal--text-container {
		margin: 0 auto;
		padding: 0;
	}

	#new-facebook-video-modal--image {
		display: block;
		width: 100%;
		margin: 24px auto 0;
	}
}


`;
const StyledInstaSubHero = styled.div`

iframe[id^='player'] {
	min-height: 67vh !important;
	height: auto;

	/*padding-right: 3em !important;*/
	max-width:500px !important;
	margin: 2.3em auto 2em auto;
	
	}



  /* - - - - - - */

#post-to-instagram-modal {
	background-color: #1400c8;
	background-image: linear-gradient(-135deg, #1400c8, #b900b4, #f50000);
	padding: 70px 20px 80px;
	position: relative;

	display: flex;
	align-items: flex-start;
	flex-direction: row-reverse;
	justify-content: center;

	color: white;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	text-align: left;
	font-size: 18px;
	font-weight: 300;
	line-height: 1.4;
}

#post-to-instagram-modal--image {
	max-width: 370px;
	width: 40%;
	min-width: 300px;
	height: auto;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

#post-to-instagram-modal--text-container {
	max-width: 320px;
	padding-left: 64px;
}

#post-to-instagram-modal--title {
	font-size: 32px;
	font-weight: 700;
	padding-bottom: 20px;
}

#post-to-instagram-modal--description {
	font-size: 18px;
	font-weight: 300;
}

/* - - - - - - */

#post-to-instagram-modal--points {
	/*	margin-top: 32px;*/
	color: white;
}

#post-to-instagram-modal--points strong {
	font-size: 20px;
	margin-top: 32px;
	font-weight: 400;
	display: block;
}

#post-to-instagram-modal--points p {
	margin-top: 26px;
}

/* - - - - - - */

#post-to-instagram-modal--connect-button {
	padding: 28px 0 0;
}

#post-to-instagram-modal--learn-more {
	display: inline-block;
	padding-left: 12px;
	color: white;
	opacity: 0.6;
	font-size: 16px;
}

#post-to-instagram-modal--learn-more:hover {
	opacity: 1;
}

/* - - - - - - */

@media screen and (max-width: 720px) {
	#post-to-instagram-modal {
		display: block;
	}

	#post-to-instagram-modal--text-container {
		margin: 0 auto;
		padding: 0;
	}

	#post-to-instagram-modal--image {
		display: block;
		width: 100%;
		margin: 48px auto 0;
	}
}

/* Quick Hacky Button. You probably dont want to use this */

.md-btn {
	font-family: "Roboto medium", sans-serif;
	font-size: 14px;
	display: inline-block;
	height: 36px;
	min-width: 88px;
	padding: 6px 16px;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 0;
	border-radius: 2px;
	background: #1400c8;
	color: white;
	outline: 0;
	text-transform: uppercase;
}

.md-btn-raised {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
		0 3px 1px -2px rgba(0, 0, 0, 0.2);
        transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    }

    `;
 
const FacebookSubHero = () => {
    return (
        <>
   
     
        <StyledInstaSubHero>
            {/* <!--start of content --> */}
        <div id="post-to-instagram-modal" style={{background:'url(https://www.toptal.com/designers/subtlepatterns/uploads/micro_carbon.png)',backgroundRepeat:'repeat',backgroundSize:'100%'}}>
        
        <div id="post-to-instagram-modal--text-container">
        
        <div id="post-to-instagram-modal--title">
		Fire Mixtapes 🔥
        </div>
        
        <div id="post-to-instagram-modal--description">
		Do you have the skills and talent, but lack the connections and exposure to get noticed?  


  If so, then Fire Mixtapes is right for you.


<img src="https://firemixtapesapparel.store/images/main-p-800.jpeg" alt="fire mixtapes logo" />

        </div>
        
        <div id="post-to-instagram-modal--points">
        {/* 			
        <!--<strong>Post everywhere from one place</strong>
            <p>Schedule and publish single images to Instagram in the same place you post to other social accounts.</p> --> */}
            
            <strong style={{fontWeight:'bold',color:'#e0b646'}}>INVEST IN YOURSELF</strong>
            <p>The Denard Bros are highly talented and respected members in their industry who have built a successful business coaching &amp; mentoring basketball players of all ages. They have a vast network of contacts in the professional basketball world, and they know what it takes to get players noticed by scouts and coaches.</p>
<p>
Fire Mixtapes has over 20,000 followers across all social media platforms. If you work with Fire Mixtapes, they will craft a mixtape that showcases your very best. Fire Mixtapes is the first step to you getting exposure to the right people in the industry.
</p>
<p>
Working with the Fire Mixtapes is not a guarantee of success, but it is a great way to increase your chances of achieving your dream of playing professional basketball &amp; has been a proven path for players who have found themselves contracts after the exposure of the Fire Mixtapes and Denard Bros have offered. The Denard Bros have the experience, the connections, and the know-how to help you reach your full potential.
</p>
<p>
If you don't have a Fire Mixtape. What are you doing? Don't miss out on this opportunity. Contact the Denard Bros today and start your journey to professional basketball with a Fire Mixtape. </p>
        
        </div>
        
        <div id="post-to-instagram-modal--connect-button">
            {/* <!-- 	I hope you all use your own buttons and not this one. this is a hack	 --> */}
            <button type="button" className="md-btn md-btn-raised">Apply for a Mixtape Today</button>
            <a href="https://business.instagram.com/getting-started/#get-a-business-profile" target="_blank" rel="noopener noreferrer" id="post-to-instagram-modal--learn-more">learn more</a>
        </div>
        
        </div>
        
        {/* <img id="post-to-instagram-modal--image" src="https://storage.googleapis.com/temp-email-content/instagram-new-preview-image.jpg" width="300" height="auto" alt="Instagram Screenshot"/> */}
		<iframe
		id="player"
    
      src="https://www.instagram.com/reel/CtXcv1EPMcH/embed/"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
        </div>
        {/* <!--end of content --> */}
        </StyledInstaSubHero>
        <StyledFacebookSubHero>
            {/* <!--start of content --> */}
<div id="new-facebook-video-modal">

	<div id="new-facebook-video-modal--text-container">

		<div id="new-facebook-video-modal--title">
			<span>contact us today:</span> Fire Mixtapes
		</div>

		<div id="new-facebook-video-modal--description">
			{/* <p>You can now upload videos to Facebook in [Social Marketing].</p>
			<p>Video posts perform significantly better than text-only posts, and provide increased engagement, search engine ranking, and shareability.</p> */}
		</div>
		
	</div>

	{/* <img id="new-facebook-video-modal--image" src="https://media-prod.apigateway.co/image/get/0200a6d1-9395-45ae-bee6-05f13357bd7c" width="365" height="auto" alt="Instagram Screenshot"/> */}
		<iframe
		id="player2"
      
      src="https://www.instagram.com/p/CtcBBxYrYzD/embed/"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />

</div>
{/* <!--end of content --> */}
        </StyledFacebookSubHero>
        </>
    )
}
 
export default FacebookSubHero
 





 