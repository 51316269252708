import React from 'react'
import styled from 'styled-components'
import Events from './Events'
import NewNews from './NewNews';
import Invite from './Invite';
import FormSpree from './FormSpree';
import Jtest from './Jtest';
import { Link } from 'react-router-dom';

const StyledProRun = styled.div`
@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/metropolis.min.css");
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexend/v18/wlptgwvFAVdoq2_F94zlCfv0bz1WC2UW_LA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexend/v18/wlptgwvFAVdoq2_F94zlCfv0bz1WCzsW_LA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lexend/v18/wlptgwvFAVdoq2_F94zlCfv0bz1WC9wR_LA.ttf) format('truetype');
}
:root {

}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: break-word;
}
html {
  font-size: 62.5%;
}
body,
html {
  height: 100%;
}
body {
  font-size: var(--font-size);
  font-family: var(--font);
  line-height: 1;
}
i,
u {
  font-style: normal;
  text-decoration: none;
}
#hero {
    --font: 'Lexend', arial, helvetica, sans-serif;
    --font-size: 1.6rem;
    --spacing: 20px;
    --spacing-l: 40px;
    --spacing-xl: 60px;
    --spacing-xxl: 80px;
    --spacing-xxxl: 120px;
  height: 100vh;
  width: 100%;
  background: red;
  position: relative;
  background: url(https://dl3.pushbulletusercontent.com/E6ATUjGF5GIxWyMK9bb0fyYLyjHPcckd/prorunburnbg.png);
  background-size: cover;
  background-position: center center;
  transform: translateZ(0);
}
#hero {
  overflow: hidden;
}
#hero::after {
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(-45deg, #222, #111, #222, #0f);
//   background: linear-gradient(-45deg, #111, #222);
  background-size: 1000%;
  mix-blend-mode: normal;
  filter:  contrast(1.3) saturation(0);
  opacity: 0.125;
//   animation: bg_rotate 5s linear infinite;
  will-change: background-position;
  transform: translateZ(0);
  pointer-events: none;
}
@keyframes bg_rotate {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
#hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
}
.bottom {
  position: absolute;
  bottom: var(--spacing-xxl);
  padding: var(--spacing-xxl) var(--spacing-xxxl);
  max-width: 90%;
//   mix-blend-mode: lighten;
  color: #ccc;
}
.bottom {
  text-align: center;
}
.bottom sup {
  letter-spacing: 3px;
  opacity: 0.7;
  font-weight: 300;
  display: inline-block;
  font-size: 1.1rem;
  margin-bottom: 20px;
  position: relative;
  color: #fff;
}
.bottom sup::after,
.bottom sup::before {
  display: block;
  content: '';
  width: 50%;
  height: 0.5px;
  margin-top: -0.25px;
  position: absolute;
  top: 50%;
  right: 110%;
  background: currentcolor;
}
.bottom sup::before {
  right: auto;
  left: 110%;
}
.bottom h1 {
  font-size: 9rem;
  margin: 0 0 var(--spacing);
  letter-spacing: -1px;
}
.bottom p.lead {
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 1px;
}
.bottom button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  padding: var(--spacing) var(--spacing-l);
  border-radius: 50px;
  background: #aaa;
  color: #222;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: var(--spacing-l);
  opacity: 0.8;
  letter-spacing: 0.5px;
}
.bottom button:hover {
  background: #eee;
}
.bottom button.loading {
  background: #aaa;
  animation: loading alternate 0.2s ease infinite;
}
@keyframes loading {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 1000px) {
  .bottom h1 {
    font-size: 8rem;
  }
}
@media screen and (max-width: 700px) {
  .bottom sup {
    letter-spacing: 2px;
  }
  .bottom {
    padding: var(--spacing-xxl);
  }
  .bottom h1 {
    font-size: 7rem;
  }
  html {
    font-size: 45%;
  }
}
.top_left {
  position: absolute;
  top: var(--spacing-l);
  color: #fff;
  opacity: 0.5;
  font-weight: bold;
  font-size: 1.6rem;
  letter-spacing: 1.5px;
  font-family: 'Metropolis', sans-serif;
}
.top_left i {
  display: block;
  font-size: 70%;
  text-transform: uppercase;
  font-weight: 300;
}
.mine {
    height: auto;
    position: relative;
    top: -5vh;
    height: auto;
    display:flex; 
    flex-direction: column;
    text-align: left;
    padding: 1rem 1.332rem 0 1.332rem
}

.mine h1 {
    font-size: 3.5em;
    color: #111;
    line-height: 1.32;
}
 .mine img {
    max-width: 7rem; 
    position: absolute;
    top: calc(100vh - 53.37rem);
    left: 50%;
    transform: translate3d(-50%,0,0);
 }

`;

const ProRun = () => {
  window.scrollTo({
    top: 0,
    left: 100,
    behavior: 'smooth'
  })
    return (
        <>
        <StyledProRun>
            <div id="hero">
                <div className="anim-layer"></div>
                {/* <div className="flex" style={{flexDirection:'column'}}>
                </div> */}
                <div className="mine">
                    <Link to="/">
                      <img src="https://dl3.pushbulletusercontent.com/jxilqC0jN195H2GQKBphBcG90ee3jGWp/proruns-rmbg-2.png" alt="denard bros pro run logo" />
                    </Link>
                    <sup><span style={{width:'auto',padding:'0.5rem',border: '2px solid red',borderRadius:'12px',boxShadow:'1px 2px 2px rgba(0,0,0,0.12)',fontWeight:'bold'}}>PROS ONLY</span></sup>
                    <br />
                    <h1>
                        Denard Bros Pro Run
                    </h1>
                    <p className="lead">
                    Led by the seasoned pro trainers Eddie Denard and his brother Aubery Denard exceptional Pro Trainer, this remarkable initiative is dedicated to elevating your skills to new heights. Prepare to be amazed by top-notch games from NBA players and global professionals. 
                    </p>
                    <br />
                    <a href="https://www.eventbrite.com/e/denard-bros-pro-runs-the-warm-up-fashion-night-tickets-636145427097">
                        <button
                        style={{fontSize:'1.2rem',padding:'1.2rem',background:'#111',color:'#eee',borderRadius:'2rem'}}>Get Tickets Now</button>
                    </a>   
                </div>
            </div>
         
        </StyledProRun>
        
 <FormSpree formID="mzbqlqop"/> 
 <Jtest />
        <Events />
        <NewNews />
    {/* <img style={{width:'100vw',maxWidth:'1000px',margin:'auto'}} src="https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F522127049%2F325827378087%2F1%2Foriginal.20230524-225030?w=940&auto=format%2Ccompress&q=75&sharp=10&rect=26%2C31%2C1396%2C698&s=531ae26b6eb17affb2a37fd93a9f646d" alt="" /> */}
<Invite />  
        </>
    )
}

export default ProRun
