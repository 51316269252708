import React ,{ useState, useEffect } from 'react'
import styled from 'styled-components';

const StyledRayList = styled.div`
  background-color: lightblue;
  color: black;
`;

const RayList = () => {
const [rayList, setRayList] = useState()

useEffect(() => {
    fetch('https://sheetdb.io/api/v1/7pax586owcf4z')
    .then(res => res.json())
    .then( data => {setRayList(data)})
    .catch(err => console.log(err.message))
}, [])


  return (
    <StyledRayList>
        <ul>
            {rayList?.map((rayListItem,index)=>(
            <li>{index+1}-
            <h4>
                {rayListItem.name}-&nbsp;{rayListItem.email}
                <br />
                {rayListItem.phoneNumber}-&nbsp;{rayListItem.zipcode}
                <br />
                {rayListItem.message} -&nbsp;{rayListItem.selectedArea}
            </h4>
            <br />
            </li>
                ))}
        </ul>
    </StyledRayList>
  )
}

export default RayList