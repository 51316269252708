import React, { useState } from 'react'
import styled from 'styled-components';

const StyledCanHome = styled.div`
  
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

  margin: 0;
  padding: 0;
  box-sizing: border-box;


.body {
  height: 100%;
  display: grid;
  padding-top:6.7em;
  place-items: center;
}

.progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 3em 3em;
  gap: 4rem;
  font: 0.7rem 'myriad pro',helvetica,sans-serif;
}

.progress-list {
  display: flex;
  list-style-type: none;
  gap: 4.25rem;
}

.progress-item {
  width: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

.progress-indicator {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  border: 0.15rem solid rgba(0,0,0,0.25);
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-bottom: 0.5rem;
  color: rgba(0,0,0,0.5);
  font-weight: 900;
}

.progress-indicator::before {
  content: '';
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  z-index: -1;
}

.progress-indicator::after {
  content: '';
  position: absolute;
  left: 3.4rem;
  width: 3.5rem;
  height: 0.18rem;
  background-color: rgba(0,0,0,0.25);
}

.progress-item:last-of-type .progress-indicator::after { display: none; }

.progress-info {
  font-weight: 600;
  font-size: 0.8rem;
}

.progress-status { 
  color: rgba(0,0,0,0.5); 
  font-style: italic; 
}

.progress-interface { user-select: none; }

.progress-btn {
  background-color: transparent;
  border: none;
  font-size: 1.25rem;
  margin: 0.75rem;
  cursor: pointer;
}

.active .progress-indicator {
  border-color: black;
  color: white;
}

.active .progress-indicator::before  {
  border-color: black;
  background-color: black;
}

.active .progress-indicator::after  { background-color: black; }
.active .progress-status            { color: rgba(0,0,0,0.75); }

.section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
}
form {
    display: flex;
    width: 67vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
   
}
form > input {
    width: 80%;
    height: 3.67em;
}


/* [data-progress-current='1'] {
  background: lightgreen;
} */

.progress-indicator {
    background: transparent;
}

.light-green{
    background: lightgreen;
}
/* [data-progress-current='3']{
    background: lightgreen;
} */
`;


const CanHome = () => {
    const [progressActive, setProgressActive] = useState(1)
  return (
    <StyledCanHome>
        <div className="body">

   

        <main className='progress'>
            <div className="section-container">

            {/* 
            
            data-progress-current="3" 
            
            */}
  <ul className='progress-list'>
    <li className='progress-item'>
        {/* if state of progressActive is 1
         it willl light green 
         ..if bigger than one, it stays lit ( assuming the user succeeded and is continuing down the steps */}
      <div className={
      progressActive >= 1 
      ? 'progress-indicator light-green'
      : 'progress-indicator'}>
1</div>
      <p className='progress-info'>Registration</p>
      <p className='progress-status'>In Progress</p>
    </li>
    <li className='progress-item'>
      <div className={
      progressActive >= 2 
      ? 'progress-indicator light-green'
      : 'progress-indicator'}
       >2</div>
      <p className='progress-info'>Courses</p>
      <p className='progress-status'>Pending</p>
    </li>
    <li className='progress-item'>
      <div className={
      progressActive >= 3 
      ? 'progress-indicator light-green'
      : 'progress-indicator'}
       >3</div>
      <p className='progress-info'>Confirmation</p>
      <p className='progress-status'>Pending</p>
    </li>
    <li className='progress-item'>
      <div className={
      progressActive >= 4 
      ? 'progress-indicator light-green'
      : 'progress-indicator'}>4</div>
      <p className='progress-info'>Payment</p>
      <p className='progress-status'>Pending</p>
    </li>
  </ul>
  <h5>{progressActive}</h5>
  <form action="">
    <input type="text" placeholder='full name' />
    <input type="email" placeholder='email' />
    <select name="" id="">
        <option value="new">option1</option>
        <option value="">option2</option>
        <option value="">option3</option>
        <option value="">option4</option>
    </select>
  </form>
  </div>
  <nav className='progress-interface'>
    <ion-icon 
     onClick={()=>{setProgressActive(prevState=>prevState-1)}}
     className='progress-btn prev' name="chevron-back-outline"></ion-icon>
    <ion-icon 
    onClick={()=>{setProgressActive(prevState=>prevState+1)}}
    className='progress-btn next' 
    name="chevron-forward-outline"></ion-icon>
  </nav>
</main>
</div>


    </StyledCanHome>
  )
}

export default CanHome