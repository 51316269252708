import React from 'react'
import styled from 'styled-components'
 
const StyledHabitTracker  = styled.div`
 background: rgb(45,252,253);
background: linear-gradient(0deg, rgba(45,252,253,1) 3%, rgba(238,174,206,1) 32%);



.hidden {
  display: none;
}

.grid {
  display: grid;
  /* margin-top: 3em; */
  /* seven equal parts */
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  
  /* grid-gap: 1em; */
  
}

.cell {
  background-color: #dfdfdf;
  width: 38px;
  height: 30px;
  border: 3px solid rgba(245,245,245,0.2);
   -moz-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
  -moz-transition: background-color 300ms ease-in;
  -webkit-transition: background-color 300ms ease-in;
}


.cell:hover {
  cursor: pointer;
  background-color: #007777;
  -moz-box-shadow: inset 0 2px 3px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: inset 0 2px 3px 0px rgba(0, 0, 0, 0.16);
  box-shadow: inset 0 2px 3px 0px rgba(0, 0, 0, 0.16);
}

input:checked + .cell {
  background-color: #00aaaa;
  -moz-box-shadow: inset 0 0px 3px 1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0px 3px 1px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0px 3px 1px rgba(0, 0, 0, 0.5);
}

input:checked + .cell:hover {
  background-color: #007777;
}
.all-container {
  display: flex;
  margin-left: 5%;
}
.test {
  min-width: 20vw;
  width: 20vw;
  height: auto;
  background: #fefefe;
  border: 3px solid rgba(245,245,245,0.2);
}
.space {
  height: 8em;
}
 #days {
  margin-left: calc(20vw + 5%);
 }
 #days li {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: flex-end;
  background: transparent;
  border: none;
  user-select: none;
  cursor: default;
  box-shadow: none;
  color: #222;
  font-weight: bold;
 }
 #days li:hover {
  background-color: transparent;
  border: none;
  box-shadow: none;
 }


 @media screen and (min-width:500px){
  .cell {
  background-color: #dfdfdf;
  width: 5em;
  height: 5em;
  border: 3px solid rgba(245,245,245,0.2);
  -moz-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
  -moz-transition: background-color 300ms ease-in;
  -webkit-transition: background-color 300ms ease-in;
}
}
@media screen and (max-width:570px){
  .all-container {
  margin-left: 0;
}
.test {
  font-size: 12px;
}
}
`;
 
const HabitTracker = () => {
return (
<StyledHabitTracker>
  <div className="space"></div>
<h1 style={{margin:'auto',minWidth:'32vw',width:'32vw',textAlign:'center',color:'#fff',background:'rgba(24,24,24,0.02)'}}>21 day whole <br />
HABIT TRACKER</h1>
    <div id='days' className="grid">
      <ul style={{display:'flex'}}>
    <li className="cell">M</li>
    <li className="cell">T</li>
    <li className="cell">W</li>
    <li className="cell">TH</li>
    <li className="cell">F</li>
    <li className="cell">S</li>
    <li className="cell">Sun</li>
    </ul>
    </div>
  <div className="all-container">
    <div className="test">follow a healthy eating plan</div>
<div class="grid">
  <input type="checkbox" id="cell1" class="hidden"/>
  <label for="cell1" class="cell"></label>
  <input type="checkbox" id="cell2" class="hidden"/>
  <label for="cell2" class="cell"></label>
  <input type="checkbox" id="cell3" class="hidden"/>
  <label for="cell3" class="cell"></label>
  <input type="checkbox" id="cell4" class="hidden"/>
  <label for="cell4" class="cell"></label>
  <input type="checkbox" id="cell5" class="hidden"/>
  <label for="cell5" class="cell"></label>
  <input type="checkbox" id="cell6" class="hidden"/>
  <label for="cell6" class="cell"></label>
  <input type="checkbox" id="cell7" class="hidden"/>
  <label for="cell7" class="cell"></label>
 
</div>
  </div>{/* end all container */}
  <div className="all-container">
    <div className="test">jfjfj</div>
<div class="grid">

  <input type="checkbox" id="cell8" class="hidden"/>
  <label for="cell8" class="cell"></label>
  <input type="checkbox" id="cell9" class="hidden"/>
  <label for="cell9" class="cell"></label>
  <input type="checkbox" id="cell10" class="hidden"/>
  <label for="cell10" class="cell"></label>
  <input type="checkbox" id="cell11" class="hidden"/>
  <label for="cell11" class="cell"></label>
  <input type="checkbox" id="cell12" class="hidden"/>
  <label for="cell12" class="cell"></label>
  <input type="checkbox" id="cell13" class="hidden"/>
  <label for="cell13" class="cell"></label>
  <input type="checkbox" id="cell14" class="hidden"/>
  <label for="cell14" class="cell"></label>

 
</div>
  </div>{/* end all container */}
</StyledHabitTracker>
)
}
 
export default HabitTracker