import React from 'react'
import styled from 'styled-components'
 
const StyledComingSoon = styled.div`
 
`;
 
const ComingSoon = () => {
    return (
        <StyledComingSoon>
            <br />  <br />
             <section>
  <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 sm:py-12 lg:px-8">
    <header className="text-center">
      <h2 className="text-xl font-bold text-gray-200 sm:text-3xl">
       Coming Soon
      </h2>

     
    </header>

    <ul className="grid grid-cols-1 gap-4 mt-8 lg:grid-cols-3">
      <li>
        <a href="#" className="relative block group">
          <img
            src="https://dl3.pushbulletusercontent.com/1YueTpUkl002Qa2F3q8DGVqgVRIaj9S4/denardlogo-rmbg-1.png"
            alt=""
            className="object-cover w-full transition duration-500 aspect-square group-hover:opacity-90"
          />

          <div
            className="absolute inset-0 flex flex-col items-start justify-end p-6"
          >
           

        
          </div>
        </a>
      </li>

   
    </ul>
  </div>
</section>
        </StyledComingSoon>
    )
}
 
export default ComingSoon
 