import React from 'react'
import styled from 'styled-components'
 
const StyledTest123 = styled.div`
 box-sizing: border-box;

 .body {
  margin: 0;

  font-size: 1.3125rem;
  line-height: 1.6;
  color: #222C2A;
}
h1, h2 {
  margin-top: 0;

}

h1 {
  font-size: 5rem;
  color: #824936;
}

h2 {
  font-size: 2.25rem;
}

img {
  width: 100%;
}

.container {
  max-width: 1128px;
  width: 80%;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.row {
  display: flex;
  justify-content: space-between;
}

.col {
  width: 48%;
}

.center {
  align-items: center;
  justify-content: center;
}

.hero {
  
  background-image: url('https://dl3.pushbulletusercontent.com/TIYrxxS7EBitrObarUiGumsWDXCZBRSF/image.png');
  background-size: cover;
  text-align: center;
  min-height: 660px;
}

.hero__text {
  width: 90%;
}

.hero__upper {
  font-size: 1.125rem;
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 1em;
  margin: 0;
}

.hero__lower {
  margin: 0;
}

.top {
  padding: 5em 0;
}

.top__title {
  text-align: center;
  color: #824936;
}

.dark {
  background-color: #222C2A;
  color: white;
}

.brown {
  background-color: #824936;
  color: white;
}

.full-width {
  padding: 5em 0;
}

.row-reverse {
  flex-direction: row-reverse;
}
.wrapper {
  display: block;
}
.mobile-img-wrapper {
  display: none;
}
@media (max-width: 800px) {
  .mobile-img-wrapper {
    display: block;
  }
  .wrapper{
    display: none;
  }
}
@media (max-width: 670px) {
  .hero {
    min-height: 500px;
  }
  .hero__upper {
    font-size: 0.75rem;
  }
  .hero__lower {
    font-size: 3rem;
  }
  .row {
    flex-direction: column;
  }
  .col {
    width: 100%;
  }
}






@import url("https://fonts.googleapis.com/css2?family=Courier+Prime&family=Abril+Fatface&family=Rammetto+One&display=swap");
* {
  box-sizing: border-box;
}

html {
  background-color: #000;
}

.wrapper {
  position: relative;
  height: 100vh;
  background-image: url('https://dl3.pushbulletusercontent.com/TIYrxxS7EBitrObarUiGumsWDXCZBRSF/image.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  color: #222;
  text-align: center;
  font-family: "Courier Prime", monospace;
}

.title {
  max-width: 67vw;
  position: absolute;
  bottom: 15%;
  left: 10%;
  font-size: 80px;
  /* font-family: "Abril Fatface", cursive; */
  text-transform: uppercase;
  color: white;
  text-shadow: 7px 4px 1px rgba(0,0,0,0.9);
  background: rgba(245,0,0,0.49);
}
.title:before, .title:after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  overflow: hidden;
}
.title:before {
  top: -30%;
  height: 50%;
  opacity: 0.8;
}
.title:after {
  top: -50%;
  height: 40%;
  opacity: 0.5;
}

.subtitle {
  position: absolute;
  right: 5%;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 80px;
  color: #222C2A;
  /* font-family: "Rammetto One", cursive; */
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
.subtitle:before {
  content: attr(data-text);
  position: absolute;
  right: 70%;
  overflow: hidden;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  transform: rotate(180deg);
}

.border-box {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  margin: auto;
  border: 2px solid;
}

.x-text, .y-text {
  position: absolute;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
}
.x-text:before, .x-text:after, .y-text:before, .y-text:after {
  content: attr(data-text);
}

.x-text {
  left: 10px;
  right: 10px;
}
.x-text.top {
  top: 10px;
}
.x-text.bottom {
  bottom: 10px;
  transform: rotate(180deg);
}

.y-text {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  top: 40px;
  bottom: 40px;
}
.y-text.left {
  left: 10px;
  transform: rotate(180deg);
}
.y-text.right {
  right: 10px;
  
}


`;

const Test123 = ({displayStatus}) => {
  return (
    <StyledTest123>
            <div className="body" style={{display:`${displayStatus}`}}>
    <div className="mobile-img-wrapper">
      <img src="https://firemixtapesapparel.store/images/main-p-800.jpeg" alt="" />
    </div>
                
<div className="wrapper">
  <div className="content-fitter" style={{width:'100%',height:'100%',objectFit:'fill'}}>
    <img style={{maxHeight:'100vh',opacity:'0.16'}} src="https://i.gifer.com/origin/68/6830d60058c57bd08deb24573c4ecda4_w200.gif" alt="" />
  </div>
  <div className="title" data-text="Fire Mixtapes🔥">Fire Mixtapes🔥</div>
  <div className="subtitle" data-text="Get Exposure">Get Exposure</div>
  <div className="border-box">
    {/* <div className="x-text top" data-text="Fire Mixtapes🔥.......">Fire Mixtapes🔥.......Fire Mixtapes🔥........</div> */}
    <div className="x-text bottom" data-text="Fire Mixtapes🔥........">Fire Mixtapes🔥.......</div>
    <div className="y-text left" data-text="Fire Mixtapes.......">Fire Mixtapes........</div>
    <div className="y-text right" data-text="Fire Mixtapes.......">Fire Mixtapes.......</div>
  </div>
</div>
 
<main>
  
  <section className="full-width" style={{background: '#999'}}>
    <div className="container">
      <div className="row">
        <div className="col">
          <h2>Showcase your talent. Get Recognition</h2>
          <p>Fire Mixtapes🔥 has a combined total of over 500k followers. Eddie Denard and his Brother Aubery put together content and mixtapes that are fire. If you have the skills on the court and want to take it somewhere you need to get featured on FireMixtapes Channel. Get a custom Mixtape Created for you handsfree, get posted on @firemixtapes and get exposure. </p>
        </div>
        <div className="col image">
          <img src="https://picsum.photos/400/250" alt="a random picture" />
        </div>
      </div>
    </div>
  </section>
  </main>
  </div>
        </StyledTest123>
    )
}
 
export default Test123
 