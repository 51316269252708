import React from 'react'
import styled from 'styled-components'
 
const StyledTwoSideLogin  = styled.div`
 .logogrid {
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.logogrid__item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
}
.logogrid__img {
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 120px;
  max-height: 60px;
  filter: grayscale(100%) contrast(25%);
  transition: filter 0.3s ease;
}
.logogrid__img:hover {
  filter: grayscale(0) contrast(100%);
}

.body {
  display: flex;
  flex-direction: column;
  justify-items: center;
  min-height: 100vh;
}

.container {
  width: 90%;
  max-width: 1000px;
  margin: auto;
}
`;
 
const TwoSideLogin = () => {
return (
<StyledTwoSideLogin>
    <div className="body">

<div class="container">

  <ul class="logogrid">
    <li class="logogrid__item">
      <img src="https://upload.wikimedia.org/wikipedia/commons/c/ce/Coca-Cola_logo.svg" class="logogrid__img" alt="Coca Cola"/>
    </li>
    <li class="logogrid__item">
      <img src="https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg" class="logogrid__img" alt="Google"/>
    </li>
    <li class="logogrid__item">
      <img src="https://upload.wikimedia.org/wikipedia/commons/6/69/Airbnb_Logo_B%C3%A9lo.svg" class="logogrid__img" alt="AirBnB"/>
    </li>
    <li class="logogrid__item">
      <img src="https://upload.wikimedia.org/wikipedia/commons/2/26/Spotify_logo_with_text.svg" class="logogrid__img" alt="Spotify"/>
    </li>
    <li class="logogrid__item">
      <img src="https://upload.wikimedia.org/wikipedia/de/f/f9/Guinness_Logo.svg" class="logogrid__img" alt="Guinness"/>
    </li>
    <li class="logogrid__item">
      <img src="https://upload.wikimedia.org/wikipedia/commons/9/92/Audi-Logo_2016.svg" class="logogrid__img" alt="Audi"/>
    </li>
    <li class="logogrid__item">
      <img src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Logo_NIKE.svg" class="logogrid__img" alt="Nike"/>
    </li>
    <li class="logogrid__item">
      <img src="https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg" class="logogrid__img" alt="Netflix"/>
    </li>
  </ul>

</div>
    </div>
</StyledTwoSideLogin>
)
}
 
export default TwoSideLogin