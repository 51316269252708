import React from 'react'
import styled from 'styled-components';

const StyledShoppy = styled.div`
  * {
  font-family: "Crimson Text", "Noto Sans TC";
  box-sizing: border-box;
}

html, body {
  background-color: #FFF;
  transition: 0.5;
  overflow-x: hidden;
}

header#header_index {
  margin-bottom: 0px;
  background-image: url(https://i.imgur.com/TbUlMym.jpg);
  background-size: cover;
  background-position: center center;
  padding-top: 20%;
  padding-bottom: 20%;
  min-width: 400px;
}
header#header_index .lovelogo {
  width: 30%;
  margin-left: 240px;
  min-width: 180px;
}

section#section_topics .container {
  padding: 80px;
  padding-bottom: 0px;
  text-align: center;
}
section#section_topics .container .block {
  display: inline-block;
  width: 140px;
  height: 30px;
  letter-spacing: 2px;
  background-color: #464646;
}
section#section_topics .container .block .h4 {
  margin-top: -3px;
  color: #FFF;
  font-family: Noto Sans TC;
  font-weight: 400;
  font-size: 28px;
  text-align: top-line;
}
section#section_topics .container .p {
  letter-spacing: 2px;
  padding: 5px;
  font-size: 14px;
}
section#section_topics .container.topics hr.one {
  margin-top: -50px;
  border-bottom: solid 2px #464646;
}
section#section_topics .container.topics news {
  display: flex;
  justify-content: center;
}
section#section_topics .container.topics news days {
  padding-top: 10px;
}
section#section_topics .container.topics news a {
  color: initial;
  text-decoration: initial;
  transition: 0.5s;
}
section#section_topics .container.topics news a:hover {
  opacity: 0.5;
}
section#section_topics .container.topics news a p {
  padding-top: 10px;
  padding-left: 30px;
}
section#section_topics .container.topics hr {
  border-bottom: solid 2px #464646;
}

section#section_story .container {
  padding: 80px;
  text-align: center;
}
section#section_story .container .block {
  display: inline-block;
  width: 140px;
  height: 30px;
  letter-spacing: 2px;
  background-color: #464646;
}
section#section_story .container .block .h4 {
  margin-top: -3px;
  color: #FFF;
  font-family: Noto Sans TC;
  font-weight: 400;
  font-size: 28px;
  text-align: top-line;
}
section#section_story .container .p {
  letter-spacing: 2px;
  padding: 5px;
  font-size: 14px;
}
section#section_story .pic1 {
  margin-bottom: 0px;
  background-image: url(https://i.imgur.com/MgFPLvX.jpg);
  background-size: cover;
  background-position: center center;
  padding-top: 15%;
  padding-bottom: 15%;
  min-width: 400px;
}

section#section_story1 .container-fluid .col-md-2.w {
  background-color: #FFF;
}
section#section_story1 .container-fluid .col-md-2.g {
  background-color: #969696;
}
section#section_story1 .container-fluid .col-md-4.g {
  background-color: #969696;
  padding: 30px;
}
section#section_story1 .container-fluid .col-md-4.g hr {
  border-bottom: solid 3px #FFF;
  display: inline-block;
  width: 20px;
}
section#section_story1 .container-fluid .col-md-4.g p {
  color: #FFF;
  margin-top: -15px;
  width: 100%;
  letter-spacing: 2px;
  line-height: 24px;
  font-size: 14px;
}
section#section_story1 .container-fluid .col-md-4.w {
  padding: 90px 30px 30px 30px;
}
section#section_story1 .container-fluid .col-md-4.w hr {
  border-bottom: solid 3px #464646;
  display: inline-block;
  width: 20px;
}
section#section_story1 .container-fluid .col-md-4.w p {
  color: #464646;
  font-size: 13px;
  margin-top: -15px;
  width: 100%;
  margin-right: 20px;
  letter-spacing: 2px;
  line-height: 30px;
}
section#section_story1 .row.w {
  padding-bottom: 120px;
}

section#section_earth .container-fluid {
  padding: 0;
  margin: 0;
}
section#section_earth .container-fluid .col-md-1.a {
  background-color: #E4E4E4;
}
section#section_earth .container-fluid .col-md-6.lg {
  background-color: #E4E4E4;
  padding: 30px;
  position: relative;
  text-align: center;
}
section#section_earth .container-fluid .col-md-6.lg .loveearth {
  text-align: center;
  color: #464646;
}
section#section_earth .container-fluid .col-md-6.lg .loveearth p {
  padding-top: 20px;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 2px;
}
section#section_earth .container-fluid .col-md-6.lg .loveearth .earth .earthtext h4 {
  padding-top: 50px;
}
section#section_earth .container-fluid .col-md-6.lg .loveearth .earth .earthtext p {
  font-family: Noto Sans TC;
  font-size: 15px;
  letter-spacing: 2px;
}
section#section_earth .container-fluid .col-md-6.lg .loveearth .circle {
  display: inline-block;
}
section#section_earth .container-fluid .col-md-6.lg .loveearth .circle .c2, section#section_earth .container-fluid .col-md-6.lg .loveearth .circle .c3 {
  margin-left: -30px;
}
section#section_earth .container-fluid .col-md-6.lg .loveearth .circle .cir {
  position: relative;
  text-align: center;
  width: 160px;
  height: 160px;
  border-radius: 100%;
  border: solid 1px #464646;
  display: inline-block;
}
section#section_earth .container-fluid .col-md-6.lg .loveearth .circle .cir p {
  position: relative;
  top: 40px;
}
section#section_earth .container-fluid .col-md-4.ptree {
  height: 500px;
  background-image: url(https://i.imgur.com/o4wOHzi.png);
  background-size: cover;
  background-position: center center;
}

section#section_three .container {
  padding: 60px 10px 70px 10px;
  text-align: center;
}
section#section_three .container h3 {
  padding: 40px 10px 0px 10px;
}
section#section_three .container h3 .title2 {
  font-size: 50px;
  font-style: italic;
}
section#section_three .container hr {
  margin-top: -10px;
  border-bottom: solid 2px;
  width: 390px;
}
section#section_three .container .loveair {
  margin: 10px;
  padding: 5px 30px;
  font-family: Noto Sans TC;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  border: solid 2px #464646;
}
section#section_three .container h6 {
  font-size: 17px;
  letter-spacing: 2px;
}
section#section_three .container p.e {
  padding: 0px;
  margin-top: -5px;
}
section#section_three .container p {
  font-family: Noto Sans TC;
  font-size: 13px;
  letter-spacing: 2px;
  padding: 0px 15px 30px 15px;
}

section#section_pic2 .col-md-12 {
  background-image: url(https://i.imgur.com/MajxUcY.jpg);
  padding-top: 15%;
  padding-bottom: 15%;
  min-width: 400px;
  background-size: cover;
  background-position: center center;
}

section#section_lineup .container, section#section_misslineup .container {
  padding-top: 80px;
  padding-bottom: 50px;
  text-align: center;
}
section#section_lineup .container .row .col-md-12 .block, section#section_misslineup .container .row .col-md-12 .block {
  display: inline-block;
  width: 160px;
  height: 30px;
  letter-spacing: 2px;
  background-color: #464646;
}
section#section_lineup .container .row .col-md-12 .block .h4, section#section_misslineup .container .row .col-md-12 .block .h4 {
  margin-top: -3px;
  color: #FFF;
  font-family: Noto Sans TC;
  font-weight: 400;
  font-size: 28px;
  text-align: top-line;
}
section#section_lineup .container .row, section#section_misslineup .container .row {
  margin-top: 50px;
}
section#section_lineup .container .row .col-md-4.sh1 .shampoo.thing1, section#section_misslineup .container .row .col-md-4.sh1 .shampoo.thing1 {
  position: relative;
  display: inline-block;
  margin: auto;
  background-image: url(https://i.imgur.com/5hplMte.png);
  width: 260px;
  height: 280px;
  background-size: cover;
  background-position: center center;
  margin: 10px;
}
section#section_lineup .container .row .col-md-4.sh2 .shampoo.thing2, section#section_misslineup .container .row .col-md-4.sh2 .shampoo.thing2 {
  position: relative;
  display: inline-block;
  margin: auto;
  background-image: url(https://i.imgur.com/CCKeqSz.png);
  width: 260px;
  height: 280px;
  background-size: cover;
  background-position: center center;
  margin: 10px;
}
section#section_lineup .container .row .col-md-4.sh3 .shampoo.thing3, section#section_misslineup .container .row .col-md-4.sh3 .shampoo.thing3 {
  position: relative;
  display: inline-block;
  margin: auto;
  background-image: url(https://i.imgur.com/TX0ttzu.png);
  width: 260px;
  height: 280px;
  background-size: cover;
  background-position: center center;
  margin: 10px;
}
section#section_lineup .container .row .col-md-4.sh4 .shampoo.thing4, section#section_misslineup .container .row .col-md-4.sh4 .shampoo.thing4 {
  position: relative;
  display: inline-block;
  margin: auto;
  background-image: url(https://i.imgur.com/SBQl0oj.png);
  width: 260px;
  height: 280px;
  background-size: cover;
  background-position: center center;
  margin: 10px;
}
section#section_lineup .container .row .col-md-8.in, section#section_misslineup .container .row .col-md-8.in {
  padding-left: 20px;
  text-align: left;
}
section#section_lineup .container .row .col-md-8.in h2, section#section_misslineup .container .row .col-md-8.in h2 {
  padding-top: 20px;
  font-style: italic;
  font-size: 45px;
  color: #965F36;
  letter-spacing: 2px;
}
section#section_lineup .container .row .col-md-8.in h4, section#section_misslineup .container .row .col-md-8.in h4 {
  color: #464646;
  font-size: 20px;
  letter-spacing: 2px;
}
section#section_lineup .container .row .col-md-8.in h2.sha, section#section_misslineup .container .row .col-md-8.in h2.sha {
  padding-top: 20px;
  font-style: italic;
  font-size: 45px;
  color: #B0B000;
  letter-spacing: 2px;
}
section#section_lineup .container .row .col-md-8.in h2.body, section#section_misslineup .container .row .col-md-8.in h2.body {
  padding-top: 20px;
  font-style: italic;
  font-size: 45px;
  color: #676E9F;
  letter-spacing: 2px;
}
section#section_lineup .container .row .col-md-8.in h2.hair, section#section_misslineup .container .row .col-md-8.in h2.hair {
  padding-top: 20px;
  font-style: italic;
  font-size: 45px;
  color: #A9989D;
  letter-spacing: 2px;
}
section#section_lineup .container .row .col-md-8.in h5, section#section_misslineup .container .row .col-md-8.in h5 {
  color: #464646;
  font-size: 13px;
  letter-spacing: 2px;
}
section#section_lineup .container .row .col-md-8.in h5.s, section#section_misslineup .container .row .col-md-8.in h5.s {
  margin-top: -5px;
  color: #464646;
  font-size: 13px;
  letter-spacing: 2px;
}
section#section_lineup .container .row .col-md-8.in p, section#section_misslineup .container .row .col-md-8.in p {
  color: #464646;
  font-size: 12px;
  letter-spacing: 2px;
}
section#section_lineup .container .row .col-md-8.in .in, section#section_misslineup .container .row .col-md-8.in .in {
  margin-top: -15px;
}
section#section_lineup .container .row .col-md-8.in .ein, section#section_misslineup .container .row .col-md-8.in .ein {
  margin-top: -15px;
  font-family: Noto Sans TC;
  font-size: 8px;
}
section#section_lineup .container .row .col-md-8.in p.money, section#section_misslineup .container .row .col-md-8.in p.money {
  margin-top: -15px;
  font-family: Noto Sans TC;
  font-size: 15px;
  font-weight: 600;
}
section#section_lineup .container .row .col-md-8.in .btnaa, section#section_misslineup .container .row .col-md-8.in .btnaa {
  color: #464646;
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  padding: 5px 30px;
  border-radius: 3px;
  letter-spacing: 2px;
  margin-bottom: 5px;
  background-color: #E4E4E4;
  border: solid 2px #969696;
  cursor: pointer;
  text-decoration: none;
  transition: 0.5s;
}
section#section_lineup .container .row .col-md-8.in .btnaa:hover, section#section_misslineup .container .row .col-md-8.in .btnaa:hover {
  opacity: 0.5;
}
section#section_lineup .container .row .col-md-8.in .buy, section#section_misslineup .container .row .col-md-8.in .buy {
  color: #464646;
  background-color: #E4E4E4;
  border: solid 2px #969696;
  margin-bottom: 50px;
  display: inline-block;
  font-size: 14px;
  padding: 5px 30px;
  border-radius: 3px;
  letter-spacing: 2px;
}
section#section_lineup .container .row .col-md-8.in a, section#section_misslineup .container .row .col-md-8.in a {
  cursor: pointer;
  text-decoration: none;
  transition: 0.5s;
}
section#section_lineup .container .row .col-md-8.in a:hover, section#section_misslineup .container .row .col-md-8.in a:hover {
  opacity: 0.5;
}

section#section_missstory .container {
  padding: 80px;
  text-align: center;
}
section#section_missstory .container .block {
  display: inline-block;
  width: 140px;
  height: 30px;
  letter-spacing: 2px;
  background-color: #464646;
}
section#section_missstory .container .block .h4 {
  margin-top: -3px;
  color: #FFF;
  font-family: Noto Sans TC;
  font-weight: 400;
  font-size: 28px;
  text-align: top-line;
}
section#section_missstory .container .p {
  letter-spacing: 2px;
  padding: 5px;
  font-size: 14px;
}
section#section_missstory .picdog {
  margin-bottom: 0px;
  background-image: url(https://i.imgur.com/I8hWTh5.jpg);
  background-size: cover;
  background-position: center center;
  padding-top: 20%;
  padding-bottom: 20%;
  min-width: 400px;
}

section#section_missmoonstory .container-fluid .col-md-3.d {
  background-color: #969696;
}
section#section_missmoonstory .container-fluid .col-md-7.d {
  background-color: #969696;
  padding: 30px 40px 50px 40px;
}
section#section_missmoonstory .container-fluid .col-md-7.d hr {
  border-bottom: solid 3px #FFF;
  display: inline-block;
  width: 20px;
}
section#section_missmoonstory .container-fluid .col-md-7.d p {
  font-family: Noto Sans TC;
  color: #FFF;
  margin-top: -15px;
  width: 100%;
  letter-spacing: 2px;
  line-height: 30px;
  font-size: 13px;
  font-weight: 300;
}

section#section_misslineup .container {
  padding-top: 80px;
  padding-bottom: 50px;
  text-align: center;
}
section#section_misslineup .container .row .col-md-12 .block {
  display: inline-block;
  width: 160px;
  height: 30px;
  letter-spacing: 2px;
  background-color: #464646;
}
section#section_misslineup .container .row .col-md-12 .block .h4 {
  margin-top: -3px;
  color: #FFF;
  font-family: Noto Sans TC;
  font-weight: 400;
  font-size: 28px;
  text-align: top-line;
}
section#section_misslineup .container .row {
  margin-top: 50px;
  margin-bottom: 30px;
}
section#section_misslineup .container .row .col-md-4.sh1 .shampoo.thing5 {
  position: relative;
  display: inline-block;
  margin: auto;
  background-image: url(https://i.imgur.com/Qlo0Q1M.png);
  width: 260px;
  height: 280px;
  background-size: cover;
  background-position: center center;
  margin: 10px;
}
section#section_misslineup .container .row .col-md-8.in h4 {
  font-family: Noto Sans TC;
}
section#section_misslineup .container .row .col-md-8.in h2.pink {
  color: #F4D3D6;
}

section#section_say .container-fluid .row .col-md-6 {
  background-color: #E4E4E4;
  padding-top: 10%;
  padding-bottom: 10%;
}
section#section_say .container-fluid .row .col-md-6 .row {
  margin-top: -20px;
}
section#section_say .container-fluid .row .col-md-6 .row .col-md-6.ssay {
  padding: 0px 30px 70px 70px;
  color: #464646;
}
section#section_say .container-fluid .row .col-md-6 .row .col-md-6.ssay hr {
  border-bottom: solid 3px #464646;
  display: inline-block;
  width: 20px;
}
section#section_say .container-fluid .row .col-md-6 .row .col-md-6.ssay .pics {
  position: absolute;
  background-image: url(https://i.imgur.com/5B8eoEr.png);
  background-size: cover;
  background-position: center center;
  width: 190px;
  height: 75px;
  right: 50px;
}
section#section_say .container-fluid .row .col-md-6.pet {
  background-image: url(https://i.imgur.com/W7YrzVm.png);
  background-size: cover;
  background-position: center center;
  min-width: 400px;
}

section#section_ingredients .container {
  padding-top: 80px;
  padding-bottom: 50px;
  text-align: center;
}
section#section_ingredients .container .row .col-md-12 .block {
  display: inline-block;
  width: 240px;
  height: 30px;
  letter-spacing: 2px;
  background-color: #464646;
}
section#section_ingredients .container .row .col-md-12 .block .h4 {
  margin-top: -3px;
  color: #FFF;
  font-family: Noto Sans TC;
  font-weight: 400;
  font-size: 28px;
  text-align: top-line;
}
section#section_ingredients .container .row.in1 {
  margin: 60px 30px 60px 30px;
}
section#section_ingredients .container .row.in1 .col-md-2 a {
  cursor: pointer;
  text-decoration: none;
  transition: 0.5s;
}
section#section_ingredients .container .row.in1 .col-md-2 a:hover p {
  color: #969696;
}
section#section_ingredients .container .row.in1 .col-md-2 img {
  width: 100%;
}
section#section_ingredients .container .row.in1 .col-md-2 p {
  color: #464646;
  font-size: 18px;
}
section#section_ingredients .container .row.in1 .col-md-2 p.eng1 {
  margin-top: -15px;
}
section#section_ingredients .container .row.in2 {
  margin: 60px 30px 60px 30px;
}
section#section_ingredients .container .row.in2 .col-md-2 a {
  cursor: pointer;
  text-decoration: none;
  transition: 0.5s;
}
section#section_ingredients .container .row.in2 .col-md-2 a:hover p {
  color: #969696;
}
section#section_ingredients .container .row.in2 .col-md-2 img {
  width: 100%;
}
section#section_ingredients .container .row.in2 .col-md-2 p {
  color: #464646;
  font-size: 18px;
}
section#section_ingredients .container .row.in2 .col-md-2 p.eng1 {
  margin-top: -15px;
}

section#section_sgs .container {
  padding-top: 80px;
  padding-bottom: 50px;
  text-align: center;
}
section#section_sgs .container .row a {
  cursor: pointer;
  text-decoration: none;
  transition: 0.5s;
}
section#section_sgs .container .row a:hover {
  opacity: 0.5;
}
section#section_sgs .container .row .col-md-12 {
  margin-bottom: 80px;
}
section#section_sgs .container .row .col-md-12 .block {
  display: inline-block;
  width: 160px;
  height: 30px;
  letter-spacing: 2px;
  background-color: #464646;
}
section#section_sgs .container .row .col-md-12 .block .h4 {
  margin-top: -3px;
  color: #FFF;
  font-family: Noto Sans TC;
  font-weight: 400;
  font-size: 28px;
  text-align: top-line;
}
section#section_sgs .container .row {
  color: #464646;
}
section#section_sgs .container .row .col-md-3 img {
  width: 100%;
  padding: 10px 30px 30px 30px;
}
section#section_sgs .container .row .col-md-9 {
  margin-bottom: 80px;
}
section#section_sgs .container .row .col-md-9 hr {
  border-bottom: solid 1px #464646;
}
section#section_sgs .container .row .col-md-9 p {
  font-size: 25px;
  letter-spacing: 1px;
}
section#section_sgs .container .row .col-md-9 .blocka {
  margin: 10px;
  display: inline-block;
  padding: 5px 15px;
  border: solid 1px #969696;
  border-radius: 3px;
}
section#section_sgs .container .row .col-md-9 .blocka img {
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

section#section_shop .container-fluid .row .col-md-12 {
  background-image: url(https://i.imgur.com/EB85iOo.jpg);
  background-size: cover;
  background-position: center center;
  padding-top: 10%;
  padding-bottom: 10%;
  min-width: 400px;
  text-align: center;
}
section#section_shop .container-fluid .row .col-md-12 h3 {
  font-size: 30px;
  font-family: Noto Sans TC;
  font-weight: 400;
  color: #FFF;
  letter-spacing: 2px;
}
section#section_shop .container-fluid .row .col-md-12 a.p {
  color: #464646;
  margin: 10px;
  display: inline-block;
  padding: 15px 30px;
  border: solid 1px #969696;
  border-radius: 3px;
  background-color: #FFF;
  text-decoration: none;
  transition: 0.5s;
}
section#section_shop .container-fluid .row .col-md-12 a.p:hover {
  opacity: 0.8;
}

fooder#fooder_contact .container {
  padding-top: 100px;
  padding-bottom: 50px;
  text-align: center;
}
fooder#fooder_contact .container .row .col-md-12 {
  margin-bottom: 80px;
}
fooder#fooder_contact .container .row .col-md-12 .block {
  display: inline-block;
  width: 160px;
  height: 30px;
  letter-spacing: 2px;
  background-color: #464646;
}
fooder#fooder_contact .container .row .col-md-12 .block .h4 {
  margin-top: -3px;
  color: #FFF;
  font-family: Noto Sans TC;
  font-weight: 400;
  font-size: 28px;
  text-align: top-line;
}
fooder#fooder_contact .container .row .col-md-2 .piclogo {
  margin-top: 10px;
  margin-bottom: 50px;
  width: 75%;
}
fooder#fooder_contact .container .row .col-md-5 {
  text-align: left;
}
fooder#fooder_contact .container .row .col-md-5 hr {
  margin-left: 10px;
  border-bottom: solid 3px #464646;
  display: inline-block;
  width: 20px;
}
fooder#fooder_contact .container .row .col-md-5 p.con {
  color: #464646;
  font-weight: 600;
}
fooder#fooder_contact .container .row .col-md-5 p {
  margin-left: 10px;
  color: #464646;
  font-family: Noto Sans TC;
  margin-top: -10px;
}
fooder#fooder_contact .container .row .col-md-4 {
  text-align: center;
}
fooder#fooder_contact .container .row .col-md-4 h5 {
  font-family: Noto Sans TC;
  color: #464646;
}
fooder#fooder_contact .container .row .col-md-4 .icon {
  display: inline-block;
}
fooder#fooder_contact .container .row .col-md-4 .icon img {
  padding: 10px;
  width: 25%;
  transition: 0.5s;
}
fooder#fooder_contact .container .row .col-md-4 .icon img:hover {
  opacity: 0.5;
}
fooder#fooder_contact .container .row .col-md-12.foo {
  margin-top: 170px;
  text-align: center;
  margin-bottom: 0px;
}
fooder#fooder_contact .container .row .col-md-12.foo p {
  color: #464646;
  font-family: Noto Sans TC;
}

@media screen and (max-width: 570px) {
  header#header_index {
    padding-top: 35%;
  }
  header#header_index .lovelogo {
    margin-top: -35px;
    padding-bottom: 10px;
    margin-left: 60px;
    min-width: 200px;
  }

  section#section_story1 .col-md-4.w {
    margin-top: -50px;
  }
  section#section_story1 .row.w {
    padding-bottom: 60px;
  }

  section#section_earth p.new {
    padding: 30px;
  }
  section#section_earth .cir.c3 {
    margin-right: -30px;
    margin-top: -30px;
  }
  section#section_earth .cir.c1, section#section_earth .cir.c2 {
    margin-bottom: -20px;
  }

  section#section_say .col-md-6.pet {
    height: 300px;
    width: 100%;
  }
}
`;
const Shoppy = () => {
  return (
    <StyledShoppy>
        
<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
  <div class="container"><a class="navbar-brand" href="#header_index">LOVE YOURSELF</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item"><a class="nav-link" href="#section_topics">TOPICS</a></li>
        <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">STORY<span class="sr-only">(current)</span></a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink"><a class="dropdown-item" href="#section_story">LOVE YOURSELF</a><a class="dropdown-item" href="#section_missstory">MISS MOON</a></div>
        </li>
        <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">LINE UP<span class="sr-only">(current)</span></a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink"><a class="dropdown-item" href="#section_lineup">LOVE YOURSELF</a><a class="dropdown-item" href="#section_misslineup">MISS MOON     </a></div>
        </li>
        <li class="nav-item"><a class="nav-link" href="#section_ingredients">INGREDIENTS   </a></li>
        <li class="nav-item"><a class="nav-link" href="#section_sgs">SGS S.A.</a></li>
        <li class="nav-item"><a class="nav-link" href="#fooder_contact">CONTACT</a></li>
      </ul>
    </div>
  </div>
</nav>
<header id="header_index">
  <div class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-md-12"><img class="lovelogo" src="https://i.imgur.com/LnNCmxJ.png" alt=""/></div>
      </div>
    </div>
  </div>
</header>
<section id="section_topics">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block">
          <div class="h4">TOPICS</div>
        </div>
        <div class="p"> LOVE YOURSELF NEWS </div>
      </div>
    </div>
  </div>
  <div class="container topics">
    <hr class="one"/>
    <news>
      <days>2021.4</days><a href="https://www.facebook.com/122855186225372/posts/254026746441548/?d=n" target="_blank">
        <p>愛自己LOVE YOURSEL台灣首家誠品限定店開幕囉</p></a>
    </news>
    <hr/>
  </div>
</section>
<section id="section_story">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block">
          <div class="h4">STORY</div>
        </div>
        <div class="p">ABOUT LOVE YOURSELF </div>
      </div>
    </div>
  </div>
  <div class="container-fluid pic1" data-bottom-top="background-size: 150%; background-position: 0% 0%;" data-center="background-size: 100%; background-position: 50% 50%;"></div>
</section>
<section id="section_story1"> 
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2 g" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1"> </div>
      <div class="col-md-4 g" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1">
        <hr/>
        <p>Returning to natural by using eco-friendly materials is our philosophy. We help minorities by purchasing materials at a fair price, which protects them from selling off their forests. Plus, all products are naturally brewed.<br/>We love people, we love the world, and we love ourselves. <br/>This is how “LOVE YOURSELF” come about; we hope to pass on our values by appreciating Nature.</p>
      </div>
      <div class="col-md-4 w" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1">
        <hr/>
        <p>「回歸自然質樸，使用天然原料」是我們的理念。產品原料透過公平交易，讓少數民族不需為收入而變賣森林，砍伐樹木，此外產品本身皆為天然釀造製成。先愛己而後愛人，所以「LOVE YOURSELF」誕生了。<br/>希望藉由品牌將這份大自然的「禮悟」用愛傳遞給每個人。     </p>
      </div>
      <div class="col-md-2 w" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1"></div>
    </div>
    <div class="row w"> </div>
  </div>
</section>
<section id="section_earth">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-1 a" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1"></div>
      <div class="col-md-6 lg" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1">
        <div class="loveearth">
          <div class="earth">
            <div class="earthtext">
              <h4>LOVE THE EARTH</h4>
              <p>NATURALLY BREWED | 純天然釀造</p>
            </div>
          </div>
          <div class="circle">  
            <div class="cir c1">
              <p>WATER</p>
            </div>
            <div class="cir c2">
              <p>AIR</p>
            </div>
            <div class="cir c3">
              <p>FOREST</p>
            </div>
          </div>
          <p class="new">堅持不含任何化學成份，不添加任何活性界面劑、起泡劑，讓一切回歸自然，讓皮膚、頭皮獲得新生。 </p>
        </div>
      </div>
      <div class="col-md-4 ptree" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1"></div>
      <div class="col-md-1" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1"></div>
    </div>
  </div>
</section>
<section id="section_three">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3>LOVE YOURSELF     <span class="title2"> 3</span><span> 大宣言</span></h3>
        <hr/>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">   
        <div class="loveair">LOVE AIR</div>
        <h6>低碳排</h6>
        <p class="e">Low-Carbon emissions</p>
        <p>全製作過程中無使⽤機器加⼯！製作過程邀請清邁原住民⾄森林進⾏⼈⼯⼿動模式採集野⽣果實，並透過長時間的發酵等待而成，幾近0碳排。</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">   
        <div class="loveair">LOVE FOREST</div>
        <h6>保護原始森林</h6>
        <p class="e">Protect the primeval forest</p>
        <p>不砍伐也不過度開墾森林，所有使⽤之果實、水果皆來⾃原始森林野⽣採集，為保護地球盡一份心力。</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">   
        <div class="loveair">LOVE WATER</div>
        <h6>清洗全身體的同時，⼀併清理下水道</h6>
        <p class="e">Green Consumerism</p>
        <p>天然酵母EM菌，平時⽤在⾃然農法中，防止害蟲的同時，更有助於農作物⽣長。在法國許多內⾏⼈是⽤EM菌清洗下水道，它具有分解髒汙及除臭的效⽤。          </p>
      </div>
    </div>
  </div>
</section>
<section id="section_pic2">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12" data-bottom-top="background-size: 150%; background-position: 0% 0%;" data-center="background-size: 100%; background-position: 50% 50%;"></div>
    </div>
  </div>
</section>
<section id="section_lineup">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block">
          <div class="h4">LINE UP</div>
        </div>
        <div class="p">LOVE YOURSELF SERIES      </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 sh1">
        <div class="shampoo thing1" data-bottom-top="transform: scale(1.5);opacity: 0" data-center="transform: scale(1);opacity: 1"></div>
      </div>
      <div class="col-md-8 in" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1">
        <h2>SHAMPOO</h2>
        <h4>暖香輕盈洗髮乳</h4>
        <h5>調節頭皮機能，滋養髮根健康，重現健康髮絲光采</h5>
        <h5 class="s">(油性/中性適用)</h5>
        <p>[成份]</p>
        <p class="in">野⽣潺槁木姜子、野⽣第倫桃、野⽣箭葉橙、EM菌農場無毒鳳梨、EM菌、野⽣無患子、肉桂、咖啡、山泉水</p>
        <p class="ein">*Litsea glutinosa. *Elephant apple. *Kaffir lime. Pineapple. Effective microorganisms. *Soapnut. Cinnamon. Coffee. Mountain spring water.(*Wildcrafted)</p>
        <p class="money">350ml  $480</p><a class="btnaa" href="https://codepen.io/HsiaoChan/full/abBzqoL" target="_blank">詳細介紹＋</a><a class="buy" href="https://shop.loveyourselfnatural.com.tw/add-to-cart=17" target="_blank">點這裡購買</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 sh2">
        <div class="shampoo thing2" data-bottom-top="transform: scale(1.5);opacity: 0" data-center="transform: scale(1);opacity: 1"></div>
      </div>
      <div class="col-md-8 in" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1">
        <h2 class="sha">SHAMPOO</h2>
        <h4>晨沐溫和洗髮乳</h4>
        <h5>維持染後髮⾊、防止髮絲分叉斷裂，使髮絲健康強韌</h5>
        <h5 class="s">(中性適用)</h5>
        <p>[成份]</p>
        <p class="in">野⽣潺槁⽊姜⼦、野⽣第倫桃、野⽣油⽢、野⽣箭葉橙、EM菌農場無毒鳳梨、EM菌、野⽣無患⼦、蝶⾖花、泰國薑蔘、山泉水</p>
        <p class="ein">*Litsea glutinosa. *Elephant apple. *Amla fruit. *Kaffir lime. Pineapple. Effective microorganisms. *Soapnut. Butterfly pea flower. Cassumunar ginger. Mountain spring water.(*Wildcrafted)</p>
        <p class="money">350ml  $480</p><a class="btnaa" href="https://codepen.io/HsiaoChan/full/abBzqoL" target="_blank">詳細介紹＋</a><a class="buy" href="https://shop.loveyourselfnatural.com.tw/add-to-cart=26" target="_blank">點這裡購買</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 sh3">
        <div class="shampoo thing3" data-bottom-top="transform: scale(1.5);opacity: 0" data-center="transform: scale(1);opacity: 1"></div>
      </div>
      <div class="col-md-8 in" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1">
        <h2 class="body">BODY WASH</h2>
        <h4>恬馨純萃沐浴露</h4>
        <h5>恬靜的薰衣草，搭配廣闊的迷迭香，享受放鬆的芬多精饗宴</h5>
        <h5 class="s">(各種膚質適用)</h5>
        <p>[成份]</p>
        <p class="in">野⽣潺槁⽊姜⼦、野⽣第倫桃、野⽣油⽢、野⽣箭葉橙、EM菌農場無毒鳳梨、EM菌、野⽣無患⼦、迷迭香、薰衣草、山泉水</p>
        <p class="ein">*Litsea glutinosa. *Elephant apple. *Amla fruit. *Kaffir lime. Pineapple. Effective microorganisms. *Soapnut. Rosemary. Lavender. Mountain spring water.(*Wildcrafted)</p>
        <p class="money">350ml  $480</p><a class="btnaa" href="https://codepen.io/HsiaoChan/full/abBzqoL" target="_blank">詳細介紹＋</a><a class="buy" href="https://shop.loveyourselfnatural.com.tw/add-to-cart=27" target="_blank">點這裡購買        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 sh4">
        <div class="shampoo thing4" data-bottom-top="transform: scale(1.5);opacity: 0" data-center="transform: scale(1);opacity: 1"></div>
      </div>
      <div class="col-md-8 in" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1">
        <h2 class="hair">HAIR CONDITIONER</h2>
        <h4>清舒賦活潤髮素</h4>
        <h5>效保濕柔滑，讓髮絲恢復亮澤柔順</h5>
        <h5 class="s">(油性/中性/乾性適用)</h5>
        <p>[成份]</p>
        <p class="in">米糠、野⽣潺槁⽊姜⼦、野⽣第倫桃、野⽣箭葉橙、EM菌、泰國薑蔘、山泉水</p>
        <p class="ein">Rice bran. *Litsea glutinosa. *Elephant apple. *Kaffir lime. Effective microorganisms. Cassumunar ginger. Mountain spring water.(*Wildcrafted)</p>
        <p class="money">350ml  $580</p><a class="btnaa" href="https://codepen.io/HsiaoChan/full/abBzqoL" target="_blank">詳細介紹＋</a><a class="buy" href="https://shop.loveyourselfnatural.com.tw/add-to-cart=29" target="_blank">點這裡購買</a>
      </div>
    </div>
  </div>
</section>
<section id="section_missstory">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block">
          <div class="h4">STORY</div>
        </div>
        <div class="p"> ABOUT MISS MOON </div>
      </div>
    </div>
  </div>
  <div class="container-fluid picdog" data-bottom-top="background-size: 150%; background-position: 0% 0%;" data-center="background-size: 100%; background-position: 50% 50%;"></div>
</section>
<section id="section_missmoonstory">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 d" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1"></div>
      <div class="col-md-7 d" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1"> 
        <hr/>
        <p>“如果不是因為我的狗狗，我不會創立這支品牌”為了陪伴自己多年的愛犬，創辦人這樣說"一直在思考他們短短的狗生當中，我能為他們做什麼？如何讓他們活的更健康?"儘管已經在飲食及環境上努力做到最好，但其中2隻認養的毛孩皮膚過敏、皮屑問題仍然反覆發生，讓我重新思考，是不是有我沒做到的....<br/>於是我希望能夠回歸自然，用最原始、天然的寵物洗劑去照顧他們的皮膚。經過一年不斷的測試，歷經乾冷的冬季及潮濕的夏季，終於調配出最完美的比例。<br/>我們的產品源自天然，無任何化學合成物質添加，原料單純、無負擔，這當中也包含我對毛孩的堅持與承諾，於是「Miss Moon蜜思沐系列」就誕生了….</p>
      </div>
      <div class="col-md-2 d"></div>
    </div>
  </div>
</section>
<section id="section_misslineup">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block">
          <div class="h4">LINE UP</div>
        </div>
        <div class="p"> MISS MOON SERIES </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 sh1">
        <div class="shampoo thing5" data-bottom-top="transform: scale(1.5);opacity: 0" data-center="transform: scale(1);opacity: 1"></div>
      </div>
      <div class="col-md-8 in" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1">
        <h2 class="pink">SHAMPOO</h2>
        <h4>MISS MOON純護潤淨沐浴露(毛寶貝限定)</h4>
        <h5>深層潔淨達到除臭效果，溫和不刺激</h5>
        <h5 class="s">(長毛/短毛適用)</h5>
        <p></p>
        <p class="in">釋迦葉、黑姜、野⽣潺槁⽊姜⼦、野⽣第倫桃、野⽣箭葉橙、EM菌、野生無患子、山泉水</p>
        <p class="ein">Sugar apple leaf. Black ginger. *Litsea glutinosa. *Elephant apple. *Kaffir lime. Effective microorganisms. *Soapnut. Mountain spring water.(*Wildcrafted)</p>
        <p class="money">350ml  $580</p><a class="btnaa" href="https://codepen.io/HsiaoChan/full/abBzqoL" target="_blank">詳細介紹＋</a><a class="buy" href="https://shop.loveyourselfnatural.com.tw/add-to-cart=31" target="_blank"> 點這裡購買    </a>
      </div>
    </div>
  </div>
</section>
<section id="section_say">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6 ssay">
            <hr/>
            <p>把最好的  留給最愛的<br/>安全  天然  環保  安心</p>
            <p class="eng">Just for you<br/>Safe Natural Environmentally friendly Be relieved</p>
            <div class="pics"></div>
          </div>
        </div>
      </div>
      <div class="col-md-6 pet" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1"></div>
    </div>
  </div>
</section>
<section id="section_ingredients">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block">
          <div class="h4">INGREDIENTS</div>
        </div>
        <div class="p"> ABOUT PRODUCT BENEFIT </div>
      </div>
    </div>
    <div class="row in1">
      <div class="col-md-1"></div>
      <div class="col-md-2 lga"><a href="https://codepen.io/HsiaoChan/full/jOVEzba" target="_blank"> <img src="https://i.imgur.com/SVnOIzO.png" alt=""/>
          <p>野⽣潺槁⽊姜⼦</p>
          <p class="eng1">Litsea glutinosa</p></a></div>
      <div class="col-md-2 af"><a href="https://codepen.io/HsiaoChan/full/jOVEzba" target="_blank"> <img src="https://i.imgur.com/623YPqo.png" alt=""/>
          <p>野⽣油⽢</p>
          <p class="eng1">Amla fruit</p></a></div>
      <div class="col-md-2 ea"><a href="https://codepen.io/HsiaoChan/full/jOVEzba" target="_blank"> <img src="https://i.imgur.com/eUibLy0.png" alt=""/>
          <p>野⽣第倫桃</p>
          <p class="eng1">Elephant apple</p></a></div>
      <div class="col-md-2 pa"><a href="https://codepen.io/HsiaoChan/full/jOVEzba" target="_blank"> <img src="https://i.imgur.com/9ZWsLdj.png" alt=""/>
          <p>EM菌無毒鳳梨</p>
          <p class="eng1">Pineapple</p></a></div>
      <div class="col-md-2 ch"><a href="https://codepen.io/HsiaoChan/full/jOVEzba" target="_blank"> <img src="https://i.imgur.com/AFcNJJ8.png" alt=""/>
          <p>野⽣箭葉橙</p>
          <p class="eng1">Citrus hystrix</p></a></div>
      <div class="col-md-1"></div>
    </div>
    <div class="row in2">
      <div class="col-md-1"></div>
      <div class="col-md-2 soa"><a href="https://codepen.io/HsiaoChan/full/jOVEzba" target="_blank"> <img src="https://i.imgur.com/ezD44u5.png" alt=""/>
          <p>野⽣無患⼦</p>
          <p class="eng1">Soapnut</p></a></div>
      <div class="col-md-2 eff"><a href="https://codepen.io/HsiaoChan/full/jOVEzba" target="_blank"> <img src="https://i.imgur.com/Rt5L2XK.png" alt=""/>
          <p>EM菌</p>
          <p class="eng1">Effective microorganisms</p></a></div>
      <div class="col-md-2 rb"><a href="https://codepen.io/HsiaoChan/full/jOVEzba" target="_blank"> <img src="https://i.imgur.com/S0EDnu6.png" alt=""/>
          <p>米糠</p>
          <p class="eng1">Rice bran</p></a></div>
      <div class="col-md-2 sal"><a href="https://codepen.io/HsiaoChan/full/jOVEzba" target="_blank"> <img src="https://i.imgur.com/HjTpOxI.png" alt=""/>
          <p>釋迦葉</p>
          <p class="eng1">Sugar apple leaf</p></a></div>
      <div class="col-md-2 bg"><a href="https://codepen.io/HsiaoChan/full/jOVEzba" target="_blank"> <img src="https://i.imgur.com/ixPjgXD.png" alt=""/>
          <p>⿊姜</p>
          <p class="eng1">Black ginger</p></a></div>
      <div class="col-md-1"></div>
    </div>
  </div>
</section>
<section id="section_sgs">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block">
          <div class="h4">SGS S.A.</div>
        </div>
        <div class="p">ABOUT PRODUCT CERTIFICATION </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3"><a href="https://codepen.io/HsiaoChan/full/OJRaWyj" target="_blank"> <img src="https://i.imgur.com/bicJ5VU.png" alt=""/></a></div>
      <div class="col-md-9">
        <p>產品通過ＳＧＳ檢驗，依據環保標章規範製造，不含以下有害物質。</p>
        <hr/>
        <div class="blocka block1"><img src="https://i.imgur.com/iCO8M4F.png" alt=""/><span class="p">微生物</span></div>
        <div class="blocka block2"><img src="https://i.imgur.com/iCO8M4F.png" alt=""/><span class="p">重金屬</span></div>
        <div class="blocka block3"><img src="https://i.imgur.com/iCO8M4F.png" alt=""/><span class="p">矽靈</span></div>
        <div class="blocka block4"><img src="https://i.imgur.com/iCO8M4F.png" alt=""/><span class="p">界面活性劑</span></div>
        <div class="blocka block5"><img src="https://i.imgur.com/iCO8M4F.png" alt=""/><span class="p">防腐劑</span></div>
        <div class="blocka block6"><img src="https://i.imgur.com/iCO8M4F.png" alt=""/><span class="p">甲醛</span></div>
        <div class="blocka block7"><img src="https://i.imgur.com/iCO8M4F.png" alt=""/><span class="p">壬基苯酚（環境荷爾蒙）</span></div>
      </div>
    </div>
  </div>
</section>
<section id="section_shop">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12" data-bottom-top="transform: translateY(-50px);opacity: 0" data-center="transform: translateY(0px);opacity: 1">
        <h3>SHOP</h3><a class="p" href="https://shopee.tw/shop/351142643/" target="_blank">點這裡前往購買→</a>
      </div>
    </div>
  </div>
</section>
<fooder id="fooder_contact">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="block">
          <div class="h4">CONTACT</div>
        </div>
        <div class="p">CONTACT US </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2"><img class="imgfluid piclogo" src="https://i.imgur.com/AQvDcPN.png" alt=""/></div>
      <div class="col-md-5">
        <hr/>
        <p class="con">和陞進出口國際貿易有限公司</p>
        <p>桃園市桃園區大興西路二段139巷145號3樓</p>
        <p>+886 972098156</p>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-4">
        <h5 class="me">SHERE ME!</h5>
        <div class="icon"><a href="https://www.facebook.com/loveyourself0972" target="_blank"> <img class="fb" src="https://i.imgur.com/Dj4vrxZ.png" alt=""/></a><a href="https://www.instagram.com/loveyourself_0972/?igshid=p7x2zws1vxa7" target="_blank">   <img class="ig" src="https://i.imgur.com/OCpqOha.png" alt=""/></a><a href="https://lin.ee/MShvJ9I" target="_blank">  <img class="line" src="https://i.imgur.com/DTuUCmm.png" alt=""/></a></div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 foo">
        <p>Copyright ©with rise detergent Co.</p>
      </div>
    </div>
  </div>
</fooder>
    </StyledShoppy>
  )
}

export default Shoppy