import React, {useState} from 'react'
import styled from 'styled-components'

 
const StyledTraining = styled.div`
 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  display: grid;
  place-items: center;
  overflow: hidden;


main {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 10px rgba(0,0,0,0.3);
}

.item {
  width: 200px;
  height: 300px;
  list-style-type: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  box-shadow: 0 20px 30px rgba(255,255,255,0.3) inset;
  transition: transform 0.1s, left 0.75s, top 0.75s, width 0.75s, height 0.75s;

  &:nth-child(1), &:nth-child(2) {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: none;
    border-radius: 0;
    box-shadow: none;
    opacity: 1;
  }

  &:nth-child(3) { left: 50%; }
  &:nth-child(4) { left: calc(50% + 220px); }
  &:nth-child(5) { left: calc(50% + 440px); }
  &:nth-child(6) { left: calc(50% + 660px); opacity: 0; }
}

.content {
  width: min(30vw,400px);
  position: absolute;
  top: 50%;
  left: 3rem;
  transform: translateY(-50%);
  font: 400 0.85rem helvetica,sans-serif;
  color: white;
  text-shadow: 0 3px 8px rgba(0,0,0,0.5);
  opacity: 0;
  display: none;

  & .title {
    font-family: 'arial-black';
    text-transform: uppercase;
  }

  & .description {
    line-height: 1.7;
    margin: 1rem 0 1.5rem;
    font-size: 0.8rem;
  }

  & button {
    width: fit-content;
    background-color: rgba(0,0,0,0.1);
    color: white;
    border: 2px solid white;
    border-radius: 0.25rem;
    padding: 0.75rem;
    cursor: pointer;
  }
}

.item:nth-of-type(2) .content {
  display: block;
  animation: show 0.75s ease-in-out 0.3s forwards;
}

@keyframes show {
  0% {
    filter: blur(5px);
    transform: translateY(calc(-50% + 75px));
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.nav {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  user-select: none;

  & .btn {
    background-color: rgba(255,255,255,0.5);
    color: rgba(0,0,0,0.7);
    border: 2px solid rgba(0,0,0,0.6);
    margin: 0 0.25rem;
    padding: 0.75rem;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: rgba(255,255,255,0.3);
    }
  }
}

@media (width > 650px) and (width < 900px) {
  .content {
    & .title        { font-size: 1rem; }
    & .description  { font-size: 0.7rem; }
    & button        { font-size: 0.7rem; }
  }
  .item {
    width: 160px;
    height: 270px;

    &:nth-child(3) { left: 50%; }
    &:nth-child(4) { left: calc(50% + 170px); }
    &:nth-child(5) { left: calc(50% + 340px); }
    &:nth-child(6) { left: calc(50% + 510px); opacity: 0; }
  }
}

@media (width < 650px) {
  .content {
    & .title        { font-size: 0.9rem; }
    & .description  { font-size: 0.65rem; }
    & button        { font-size: 0.7rem; }
  }
  .item {
    width: 130px;
    height: 220px;

    &:nth-child(3) { left: 50%; }
    &:nth-child(4) { left: calc(50% + 140px); }
    &:nth-child(5) { left: calc(50% + 280px); }
    &:nth-child(6) { left: calc(50% + 420px); opacity: 0; }
  }
}
`;
 
const Training = () => {



const [bgImages, setBgImages] = useState([
  'https://scontent-ord5-2.xx.fbcdn.net/v/t39.30808-6/386766750_10232593560648578_3584765524479088192_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=dd5e9f&_nc_ohc=ojEM12op5bAAX_87G3-&_nc_ht=scontent-ord5-2.xx&oh=00_AfCxgQoBoopXH_pD9I3gI6UDVAcdSIQyWcQtBaiHnbieew&oe=65A3B7EE',
  'https://scontent-ord5-1.xx.fbcdn.net/v/t39.30808-6/386751520_10232593560808582_1126672521456862802_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=dd5e9f&_nc_ohc=8PA_PXv8rQEAX_2oP0v&_nc_ht=scontent-ord5-1.xx&oh=00_AfBe0Qi8BZaOXBnxugMPAlv7jVOrO1RpHZvI7rrTHGq5_A&oe=65A3D033',
  'https://scontent-ord5-1.xx.fbcdn.net/v/t39.30808-6/386737498_10232593564688679_2813926617766897454_n.jpg?stp=cp6_dst-jpg&_nc_cat=108&ccb=1-7&_nc_sid=dd5e9f&_nc_ohc=voCZAawZVOMAX-yviWR&_nc_ht=scontent-ord5-1.xx&oh=00_AfCowSlivapd5AkJjP5QnNoxpjKJVl0XPJlIgrx4iYbI7Q&oe=65A2E0C3'])
const [currentImageSelection, setCurrentImageSelection] = useState(0)

const incrementBgImage = () => {
 (currentImageSelection <= bgImages.length - 2) && setCurrentImageSelection(prevState => prevState + 1)
}
const decrementBgImage = () => {
 (currentImageSelection >= 1) && setCurrentImageSelection(prevState => prevState - 1)
}

  const slider = document.querySelector('.slider');
  const items = document.querySelectorAll('.item');

// const activate = (e) => {
//   e.target.matches('.next') && slider.append(items[0])
//   e.target.matches('.prev') && slider.prepend(items[items.length-1]);
// }
// e.target.matches('.next') && slider.append(items[0])
// e.target.matches('.prev') && slider.prepend(items[items.length-1]);


    return (
        <StyledTraining>
    {/* <h1>current: {currentImageSelection}</h1> */}
          <main>
  <ul class='slider'>
   <li class='item' style={{backgroundImage: `url(${bgImages[currentImageSelection]})`}}>
      <div class='content'>
        <h2 class='title'>"Lossless Youths"</h2>
        <p class='description'> Lorem ipsum, dolor sit amet consectetur
        adipisicing elit. Tempore fuga voluptatum, iure corporis inventore
        praesentium nisi. Id laboriosam ipsam enim.  </p>
        <button>Read More</button>
      </div>
    </li>
 
 <li class='item' style={{backgroundImage: `url(${bgImages[currentImageSelection]})`}}>
      <div class='content'>
        <h2 class='title'>"Lossless Youths"</h2>
        <p class='description'> Lorem ipsum, dolor sit amet consectetur
        adipisicing elit. Tempore fuga voluptatum, iure corporis inventore
        praesentium nisi. Id laboriosam ipsam enim.  </p>
        <button>Read More</button>
      </div>
    </li>

   <li class='item' style={{backgroundImage: `url(${bgImages[currentImageSelection+1]})`}}>
      <div class='content'>
        <h2 class='title'>"Estrange Bond" </h2>
        <p class='description'> Lorem ipsum, dolor sit amet consectetur
        adipisicing elit. Tempore fuga voluptatum, iure corporis inventore
        praesentium nisi. Id laboriosam ipsam enim.  </p>
        <button>Read More</button>
      </div>
    </li>
    
    {/* <li class='item' style={{backgroundImage: "url('https://cdn.mos.cms.futurecdn.net/dP3N4qnEZ4tCTCLq59iysd.jpg')"}}>
      <div class='content'>
        <h2 class='title'>"Lossless Youths"</h2>
        <p class='description'> Lorem ipsum, dolor sit amet consectetur
        adipisicing elit. Tempore fuga voluptatum, iure corporis inventore
        praesentium nisi. Id laboriosam ipsam enim.  </p>
        <button>Read More</button>
      </div>
    </li>
    <li class='item' style={{backgroundImage: "url('https://i.redd.it/tc0aqpv92pn21.jpg')"}}>
      <div class='content'>
        <h2 class='title'>"Estrange Bond"</h2>
        <p class='description'> Lorem ipsum, dolor sit amet consectetur
        adipisicing elit. Tempore fuga voluptatum, iure corporis inventore
        praesentium nisi. Id laboriosam ipsam enim.  </p>
        <button>Read More</button>
      </div>
    </li>
    <li class='item' style={{backgroundImage:" url('https://wharferj.files.wordpress.com/2015/11/bio_north.jpg')"}}>
      <div class='content'>
        <h2 class='title'>"The Gate Keeper"</h2>
        <p class='description'> Lorem ipsum, dolor sit amet consectetur
        adipisicing elit. Tempore fuga voluptatum, iure corporis inventore
        praesentium nisi. Id laboriosam ipsam enim.  </p>
        <button>Read More</button>
      </div>
    </li>
    <li class='item' style={{backgroundImage:" url('https://images7.alphacoders.com/878/878663.jpg')"}}>
      <div class='content'>
        <h2 class='title'>"Last Trace Of Us"</h2>
        <p class='description'>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.
        </p>
        <button>Read More</button>
      </div>
    </li>
    <li class='item' style={{backgroundImage:" url('https://theawesomer.com/photos/2017/07/simon_stalenhag_the_electric_state_6.jpg')"}}>
      <div class='content'>
        <h2 class='title'>"Urban Decay"</h2>
        <p class='description'>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tempore fuga voluptatum, iure corporis inventore praesentium nisi. Id laboriosam ipsam enim.
        </p>
        <button>Read More</button>
      </div>
    </li>
    <li class='item' style={{backgroundImage:" url('https://da.se/app/uploads/2015/09/simon-december1994.jpg')"}}>
      <div class='content'>
        <h2 class='title'>"The Migration"</h2>
        <p class='description'> Lorem ipsum, dolor sit amet consectetur
        adipisicing elit. Tempore fuga voluptatum, iure corporis inventore
        praesentium nisi. Id laboriosam ipsam enim.  </p>
        <button>Read More</button>
      </div>
    </li> */}
  </ul>
  <nav class='nav'>
    <ion-icon  onClick={decrementBgImage} class='btn prev' name="arrow-back-outline"></ion-icon>
    <ion-icon onClick={incrementBgImage} class='btn next' name="arrow-forward-outline"></ion-icon>
  </nav>
</main>


        </StyledTraining>
    )
}
 
export default Training
 