import React, { useState } from 'react'
import styled from 'styled-components'
import Troy from './Troy';
import ReactSlider from 'react-slider';


 
const StyledTroySecondary  = styled.div`
 /* Reset CSS */

	box-sizing: border-box;
	margin: 0;
	padding: 0;



/* Header */
header {
	background-color: #333;
	padding: 5px 1.5em 0 .2em;
}

.logo {
	color: #fff;
	font-weight: bold;
	font-size: 24px;
}

nav {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
}

.menu li {
	display: inline-block;
	margin-left: 20px;
}

.menu li a {
	color: #fff;
	text-decoration: none;
}

/* Main */
.hero-section {
	text-align: center;
	padding: 3em 20px;
	background-color: #f2f2f2;
}

.hero-section h1 {
	font-size: 36px;
	margin-bottom: 20px;
}

.hero-section p {
	color: #777;
	margin-bottom: 40px;
}

.cta-button {
	display: inline-block;
	padding: 12px 30px;
	background-color: #333;
	color: #fff;
	text-decoration: none;
	border-radius: 4px;
}

.services-section {
	padding: 80px 20px;
	text-align: center;
	background-color: #fff;
}

.services-section h2 {
	font-size: 24px;
	margin-bottom: 40px;
}

.services {
	display: flex;
	justify-content: space-between;
}

.service {
	flex-basis: 30%;
	padding: 20px;
	background-color: #f2f2f2;
	border-radius: 4px;
	text-align: center;
}

.service h3 {
	font-size: 20px;
	margin-bottom: 10px;
}

.service p {
	color: #777;
}

/* Footer */
footer {
	text-align: center;
	padding: 20px;
	background-color: #333;
	color: #fff;
}
.troy-logo {
margin:0 0 0 4.5em;
width: 12em;
padding:1.2em 0 1em 0;
}
@media screen and (max-width:750px){
    .troy-logo {
margin: 0 auto 0 auto;
width: 12em;
padding:0.5em 0 0.5em 0;
}
}



* {
  box-sizing: border-box;
  display: block;
  margin: 0;
}

head, script {
  display: none;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  pointer-events: none;
}

body {
  display: flex;
  height: 100vh;
  background: #f2f2f2;
  color: rgba(0, 0, 0, 0.8);
  padding: 1rem;
  font-family: "Open Sans", sans-serif;
}

main {
  width: 100%;
  max-width: 50rem;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border-radius: 2rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
}

h3 {
  font-family: "Montserrat";
}

nav {
  padding: 2rem;
  display: flex;
  margin-top: -4rem;
}
nav button {
  min-width: 10rem;
}
nav button:first-child {
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
nav button:last-child {
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
nav button.selected {
  background: #222;
  color: #fff;
}

section {
  width: 100%;
  padding: 1rem 3rem;
  background: #fff;
}
section > * + * {
  margin: 2rem 0 0 2rem;
}
section > label {
  font-size: 1.38rem;
  line-height: 150%;
  text-align: center;
  color: #666;
  width: 100%;
}
section > label > * {
  display: inline-block;
}
section > label > b {
  margin: 0 0.4em;
  text-decoration: underline;
  color: #333;
}
section input {
  margin-top: 1rem !important;
}
section p {
  color: rgba(0, 0, 0, 0.5);
}

.row {
  display: flex;
  align-items: center;
}
.row > * + * {
  margin-left: 2rem;
}

input[type=range] {
  width: 100%;
}

button {
  padding: 1rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-transform: capitalize;
  outline: none;
  color: rgba(0, 0, 0, 0.5);
}
button[disabled] {
  color: #2270F6;
  border: 1px solid #2270F6 !important;
}

.mla {
  margin-left: auto;
}

.mra {
  margin-right: auto;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -2rem;
  margin-top: 1rem;
}
.footer > * + * {
  margin-top: 1rem;
}
.footer h3 {
  text-align: center;
  font-size: 3rem;
  color: #444;
}
.footer button {
  background: #1a9bc7;
  min-width: 12rem;
  color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 1rem rgba(0, 0, 0, 0.23);
  border-radius: 0.38rem;
  font-size: 1.38rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 1rem 3rem;
}
.footer span {
  font-size: 1.1rem;
}
.footer span.annual {
  color: green;
}
.footer span.monthly {
  color: #999;
}
.footer span.custom {
  color: #999;
}
.footer div * + * {
  margin-top: 0.5rem;
}

input[type=range] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */
  max-width: 40rem;
  margin-top: 2.4rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

input[disabled] {
  opacity: 0.25;
  display: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  animate: 0.2s;
  background: rgba(0, 0, 0, 0.2);
}

input[type=range]::-webkit-slider-thumb {
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  transform: translateY(-50%);
}

input[type=range]:not([disabled])::-webkit-slider-thumb {
  border: 2px solid #1a9bc7;
}

.mark,mark {
    background: #6bb2ea;
}

`;
 
const TroySecondary = () => {
    const [magicValue, setMagicValue] = useState(75);
    const [seats, setSeats] = useState(1);

return (
<StyledTroySecondary>
<header>
	<nav style={{marginTop:'-0.5em'}}>
		<div class="logo"> <img className='troy-logo' src="https://dl3.pushbulletusercontent.com/UHA56he29M0nSuM96W21jomtqSHj6b96/image.png" alt="" /></div>
		<ul class="menu">
			<li><a href="#">menu one</a></li>
			<li><a href="#">menu two</a></li>
			<li><a href="#">menu three</a></li>
			<li><a href="#">menu four </a></li>
		</ul>
	</nav>
</header>

<main>
	{/* <section class="hero-section">
		<h1 style={{maxWidth:'67vw',textAlign:'left',margin:' 0 6.7% 6.7% 6.7%',padding:'0 3% .5em 3%',lineHeight:'1.25',fontWeight:'bold'}}>WE HELP KEEP YOUR FAMILY SAFE & IMPROVE AIR QUALITY</h1>
        <h5>Residential & Commercial

Air Ducts | Dryer Vents | Chimney</h5>
		<p style={{maxWidth:'75vw',margin:'auto',padding:'0 0 2.5em 0',marginLeft:'1.67em',textAlign:'left'}}>We are NADCA,  CDET  & CSIA Certified.

Furthermore, we  pride ourselves on the quality of our work as well as our commitment to  outstanding results. We look forward to building lasting relationships  and guarantee your satisfaction!</p>
		<a href="#" class="cta-button" style={{fontWeight:'bold',fontSize:'1.2em',background:'#006ec6'}}>Get a Free Quote</a>
	</section> */}
	{/* <section class="services-section">
		<h2>Hizmetlerimiz</h2>
		<div class="services">
			<div class="service">
				<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24">
					<path d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20ZM13 12V16H11V12H8L12 8L16 12H13Z"></path>
				</svg>
				<h3>Hizmet 1</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
			</div>
			<div class="service">
				<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24">
					<path d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20ZM13 12V16H11V12H8L12 8L16 12H13Z"></path>
				</svg>
				<h3>Hizmet 2</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
			</div>
			<div class="service">
				<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24">
					<path d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20ZM13 12V16H11V12H8L12 8L16 12H13Z"></path>
				</svg>
				<h3>Hizmet 3</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
			</div>
		</div>
	</section> */}
</main>
<nav style={{marginTop:'3.2em'}}><button style={{backgroundColor:'transparent',color:'#222'}} class="selected">Total Monthly Savings</button>
{/* <button class="">Savings Yearly</button> */}
</nav><section><label>Sum Total Savings after duration of<b>
    {(seats > 0 && seats <= 24) && '1'}
    {(seats  > 24 && seats <= 36) && '2'}
    {(seats > 36 && seats <= 48) && '3'}
    {(seats > 48 && seats <= 60) && '4'}
    {(seats > 60 && seats <= 72) && '5'}
    {(seats > 72 && seats <= 84) && '6'}
    {(seats > 84 && seats <= 94) && '7'}
    {(seats > 94 && seats <= 100) && '8'}
    </b>years</label>
{/* <input type="range" min="0" max="5000" value="1000"/> */}
{/* <div className="title">Single Thumb</div> */}


<div className="h-12 sm:px-16 lg:px-20 xl:px-24 sm:h-22">
            <ReactSlider
              className="mt-14"
              marks
              min={1}
              max={100}
              defaultValue={seats}
              onChange={(value) => setSeats(value)}
              renderThumb={(props, state) => (
                <div
                  {...props}
                  style={{ ...props.style, zIndex: 20 }}
                  className="relative flex flex-col items-center w-8 h-8 -mt-2 outline-none"
                >
                  <div style={{margin:'-2.3em 0 0 -1.75em'}} className="absolute top-0 inline-block px-2 py-1 mb-2 -mt-2 text-xs text-white bg-gray-900 rounded-sm whitespace-nowrap">
                    {state.valueNow} month{state.valueNow > 1 && 's'}
                  </div>
                  <div className="w-8 h-8 bg-white border-4 border-blue-700 rounded-full shadow-lg cursor-pointer" />
                </div>
              )}
              renderTrack={(props, state) => (
                <div
                  {...props}
                  className='h-4 rounded-full cursor-pointer'
                  
                />
              )}
            />
          </div>

{/*  */}
</section><div className='footer'>
    {(seats > -1 && seats < 9) && <h3>$80 savings</h3>}
    {(seats > 9 && seats < 20) && <h3>$130 savings</h3>}
    {(seats > 19 && seats < 30) && <h3>$253 savings</h3>}
{(seats > 29 && seats < 40 ) && <h3>$377 savings</h3>}
{(seats > 39 && seats < 50 ) && <h3>$491 savings</h3>}
{(seats > 49 && seats < 60 ) && <h3>$549 savings</h3>}
{(seats > 59 && seats < 70 ) && <h3>$654 savings</h3>}
{(seats > 69 && seats < 80 ) && <h3>$706 savings</h3>}
{(seats > 79 && seats < 90 ) && <h3>$782 savings</h3>}
{(seats > 89 && seats < 101 ) && <h3>$827 savings</h3>}
<span class="annual">Equates to $64/mo - SAVE $892</span><button>Get a Free Quote Now</button></div>
{/*  */}
<Troy />
{/*  */}

<footer>
	<p>Vent 2 Vent LLC© 2024. All rights reserved.</p>
</footer>
</StyledTroySecondary>
)
}
 
export default TroySecondary