import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Navigation from './Navigation';
import { Link } from 'react-router-dom'

const StyledHeroseph = styled.div`
margin: 9em 0 0 0;
 html {
  font-size: 16px;
}

.body {
  height: 100vh;
  padding: 3em 0 0 0;
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #070707;
  font-family: "helvetica neue", helvetica, sans-serif;
  overflow: hidden;
}

a {
  color: #fff;
  text-decoration: none;
}

.scroll {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.5);
  font-family: "font-2";
  font-size: calc(0.5rem + 0.35vw);
  z-index: 10;
}
.logo {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  margin: 0;
  z-index: 10;
}
.logo img {
  display: block;
  height: 5rem;
  width: auto;
}

ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav {
  position: absolute;
  top: 2rem;
  z-index: 10;
}
@media screen and (max-width: 720px){
    .nav {
  position: absolute;
  top: 2rem;
  z-index: 10;
  display: none;
}
}
.nav--left {
  left: 1rem;
}
.nav--right {
  right: 1rem;
}
.nav ul {
  display: flex;
  align-items: center;
  height: 1rem;
}
.nav li {
  display: block;
  margin: 0 1rem;
  padding: 0;
}
.nav a {
  position: relative;
  display: flex;
  align-items: center;
  font-size: calc(0.5rem + 0.35vw);
  font-family: "helvetica neue", helvetica, sans-serif;
}
.nav a span {
  position: relative;
}
.nav a span:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.35rem;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.25);
  transition: transform 0.75s ease;
  transform-origin: right;
  transform: scaleX(0);
}
.nav a:hover span:before, .nav a.is-active span:before {
  transform: scaleX(1);
  transform-origin: left;
}

.vert-text {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  width: 15rem;
  display: flex;
  align-items: center;
  z-index: 10;
}
.vert-text span {
  display: block;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.1;
  transform: rotate(-90deg) translateY(15rem);
  transform-origin: bottom left;
  font-size: 1.35rem;
}

.cart-total {
  display: block;
  height: 2rem;
  width: 2rem;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  text-align: center;
  line-height: 2rem;
  margin-left: 1rem;
}

.slider {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.slider__text {
  position: absolute;
  bottom: calc(2rem + 8vw);
  left: calc(2rem + 0.1vw);
  z-index: 10;
  font-size: calc(1rem + 4vw);
  text-transform: uppercase;
  transform-origin: top;
  line-height: 1.075;
  color: #fff;
  font-weight: 500;
}
.slider__text-line {
  overflow: hidden;
}
.slider__inner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.slider__nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 10;
}
.slider-bullet {
  display: flex;
  align-items: center;
  padding: 1rem 0;
}
.slider-bullet__text {
  color: #fff;
  font-size: 0.65rem;
  margin-right: 1rem;
}
.slider-bullet__line {
  background-color: #fff;
  height: 1px;
  width: 1rem;
}
.slider canvas {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.slide {
  position: absolute;
  left: 0;
  top: 2em;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.slide__content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 8em;
  right: 0;
  bottom: 0;
}
.slide__img {
  position: relative;
  width: 25vw;
  height: 70vh;
  padding: 0;
  margin: 0;
  min-width: 12.5rem;
  transform-origin: top;
  filter: grayscale(1) contrast(1.095);
}
.slide__img:first-child {
  top: -1.5rem;
}
.slide__img:last-child {
  bottom: -1.5rem;
}
.slide__img img {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
    
}

/* start loading screen */
/*loader*/

.loader {
	z-index: 999999999;
  padding: 1rem;
  background-color: rgba(0,0,0,0.99);
  // background-image: url('https://dl3.pushbulletusercontent.com/KIJHhmLqp3Uzbc78cdine4cDo7TT7Ca7/image.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  color: white;
  position: fixed;
  inset: 0;
}

.o1 {
  opacity: 0.1;
}

.txt {
  position: absolute;
  font-size: 7vmax;
}
.txt-mask {
  position: absolute;
  background-color: #080808;
  width: 100%;
  height: 100%;
  transform: translatex(0%);
  -webkit-animation: loader 2s ease-in-out infinite alternate;
          animation: loader 2s ease-in-out infinite alternate;
  will-change: transform;
}

.non-active {
    display: none;
}

@-webkit-keyframes loader {
  to {
    transform: translatex(100%);
  }
}

@keyframes loader {
  to {
    transform: translatex(100%);
  }
}
/*/loader*/
/* end loading screen */

.hero-text {
   color: #e0b646;
   text-shadow: 2px 3px 3px rgba(0,0,0,0.7);
   transform: translate3d(0,-1.5rem,0);
}
`;




const Heroseph = () => {
    const [isLoading, setIsLoading] = useState(true);
    const handleLoader = ()=>{setIsLoading(!isLoading);}
    useEffect(() => {
      setTimeout(handleLoader,2400)
    
    }, [])
    
    return (
        <StyledHeroseph>
            <div className={!!isLoading ? "loader":"non-active"}>
                <p>LOADING</p>
                <div className="loader__inner">
                    <div className="txt">
                        <div className="txt-mask"></div>
                        <div className="txt-h1">Denard Bros.</div>
                    </div>
                    <div className="txt">
                        <div className="txt-h1 o1">Denard Bros.</div>
                    </div>
                </div>
            </div>
            <div className="body">
 
{/* <Navigation /> */}
               

      
                <figure className="logo" style={{ display: 'flex',margin: '2.3em 0 0 0'}}>
                    <img src="https://dl3.pushbulletusercontent.com/1YueTpUkl002Qa2F3q8DGVqgVRIaj9S4/denardlogo-rmbg-1.png" />
                </figure>
                
                {/* 🔥 */}

               
                <div className="slider js-slider">
                    <div className="slider__inner js-slider__inner"></div>

                    <div className="slide js-slide">
                        <div className="slide__content">
                            <figure className="slide__img js-slide__img">
                                <img src="/" />
                            </figure>
                            <figure className="slide__img js-slide__img">
                                <img src="/" />
                            </figure>
                        </div>

                        <div className="slider__text js-slider__text">
                            <div className="slider__text-line js-slider__text-line hero-text"><div>Denard </div></div>
                            <div className="slider__text-line js-slider__text-line hero-text"><div>Bros</div></div>
                            <div className="slider__text-line js-slider__text-line hero-text"><div>Professional</div></div>
                            <div className="slider__text-line js-slider__text-line hero-text"><div>Basketball Training.</div></div>
                            {/* button start */}
                            

<Link
  className="group relative inline-block focus:outline-none focus:ring"
  to="/camps"
>
  <span
    className="absolute inset-0 translate-x-1.5 translate-y-1.5 bg-yellow-300 transition-transform group-hover:translate-y-0 group-hover:translate-x-0"
  ></span>

  <span
    className="relative inline-block border-2 border-current px-8 py-3 text-sm font-bold uppercase tracking-widest text-black group-active:text-opacity-75"
  >
    Sign Up Now
  </span>
</Link>

                            {/* button end */}
                        </div>

                    </div>

                  

                    <div className="slide js-slide">
                        <div className="slide__content">
                            {/* <figure className="slide__img js-slide__img"> */}
                                {/* <img src="https://dl3.pushbulletusercontent.com/aiFtAxDobIzF5bUuDBea72FhcgF3FmAL/eddiecut-3.png" /> */}
                                {/* <img src="https://dl3.pushbulletusercontent.com/JjapLCElrXBUKjWTAxkYqLZz8vsZbF3r/denardboth-3.png" /> */}
                            {/* </figure> */}
                            <figure className="slide__img js-slide__img">
                                <img src="https://dl3.pushbulletusercontent.com/iy4fGml1zGO2iXHiwMu1w7daEsGTOyov/D4C8B88E-5ECB-4BBC-B13C-18F783E94D55.jpeg" />
                            </figure>
                            <figure className="slide__img js-slide__img">
                                 <img style={{filter:'contrast(1.05) brightness(1.22)'}} src="https://dl3.pushbulletusercontent.com/JYqFKRcZImC9Wy9XmKqoHV3VRg3ILURD/aub-4.png" />
                                 {/* <img src="https://dl3.pushbulletusercontent.com/gyUga3DnMNkzYBImRa2Mi02szSsiWDTZ/image.png" /> */}
                                {/* <img src="https://dl3.pushbulletusercontent.com/c6qhxxoEUn3LM6b5D7E3aihWlu6p7tpT/image.png" /> */}
                                {/* https://s3-us-west-2.amazonaws.com/s.cdpn.io/58281/photo6.jpg */}
                            </figure>
                        </div>
                    </div>

                    <nav className="slider__nav js-slider__nav">
                        <div className="slider-bullet js-slider-bullet">
                            <span className="slider-bullet__text js-slider-bullet__text">01</span>
                            <span className="slider-bullet__line js-slider-bullet__line"></span>
                        </div>
                        <div className="slider-bullet js-slider-bullet">
                            <span className="slider-bullet__text js-slider-bullet__text">02</span>
                            <span className="slider-bullet__line js-slider-bullet__line"></span>
                        </div>
                        <div className="slider-bullet js-slider-bullet">
                            <span className="slider-bullet__text js-slider-bullet__text">03</span>
                            <span className="slider-bullet__line js-slider-bullet__line"></span>
                        </div>
                    </nav>

                  
            </div>

                </div>

                <div className="vert-text">
                    <span>
                        Eddie Denard<br />
                        X Aubery Denard
                    </span>
                </div>
        </StyledHeroseph>
    )
}

export default Heroseph
