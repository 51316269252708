import React, { useState } from 'react'
import styled from 'styled-components'

const StyledNewNews = styled.div`
 .body{
    font-family: 'Droid Serif', serif;
    font-size: 1em;
    color: #e0b646;
    /* color: #f2f2f2; */
    background-color: #101010;
    /* background-color: #f9f7f1; */
    padding: 3em 1.1em 1.1em 1.1em;
    margin-top: 5rem;
}
header {
    font-family: 'Playfair Display', serif;
    font-weight: 900;
    font-size: 3em;
    text-transform: uppercase;
    display: inline-block;
    line-height: 72px;
    margin-bottom: 20px;

}
p{
    margin-top: 0;
    margin-bottom: 20px;
}
.head{
    text-align: center;
    position: relative;


}

.headerobjectswrapper{
}

.subhead{
    text-transform: uppercase;
    border-bottom: 2px solid #2f2f2f;
    border-top: 2px solid #2f2f2f;
    padding: 12px 0 12px 0;

}
.weatherforcastbox{
    position: relative;
    width: 12%;
    left: 10px;
    border: 3px double #2f2f2f;
    padding: 10px 15px 10px 15px;
    line-height: 20px;
    display: inline-block;
    margin: 0 50px 20px -360px;
}
.content{
    font-size: 0;
    line-height: 0;
    word-spacing: -.31em;
    display: inline-block;
    margin: 30px 2% 0 2%;


}
.collumns{

}

.collumn{
    font-size: 14px;
    line-height: 20px;
    width: 17.5%;
    display: inline-block;
    padding: 0 1% 0 1%;
    vertical-align: top;
    margin-bottom: 50px;
    transition: all .7s;
}
.collumn + .collumn { 
  border-left: 1px solid #2f2f2f;
}
.collumn .headline{
    text-align: center;
    line-height: normal;
    font-family: 'Playfair Display', serif;
    display: block;
    margin: 0 auto;


}
.collumn .headline.hl1{
    font-weight: 700;
    font-size: 30px;
    text-transform: uppercase;
    padding: 10px 0 10px 0;

}

.collumn .headline.hl2{
    font-weight: 400;
    font-style: italic;
    font-size: 24px;
    box-sizing: border-box;
    padding: 10px 0 10px 0;
}

.collumn .headline.hl2:before{
    border-top: 1px solid #2f2f2f;
    content: '';
    width: 100px;
    height: 7px;
    display: block;
    margin: 0 auto;
}
.collumn .headline.hl2:after{
    border-bottom: 1px solid #2f2f2f;
    content: '';
    width: 100px;
    height: 13px;
    display: block;
    margin: 0 auto;

}

.collumn .headline.hl3{
    font-weight: 400;
    font-style: italic;
    font-size: 36px;
    box-sizing: border-box;
    padding: 10px 0 10px 0;
}
.collumn .headline.hl4{
    font-weight: 700;
    font-size: 12px;
    box-sizing: border-box;
    padding: 10px 0 10px 0;
}
.collumn .headline.hl4:before{
    border-top: 1px solid #2f2f2f;
    content: '';
    width: 100px;
    height: 7px;
    display: block;
    margin: 0 auto;
}
.collumn .headline.hl4:after{
    border-bottom: 1px solid #2f2f2f;
    content: '';
    width: 100px;
    height: 10px;
    display: block;
    margin: 0 auto;

}

.collumn .headline.hl5{
    font-weight: 400;
    font-size: 42px;
    text-transform: uppercase;
    font-style: italic;
    box-sizing: border-box;
    padding: 10px 0 10px 0;
}
.collumn .headline.hl6{
    font-weight: 400;
    font-size: 18px;
    box-sizing: border-box;
    padding: 10px 0 10px 0;
}
.collumn .headline.hl6:before{
    border-top: 1px solid #2f2f2f;
    content: '';
    width: 100px;
    height: 7px;
    display: block;
    margin: 0 auto;
}
.collumn .headline.hl6:after{
    border-bottom: 1px solid #2f2f2f;
    content: '';
    width: 100px;
    height: 10px;
    display: block;
    margin: 0 auto;

}
.collumn .headline.hl7{
    font-weight: 700;
    font-size: 12px;
    box-sizing: border-box;
    display: block;
    padding: 10px 0 10px 0;
}
.collumn .headline.hl8{
    font-weight: 700;
    font-size: 12px;
    box-sizing: border-box;
    padding: 10px 0 10px 0;
}
.collumn .headline.hl9{
    font-weight: 700;
    font-size: 12px;
    box-sizing: border-box;
    padding: 10px 0 10px 0;
}
.collumn .headline.hl10{
    font-weight: 700;
    font-size: 12px;
    box-sizing: border-box;
    padding: 10px 0 10px 0;
}
.collumn .citation{
    font-family: 'Playfair Display', serif;
    font-size: 36px;
    line-height: 44px;
    /*font-style: italic;*/
    text-align: center;
    font-weight: 400;
    display: block;
    margin: 40px 0 40px 0;
    font-feature-settings: "liga", "dlig";

}
.collumn .citation:before{
    border-top: 1px solid #2f2f2f;
    content: '';
    width: 100px;
    height: 16px;
    display: block;
    margin: 0 auto;
}
.collumn .citation:after{
    border-bottom: 1px solid #2f2f2f;
    content: '';
    width: 100px;
    height: 16px;
    display: block;
    margin: 0 auto;
}
.collumn .figure {
	  margin: 0 0 20px;
}
.collumn p {
	  margin: 0 0 3em 0;
}
.collumn .figcaption{
	font-style: italic;
	font-size: 12px;
}
.media {
    -webkit-filter: sepia(80%) contrast(1) opacity(0.8);
    filter: sepia(80%) grayscale(1) contrast(1) opacity(0.8);
    /* mix-blend-mode: multiply; */
    width: 100%;
}
/*________________________________________________________________________________________________________________________________*/
/*MEDIAQUERIES*/
@media only all and (max-width: 1300px) {
    .weatherforcastbox{
        display: none;
    }

}
@media only all and (max-width: 1200px) {
    .collumn{
        width: 31%;
    }

}
    @media only all and (max-width: 900px) {
    .collumn{
        width: 47%;
    }

}
@media only all and (max-width: 600px) {
    .collumn{
        width: 100%;
    }
	.collumn + .collumn {
				border-left: none;
      	border-bottom: 1px solid #2f2f2f;
	}
    header{
        max-width: 320px;
        font-size: 60px;
        line-height: 54px;
        overflow: hidden;
    }

}
`;



const NewNews = () => {
    const [things, setThings] = useState([
        {
            title: 'Opening Night - Denard Bros Pro Run',
            date:'June 23, 2023',
            picUrl: 'https://lh3.googleusercontent.com/mzu8NW28VJJMX4lZMEG3hQhRcRjQ4NQP9PhuX2hmNxrUq7kPlO_Zg1xxRFdaDSVNGvJ55wuv9GxrdV4ZFdPFtkumYsB5GGsCG1v8Je2P=s480',
            caption: 'DENARD BROS PRO RUN',
            description: 'Opening Night is fashion night. tickets are on sale now'
        },
        {
            title: 'Camps are coming soon - Denard Bros Pro Run',
            date:'June 17, 2023',
            picUrl: 'https://dl3.pushbulletusercontent.com/AfmPCkaefoyY88lSrpoELUzqBhq57gwv/image.png',
            caption: 'Aubery and Eddie Denard smile for the cameras as they kick off next level skills camps',
            description: 'Opening Night is fashion night. tickets are on sale now'
        }
    ])
    return (
        <StyledNewNews>
            <div className='body'>
                <div className="head">
                    <div className="headerobjectswrapper">

                        <header>Denard Bros Updates</header>
                    </div>

                    <div className="subhead">Stay up to date with whats going on!</div>
                </div>
                <div className="content">
                    <div className="collumns">
                        {things.map((thing, index) => (
                            <>
                                {index < 3 && <div className="collumn"><div className="head"><span className="headline hl1">{thing.title}</span><p><span className="headline hl2">{thing.date}</span></p></div>
                                    <figure className="figure">
                                        <img className="media" src={thing.picUrl} alt="" />
                                        <figcaption className="figcaption">"{thing.caption}"</figcaption>
                                    </figure>
                                    <p>{thing.description}</p>

                                    <hr />
                                </div>
                                }
                            </>

                        ))}

                    </div>
                </div>

            </div>
        </StyledNewNews>
    )
}

export default NewNews
