import React from 'react'
import styled from 'styled-components'
 
const StyledInvite = styled.div`
 
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  background-color: #111;
  color: #959595;
  font: 400 75%/1.5 "economica", sans-serif;
  /* min-width: 30em; */


h2, h3 {
  margin: 0 0 0.375rem 0;
}

h3 {
  margin-top: 1.5rem;
}

.subtitle {
  font-style: italic;
  font-weight: 400;
  font-size: 0.875em;
  color: #898989;
  margin: 0 0 0.25em;
}

.card {
  border: 0.75rem solid #959595;
  max-width: 40em;
  margin: 1.5em auto;
}

.heading {
  font-weight: 700;
}

.super-heading,
.title {
  display: inline-block;
  color: #f5f5f5;
  background: #959595;
  text-transform: uppercase;
}

.super-heading {
  font-size: 1.75em;
  font-style: italic;
  padding: 0 0.5em 0.125em 0.125em;
  margin: 1em 0 0.125em;
  line-height: 1.25;
}

.title {
  margin: 0 0 0.125em;
  padding-right: 0.125em;
  font-size: 4.5em;
  line-height: 1;
  max-width: 20ch;

}

.mirrored {
  display: inline-block;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.party-address {
  padding: 0.125em 0;
  font-style: normal;
  text-align: center;
  border-width: 0.125em 0;
  border-style: solid;
  border-color: #959595;
}

.party-address > p {
  display: inline-block;
  margin: 0 1em;
}

.party-date {
  display: block;
  border-bottom: 0.125em solid #959595;
  text-align: center;
  margin: 0 0 0.5em;
}

.party-month,
.party-day {
  display: inline-block;
  vertical-align: baseline;
  font-weight: 700;
  color: #efefef;
}

.party-month {
  font-size: 6em;
  line-height: 1.25;
  margin-left: 0.25em;
}

.party-day {
  line-height: 1;
  font-size: 9em;
}

.party-day sup {
  font-size: 0.25em;
  top: -2em;
}

form {
  padding: 2.4em 0.75em 0.75em;
}

input[type=radio] {
  margin-right: 0.25em;
}

.radio-bringing--yes:checked ~ .is_bringing {
  display: block;
}

.radio-coming--yes:checked ~ .bringing {
  display: block;
}

.label-coming--yes,
.label-bringing--yes {
  margin-right: 1.5em;
}

.bringing {
  display: none;
  border: none;
  padding: 0;
}

.is_bringing {
  display: none;
  padding-left: 1.5em;
  border: none;
}

input[type=text] {
  width: 100%;
  margin: 0 0 0.5em;
  -webkit-appearance: none;
  border-radius: 0;
  border: 0.0675em solid #898989;
  padding: 0.25em 0.375em;
  color: #959595;
}

input[type=text]:disabled {
  color: #898989;
}

.send-card-button {
  display: block;
  margin: 1.5em auto 0;
  border: none;
  background-color: #959595;
  padding: 0.25em 0.5em 0.5em;
  color: #f5f5f5;
  font-size: 1.5em;
}

.send-card-button:hover {
  color: tomato;
}

.send-card-button.sent {
  pointer-events: none;
}

.thank-you {
  text-align: center;
  margin: 1.5em 0 0 0;
}

@media only screen and (min-width: 48em) {
  body {
    font-size: 1em;
  }

  .card {
    max-width: 56em;
  }

  .card:after {
    content: "";
    display: table;
    clear: both;
  }

  .column {
    float: left;
  }

  .column.left {
    width: 66.6666%;
    border-right: 0.125em solid #959595;
  }

  .column.right {
    width: 33.3334%;
  }

  .party-address {
    margin: 2.4em 0 1.5em;
  }

  .party-date {
    border: none;
  }
}

`;
 
const Invite = () => {
    return (
        <StyledInvite>
          <section className="bg-gray-900 text-white">
  <div className="max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
    <div className="max-w-xl">
      <h2 className="text-3xl font-bold sm:text-4xl">What makes us special</h2>

      <p className="mt-4 text-gray-300">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat
        dolores iure fugit totam iste obcaecati. Consequatur ipsa quod ipsum
        sequi culpa delectus, cumque id tenetur quibusdam, quos fuga minima.
      </p>
    </div>

    <div
      className="mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-2 md:gap-12 lg:grid-cols-3"
    >
      <div className="flex items-start gap-4">
        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
          <svg
            className="h-5 w-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
            <path
              d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
            ></path>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
            ></path>
          </svg>
        </span>

        <div>
          <h2 className="text-lg font-bold">Lorem, ipsum dolor.</h2>

          <p className="mt-1 text-sm text-gray-300">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
            cumque tempore est ab possimus quisquam reiciendis tempora animi!
            Quaerat, saepe?
          </p>
        </div>
      </div>

      <div className="flex items-start gap-4">
        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
          <svg
            className="h-5 w-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
            <path
              d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
            ></path>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
            ></path>
          </svg>
        </span>

        <div>
          <h2 className="text-lg font-bold">Lorem, ipsum dolor.</h2>

          <p className="mt-1 text-sm text-gray-300">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
            cumque tempore est ab possimus quisquam reiciendis tempora animi!
            Quaerat, saepe?
          </p>
        </div>
      </div>

      <div className="flex items-start gap-4">
        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
          <svg
            className="h-5 w-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
            <path
              d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
            ></path>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
            ></path>
          </svg>
        </span>

        <div>
          <h2 className="text-lg font-bold">Lorem, ipsum dolor.</h2>

          <p className="mt-1 text-sm text-gray-300">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
            cumque tempore est ab possimus quisquam reiciendis tempora animi!
            Quaerat, saepe?
          </p>
        </div>
      </div>

      <div className="flex items-start gap-4">
        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
          <svg
            className="h-5 w-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
            <path
              d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
            ></path>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
            ></path>
          </svg>
        </span>

        <div>
          <h2 className="text-lg font-bold">Lorem, ipsum dolor.</h2>

          <p className="mt-1 text-sm text-gray-300">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
            cumque tempore est ab possimus quisquam reiciendis tempora animi!
            Quaerat, saepe?
          </p>
        </div>
      </div>

      <div className="flex items-start gap-4">
        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
          <svg
            className="h-5 w-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
            <path
              d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
            ></path>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
            ></path>
          </svg>
        </span>

        <div>
          <h2 className="text-lg font-bold">Lorem, ipsum dolor.</h2>

          <p className="mt-1 text-sm text-gray-300">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
            cumque tempore est ab possimus quisquam reiciendis tempora animi!
            Quaerat, saepe?
          </p>
        </div>
      </div>

      <div className="flex items-start gap-4">
        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
          <svg
            className="h-5 w-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
            <path
              d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
            ></path>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
            ></path>
          </svg>
        </span>

        <div>
          <h2 className="text-lg font-bold">Lorem, ipsum dolor.</h2>

          <p className="mt-1 text-sm text-gray-300">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Error
            cumque tempore est ab possimus quisquam reiciendis tempora animi!
            Quaerat, saepe?
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
{/* <!-- card --> */}
        </StyledInvite>
    )
}
 
export default Invite
 