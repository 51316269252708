import React, { useState } from 'react'
import styled from 'styled-components'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
 
const StyledFlexAccordian = styled.div`
.exit-x {
  z-index: 9999;
    position: absolute;
    top: 0.45em;
    right: 0.45em;
    height: 2.7em;
    width: 2.7em;
    border-radius: 50%;
    background: #000;
    display: grid;
    place-content: center;
    font-size: 1.68em;
}
display: flex;
flex-direction: column;
 @-webkit-keyframes blinky {
  0%, 9%, 11%, 19%, 21%, 69%, 71%, 100% {
    transform: scaleY(1);
  }
  10%, 20%, 70% {
    transform: scaleY(0);
  }
}
@keyframes blinky {
  0%, 9%, 11%, 19%, 21%, 69%, 71%, 100% {
    transform: scaleY(1);
  }
  10%, 20%, 70% {
    transform: scaleY(0);
  }
}
.body {
    width: 100vw;
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  line-height: 24px;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.bio {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  grid-gap: 24px;
}

ul {
  display: flex;
  min-height: 450px;
  height: auto;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  list-style-type: none;
  width: 100vw;
  min-width: 100%;
  flex-direction: column;

}
@media only screen and (min-width: 1280px) {
  /* ul {
    flex-direction: row;
  } */
}

li {
  flex: 1;
  display: flex;
  align-items: stretch;
  cursor: pointer;
  transition: all 0.35s ease;
  cursor: pointer;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  overflow: hidden;
}
li:before {
  content: "";
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(15, 15, 15, 0.75);
}
li.active {
  flex: 6;
  cursor: default;
}
li.active:before {
  background: linear-gradient(180deg, rgba(15, 15, 15, 0) 0%, rgba(0,0,0,0.88) 100%);
}

h2 {
  font-size: 36px;
  line-height: 36px;
  font-weight: 700;
  text-transform: uppercase;
}
@media only screen and (min-width: 768px) {
  h2 {
    font-size: 48px;
    line-height: 48px;
  }
}
@media only screen and (min-width: 1280px) {
  h2 {
    font-size: 64px;
    line-height: 64px;
  }
}

h3 {
  font-weight: bold;
  white-space: nowrap;
  position: absolute;
  z-index: 30;
  opacity: 1;
  top: 50%;
  left: 50%;
  transition: top 0.35s, opacity 0.15s;
  transform-origin: 0 0;
  font-size: 24px;
  text-transform: uppercase;
  transform: translate(-50%, -50%) rotate(0deg);
}
@media only screen and (min-width: 1280px) {
  h3 {
    top: 100%;
    left: 50%;
    font-size: 32px;
    transform: translate(-20px, -50%) rotate(-90deg);
  }
}
.active h3 {
  opacity: 0;
  top: 200%;
}

.section-content {
  position: relative;
  z-index: 30;
  opacity: 0;
  align-self: flex-end;
  width: 100%;
  transition: all 0.35s 0.1s ease-out;
}
.active .section-content {
  opacity: 1;
}
.section-content .inner {
  position: absolute;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  align-items: flex-end;
  left: 0;
  bottom: 0;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.25s ease-out;
}
@media only screen and (min-width: 768px) {
  .section-content .inner {
    grid-auto-flow: column;
    grid-template-columns: calc(100% - 340px) 300px;
    grid-column-gap: 40px;
    padding: 40px;
  }
}
@media only screen and (min-width: 1280px) {
  .section-content .inner {
    grid-auto-flow: column;
    grid-template-columns: 460px 200px;
    grid-column-gap: 40px;
    padding: 40px;
  }
}
.active .section-content .inner {
  opacity: 1;
}

.artist-profile-link {
  pointer-events: none;
}
.active .artist-profile-link {
  pointer-events: all;
}


`;
 
const FlexAccordian = ( {setIsNavOpen} ) => {
    const [menuSelections, setMenuSelections] = useState([
        {linkUrl:'/camps',backgroundUrl:'https://lh3.googleusercontent.com/NNT53jfNHabTFjJuT74CYhcs1u0zEIEhpZ08kToAsjKcetdmSTpzL4eSKLKDE85bHTkSWvl0-4I9Ml3JJgGhBX7OFwgnIsZjjH9Scial=s817',name:'Training',description:'.',profileUrl:''},
        {linkUrl:'/camps',backgroundUrl:'http://firemixtapesapparel.store/images/main-p-800.jpeg',name:'Mixtape Videos',description:'.',profileUrl:''},
        {linkUrl:'/camps',backgroundUrl:'https://dl3.pushbulletusercontent.com/iy4fGml1zGO2iXHiwMu1w7daEsGTOyov/D4C8B88E-5ECB-4BBC-B13C-18F783E94D55.jpeg',name:'Camps & Clinics',description:'.',profileUrl:''},
        {linkUrl:'/prorun',backgroundUrl:'https://dl3.pushbulletusercontent.com/c6qhxxoEUn3LM6b5D7E3aihWlu6p7tpT/image.png',name:'Pro Runs',description:'.',profileUrl:'https://dl3.pushbulletusercontent.com/iy4fGml1zGO2iXHiwMu1w7daEsGTOyov/D4C8B88E-5ECB-4BBC-B13C-18F783E94D55.jpeg'}
    ])

    const [active, setActive] = useState(0)
    return (
        <StyledFlexAccordian>
          <div className="body">
            <h1 className='exit-x' onClick={()=>{setIsNavOpen(false)}}><AiOutlineClose /></h1>

  <ul  
  style={{overflow:'scroll',position:'absolute',top:'0',}} >

      {menuSelections.map((menuSelection,index)=>(
      <div>
        <Link to={menuSelection.linkUrl}>
        <li
      style={{cursor:'pointer',backgroundImage: `url(${menuSelection.backgroundUrl})`,height:'11.15em',border:'1px solid rgba(225,225,225,0.92)'}}
      role="button"
      className='active'
      onClick={() => setIsNavOpen(false)}
    >
      <h3>{menuSelection.name}</h3>
      <div className="section-content">
        <div className="inner">
          <div className="bio">
            <h2> {menuSelection.name} </h2>
            <p>
               {menuSelection.description} 
            </p>

            {/* <a
              href={menuSelection.profileUrl}
              target="_blank"
              className="menuSelection-profile-link md:hidden"
            >
              <img
                src="https://assets.codepen.io/152347/spotify-badge.svg"
                alt="Listen on Spotify"
                width="176"
                height="64"
                loading="lazy"
              />
            </a> */}
          </div>
        </div>
      </div>
    </li>
        </Link>
      </div>
      ))} 
      </ul>
          </div>
    {/* <li
      v-for="(menuSelection, i) in menuSelections"
      :style="`background-image: url(${menuSelection.backgroundUrl});`"
      role="button"
      :className="active === i ? 'active' : ''"
      @click="() => (active = i)"
    >
      <h3>{{ menuSelection.name }}</h3>
      <div className="section-content">
        <div className="inner">
          <div className="bio">
            <h2>{{ menuSelection.name }}</h2>
            <p>
              {{ menuSelection.description }}
            </p>

            <a
              :href="menuSelection.spotify.profileUrl"
              target="_blank"
              className="menuSelection-profile-link md:hidden"
            >
              <img
                src="https://assets.codepen.io/152347/spotify-badge.svg"
                alt="Listen on Spotify"
                width="176"
                height="64"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template> */}

        </StyledFlexAccordian>
    )
}
 
export default FlexAccordian
 