import Heroseph from './Heroseph'
import Camps from './Camps'
import Training from './Training';

import styled from 'styled-components';

const StyledFooter = styled.div`
  
.site-footer {
  background: url("//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/bg-line-fractal-white-alpha-25.svg") no-repeat right bottom, #000000;
  background-attachment: fixed;
  background-size: 100%;
  background-blend-mode: luminosity;
  color: #ffffff;
  -webkit-clip-path: polygon(0 100%, 0 20%, 50% 0, 100% 20%, 100% 100%);
  clip-path: polygon(0 100%, 0 20%, 50% 0, 100% 20%, 100% 100%);
}
.site-footer .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7em;
  padding-bottom: 4em;
}
.site-footer p {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
  max-width: 34ch;
  text-align: center;
}
.site-footer .copyrights {
  color: #bebebe;
  font-size: 90%;
  font-weight: 400;
  margin-bottom: 0;
}
.site-footer .copyrights a {
  color: currentColor;
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

@media (max-width: 999px) {
  .footer-desktop {
    display: none !important;
  }
}
@media (min-width: 62.5em) {
  body {
    background: #000000;
  }

  .site-header {
    background: transparent;
    color: #f7be00;
    overflow: hidden;
  }

  .hero {
    height: 100vh;
  }
  .hero .text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1 60vh;
    padding-top: 16vw;
    padding-bottom: 0;
    padding-right: 2em;
    position: relative;
    z-index: 1;
  }
  .hero .text-wrap {
    padding-right: 5em;
  }
  .hero .title {
    font-size: 4.5em;
    flex-basis: auto;
    margin-bottom: 1rem;
  }
  .hero .subtitle {
    color: #eee;
    font-size: 1.35em;
    margin-bottom: 2.5em;
  }
  .hero .actions {
    margin-bottom: 0;
  }
  .hero .media {
    background: url("//s3-us-west-2.amazonaws.com/s.cdpn.io/97621/bg-line-fractal-white-alpha-25.svg") no-repeat right bottom, linear-gradient(45deg, #5d353d, #2c2150);
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.75;
    height: 100vh;
    width: 100vw;
    z-index: 0;
  }
  .hero-img {
    -webkit-clip-path: unset;
    clip-path: unset;
    mix-blend-mode: luminosity;
    opacity: 0.4;
  }

  .footer-desktop {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1em;
    padding-bottom: 1em;
    padding-left: 5vw;
    position: relative;
    z-index: 1;
  }
  .footer-desktop .copyrights {
    color: #ddd;
    font-weight: 300;
    font-size: 0.875em;
    margin: 0;
    max-width: 100%;
    opacity: 0.4;
    padding-right: 1em;
    transition: 0.4s ease;
  }
  .footer-desktop .copyrights a {
    color: currentColor;
    text-decoration: underline;
    transition: 0.4s ease;
  }
  .footer-desktop .copyrights a:hover, .footer-desktop .copyrights a:focus {
    color: #fff;
    text-decoration: none;
  }
  .footer-desktop .copyrights:hover, .footer-desktop .copyrights:focus {
    opacity: 1;
  }
  .footer-desktop .icon-list {
    justify-content: flex-end;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 0;
  }
  .footer-desktop .icon-fb {
    font-size: 0.875em;
  }

  .navbar {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .logo-full-ribbon.light .ribbon {
    fill: #f7be00;
  }
  .logo-full-ribbon.light .text,
.logo-full-ribbon.light .crown {
    fill: #000000;
  }

  .navbar-collapse.collapsing, .navbar-collapse.in {
    display: none !important;
    z-index: 0;
  }

  .navbar-controls {
    align-items: center;
    align-self: flex-start;
    padding-top: 1em;
    padding-right: 1em;
  }

  .navbar-toggle-login {
    font-size: 1.125em;
    margin-left: 0.5rem;
  }
  .navbar-toggle-menu, .navbar-toggle-login {
    display: none;
  }
  .navbar-toggle .icon-user {
    fill: #ffffff;
    height: 1.75em;
    width: 1.75em;
  }

  .navbar-desktop {
    display: block;
  }

  .info {
    background-position: center -10vw, center center;
  }

  
.site-footer {
    display: none;
  }
}
@media (min-width: 120em) {
  body {
    font-size: 22px;
  }

  .hero .text {
    font-size: 0.875vw;
    padding-bottom: 4vh;
  }
  .hero .title {
    margin-top: 0;
  }
  .hero .subtitle {
    margin-bottom: 3vw;
  }
  .hero .btn-primary-dark:hover, .hero .btn-primary-dark:focus {
    border-color: #f7be00;
  }

  .join-beta {
    display: flex;
    align-items: center;
  }

  .navbar-brand {
    margin-left: 6em;
  }

  .logo-full-ribbon {
    display: block;
    height: 14em;
  }

  .nav-controls {
    font-size: 1.5em;
  }

  .navbar-toggle .icon-bar {
    width: 30px;
    height: 3px;
  }
  .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 0.2em;
  }

  .main-menu-visible .icon-bar.bottom {
    transform: translateY(-10px) rotate(45deg);
  }
  .main-menu-visible .logo-full-ribbon {
    height: 10em;
  }
}


.btn-primary,
a.btn-primary {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #f7be00;
  border-color: #f7be00;
  color: #000000;
}
.btn-primary:hover, .btn-primary:focus,
a.btn-primary:hover,
a.btn-primary:focus {
  text-decoration: none;
}
.btn-primary:hover, .btn-primary:focus,
a.btn-primary:hover,
a.btn-primary:focus {
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}
.btn-primary-dark,
a.btn-primary-dark {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}
.btn-primary-dark:hover, .btn-primary-dark:focus,
a.btn-primary-dark:hover,
a.btn-primary-dark:focus {
  text-decoration: none;
}
.btn-primary-dark:hover, .btn-primary-dark:focus,
a.btn-primary-dark:hover,
a.btn-primary-dark:focus {
  background: #f7be00;
  border: 0.25em solid #f7be00;
  color: #000000;
}
.btn-primary-outline,
a.btn-primary-outline {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #f7be00;
  border-color: #f7be00;
  color: #000000;
  border: 0.25em solid #000000;
}
.btn-primary-outline:hover, .btn-primary-outline:focus,
a.btn-primary-outline:hover,
a.btn-primary-outline:focus {
  text-decoration: none;
}
.btn-primary-outline:hover, .btn-primary-outline:focus,
a.btn-primary-outline:hover,
a.btn-primary-outline:focus {
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}
.btn-primary-outline-dark,
a.btn-primary-outline-dark {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #000000;
  border-color: #000000;
  color: #f7be00;
  border: 0.25em solid #f7be00;
}
.btn-primary-outline-dark:hover, .btn-primary-outline-dark:focus,
a.btn-primary-outline-dark:hover,
a.btn-primary-outline-dark:focus {
  text-decoration: none;
}
.btn-primary-outline-dark:hover, .btn-primary-outline-dark:focus,
a.btn-primary-outline-dark:hover,
a.btn-primary-outline-dark:focus {
  background: #f7be00;
  border: 0.25em solid #f7be00;
  color: #000000;
}
.btn-outline-light,
a.btn-outline-light {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #f7be00;
  border-color: #f7be00;
  color: #000000;
  background: transparent;
  border: 0.25em solid #f7be00;
  color: #f7be00;
}
.btn-outline-light:hover, .btn-outline-light:focus,
a.btn-outline-light:hover,
a.btn-outline-light:focus {
  text-decoration: none;
}
.btn-outline-light:hover, .btn-outline-light:focus,
a.btn-outline-light:hover,
a.btn-outline-light:focus {
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}
.btn-outline-light:hover, .btn-outline-light:focus,
a.btn-outline-light:hover,
a.btn-outline-light:focus {
  background: #f7be00;
  border: 0.25em solid #f7be00;
  color: #000000;
}
.btn-outline-dark,
a.btn-outline-dark {
  border-radius: 0;
  border-style: solid;
  border-width: 0.25em;
  display: inline-block;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 0.875em;
  letter-spacing: 0.07em;
  margin-right: auto;
  margin-left: auto;
  padding: 0.938em 2.125em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s ease;
  background: #f7be00;
  border-color: #f7be00;
  color: #000000;
  background: transparent;
  border: 0.25em solid #000000;
}
.btn-outline-dark:hover, .btn-outline-dark:focus,
a.btn-outline-dark:hover,
a.btn-outline-dark:focus {
  text-decoration: none;
}
.btn-outline-dark:hover, .btn-outline-dark:focus,
a.btn-outline-dark:hover,
a.btn-outline-dark:focus {
  background: #000000;
  border-color: #000000;
  color: #f7be00;
}

p {
  font-size: 1.2em;
  line-height: 1.7;
  margin-bottom: 2rem;
  max-width: 60ch;
}
p strong {
  font-weight: 600;
}

.icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  width: 1em;
  vertical-align: middle;
  position: relative;
  top: -0.0625em;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  text-align: center;
  stroke-width: 0;
}

img {
  max-width: 100%;
}

ul {
  list-style-type: none;
}
ul a {
  text-decoration: none;
}

.container {
  max-width: 60em;
  padding-top: 2em;
  padding-right: 2em;
  padding-bottom: 2em;
  padding-left: 2em;
  width: 100%;
}

section > .container {
  padding-top: 4em;
  padding-right: 2em;
  padding-bottom: 4em;
  padding-left: 2em;
}

.container-fluid > .navbar-collapse {
  margin-right: 0;
  margin-left: 0;
}
@media (min-width: 48em) {
  .container-fluid > .navbar-header {
    margin-right: -15px;
  }
}



`;

const Home = () => {
  return <div>


    <Heroseph />
    <Training />
  <Camps />
  <StyledFooter>
      <footer className="site-footer" role="contentinfo">
        <div className="container">
          <img className="logo-icon logo-icon-sm pb-2" src="https://dl3.pushbulletusercontent.com/49vHkgXeCJTOuuyTmT8zuUpG6cDoC109/image.png" alt="Denard Bros"/>
          <p><strong>Got something (meaningful) to say? Drop us a line.</strong></p>
          <a href="mailto:denardbros@gmail.com?Subject=Hello" className="btn-outline-light">Contact Us</a>
          <ul className="icon-list">
            <li>
              <a href="//www.facebook.com//">
                          <svg className="icon">
                          <use xlinkHref="#icon-facebook"></use>
                          </svg>
                          <span className="sr-only">Find us on Facebook</span>
                      </a>
            </li>
            <li>
              <a href="//twitter.com/">
                          <svg className="icon">
                          <use xlinkHref="#icon-twitter"></use>
                          </svg>
                          <span className="sr-only">Follow us on Twitter</span>
                      </a>
            </li>
            {/* <!--<li>
                      <a href="#0">
                          <svg className="icon">
                          <use xlinkHref="#icon-twitch"></use>
                          </svg>
                          <span className="sr-only">Watch us on Twitch</span>
                      </a>
                      </li>--> */}
          </ul>
          <p className="copyrights">Copyright © 2023 DenardBros All right reserved. <a href="/terms">Terms of Service</a>.
          </p>
        </div>
      </footer>
      </StyledFooter>
  </div>
};

export default Home;
